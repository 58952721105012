import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Challenges.css";
import JSCodingChallenges from "../Interviewqa/QnaJson/JSCodingChallenges";
import ReactGA from "react-ga4";

const Challenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredChallenges, setFilteredChallenges] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState("all"); // Default to "all"
  const navigate = useNavigate();

  useEffect(() => {
    const data = JSCodingChallenges.data;
    setChallenges(data);
    setFilteredChallenges(data);
  }, []);

  useEffect(() => {
    let filtered = challenges.filter((challenge) =>
      challenge.ques.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (selectedDifficulty !== "all") {
      filtered = filtered.filter(
        (challenge) => challenge.difficulty.toLowerCase() === selectedDifficulty
      );
    }

    setFilteredChallenges(filtered);
  }, [searchTerm, challenges, selectedDifficulty]);

  const handleDifficultyChange = (event) => {
    setSelectedDifficulty(event.target.value.toLowerCase());
  };

  return (
    <div className="challenges_question-main-container">
      <div className="search-container">
        <div className="search-input-container">
          <input
            type="text"
            className="search-input"
            placeholder="Search questions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <i className="fas fa-search search-input-icon"></i>
        </div>
        <select
          className="difficulty-dropdown"
          value={selectedDifficulty}
          onChange={handleDifficultyChange}
        >
          <option value="all">All Levels</option>
          <option value="easy">Beginner</option>
          <option value="medium">Intermediate</option>
          <option value="hard">Advanced</option>
        </select>
      </div>
      {filteredChallenges.map((item) => {
        const difficultyClass = item.difficulty.toLowerCase();
        return (
          <div
            className={`challenges-question-container ${difficultyClass}`}
            key={item.id}
          >
            <div className="challenges-question-title">
              {item.id}. {item.ques}
            </div>
            <div className="challenges-buttons-container">
              <div className="challenges-question-diff">
                Difficulty - {item.difficulty}
              </div>
              <div
                className="challenges-question-solve"
                onClick={() => {
                  ReactGA.event({
                    category: "Home",
                    action: `Clicked ${item.id} coding challenge`,
                  });
                  navigate(
                    `/ChallengeAnswer/${encodeURIComponent(
                      JSON.stringify(item.id)
                    )}`
                  );
                }}
              >
                Solve
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Challenges;
