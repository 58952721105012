import React from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setSessionStorage } from "../../Shared/Utils/StorageUtils";
import CommonConstants from "../../Shared/Constants/CommonConstants";
import javascriptLogo from "../../assets/javascriptlogo.png";
import angularLogo from "../../assets/angularlogo.png";
import reactLogo from "../../assets/reactlogo.png";
import htmlLogo from "../../assets/html.png";
import cssLogo from "../../assets/csslogo.png";
import vueLogo from "../../assets/vue.svg";
import "./Home.css";
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'; 

const courses = [
  // {
  //   name: "Javascript",
  //   questions: "Javascript Interview Questions",
  //   description: "All Interview Questions from beginner to advanced",
  //   logo: javascriptLogo,
  //   clickedIndex: 5,
  //   eventAction: "Clicked Javascript Interview ques",
  //   navigatePath: `/InterviewQa/${encodeURIComponent(
  //     JSON.stringify("JavascriptInt")
  //   )}`,
  // },
  {
    name: "React",
    questions: "React Real-time scenarios",
    description: "Practice very Basic to Advanced Scenarios",
    logo: reactLogo,
    clickedIndex: 14,
    eventAction: "react/real-time Main",
    navigatePath: "/ReactScenarioChallenges",
  },
  {
    name: "Javascript",
    questions: "Javascript Coding challenges",
    description: "Coding challenges from beginner to advanced",
    logo: javascriptLogo,
    clickedIndex: 11,
    eventAction: "Clicked Javascript challenges ques",
    navigatePath: "/Challenges",
  },
  // {
  //   name: "Javascript",
  //   questions: "Javascript Output Questions",
  //   description: "All output based Questions from beginner to advanced",
  //   logo: javascriptLogo,
  //   clickedIndex: 10,
  //   eventAction: "Clicked Javascript output Interview ques",
  //   navigatePath: `/InterviewQa/${encodeURIComponent(
  //     JSON.stringify("JavascriptOutInt")
  //   )}`,
  // },
  {
    name: "React",
    questions: "React Machine Coding Ques",
    description: "Machine coding questions from beginner to advanced",
    logo: reactLogo,
    clickedIndex: 12,
    eventAction: "React Machine - Main",
    navigatePath: "/ReactChallenges",
  },
  // {
  //   name: "React",
  //   questions: "React Interview Questions",
  //   description: "All React Interview Questions from beginner to advanced",
  //   logo: reactLogo,
  //   clickedIndex: 2,
  //   eventAction: "Clicked React Interview ques",
  //   navigatePath: `/InterviewQa/${encodeURIComponent(
  //     JSON.stringify("ReactInt")
  //   )}`,
  // },
  {
    name: "Angular",
    questions: "Angular Interview Questions",
    description: "All Interview Questions from beginner to advanced",
    logo: angularLogo,
    clickedIndex: 3,
    eventAction: "Clicked Angular Interview ques",
    navigatePath: `/InterviewQa/${encodeURIComponent(
      JSON.stringify("AngularInt")
    )}`,
  },
  // {
  //   name: "VueJs",
  //   questions: "VueJs Interview Questions",
  //   description: "All VueJs Interview Questions from beginner to advanced",
  //   logo: vueLogo,
  //   clickedIndex: 4,
  //   eventAction: "Clicked Vuejs Interview ques",
  //   navigatePath: `/InterviewQa/${encodeURIComponent(
  //     JSON.stringify("VueInt")
  //   )}`,
  // },
  {
    name: "Html",
    questions: "Html Interview Questions",
    description: "All Html Interview Questions from beginner to advanced",
    logo: htmlLogo,
    clickedIndex: 6,
    eventAction: "Clicked Html Interview ques",
    navigatePath: `/InterviewQa/${encodeURIComponent(
      JSON.stringify("HtmlInt")
    )}`,
  },
  {
    name: "Css",
    questions: "Css Interview Questions",
    description: "All Css Interview Questions from beginner to advanced",
    logo: cssLogo,
    clickedIndex: 7,
    eventAction: "Clicked Css Interview ques",
    navigatePath: `/InterviewQa/${encodeURIComponent(
      JSON.stringify("CssInt")
    )}`,
  },
];

const TutorialItem = ({
  name,
  questions,
  description,
  logo,
  clickedIndex,
  eventAction,
  navigatePath,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    setSessionStorage(
      CommonConstants.CLICKED_INDEX,
      JSON.stringify(clickedIndex)
    );
    ReactGA.event({
      category: "Home",
      action: eventAction,
    });
    navigate(navigatePath);
  };

  return (
    <div className="home__tutorial-item-container">
      <div className="home__tutorial-left--container">
        <div className="home-tutorial__headerwithshare">
          <div className="home-tutorial__header-text-react">{name}</div>
          <div className="home-tutorial__header-text-react-share">
            <FontAwesomeIcon
              className="layout-web__left-course__icon"
              icon={faShareAlt}
            />
          </div>
        </div>
        <div className="home-tutorial__react-int-ques__header">{questions}</div>
        <div className="home-tutorial__react-int-ques__sub-header">
          {description}
        </div>
        <div className="home__tutorial-logo-container">
          <img
            className="home__tutorial-logo"
            src={logo}
            alt={`${name} logo`}
          />
        </div>
      </div>
      <div className="home__tutorial-right--container">
        <div className="home-container-item__continue-container">
          <div className="home-tutorial-item__continue" onClick={handleClick}>
            Continue{" "}
            <FontAwesomeIcon
              className="home__tutorial-continue-icon"
              icon="fa-solid fa-arrow-right"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <div className="home__main-container">
      <div className="home__main_couses_container">
        <div className="courses__container-main">
          {courses.map((course, index) => (
            <TutorialItem key={index} {...course} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
