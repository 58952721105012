import React, { useState, useEffect } from "react";
// import "./ReactChallengeAnswer.css"; // Import a CSS file for styling
import { useParams } from "react-router-dom";
import {
  SandpackConsole,
  SandpackLayout,
  SandpackTests,
  SandpackProvider,
  SandpackCodeEditor,
  SandpackPreview,
  useSandpack,SandpackPreviewWithConsole,Sandpack
} from "@codesandbox/sandpack-react";
import ReactScenarios from "../../../Interviewqa/QnaJson/ReactScenarios";

export const ReactScenariosAnswerEditor = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { reactChalNumb } = useParams();
  const [data,setData] = useState("");

  useEffect(() => {
    const challengesInfo = ReactScenarios.data;
    let url = challengesInfo[reactChalNumb-1];
    console.log(url)
    setData(url);
  }, [reactChalNumb]);

  return (
    <Sandpack 
      template="react"
      theme="dark" 
      files={ReactScenarios.data[reactChalNumb-1].files}
      options={{
        visibleFiles: ReactScenarios.data[reactChalNumb-1].visibleFiles,
        showNavigator: true,
        showTabs: true,
        showConsole: true, showConsoleButton: true,
        closableTabs: true,
        showLineNumbers: true,
        showInlineErrors: true, 
        wrapContent: true, 
        editorHeight: "91vh", 
        editorWidthPercentage: 60, 
      }}
    />
  )
}