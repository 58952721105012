import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CodeEditor from "../../MonaccoEditor/MonaccoEditor";
import JSCodingChallenges from "../../Interviewqa/QnaJson/JSCodingChallenges";


export const ChallengesAnswers = () => {
  const { chalNumb } = useParams();
  const [quesAnsData, setQuesAnsData] = useState({});

  useEffect(() => {
    
    async function data() {
      const ansInfo = await JSCodingChallenges.data[chalNumb - 1];
      setQuesAnsData(ansInfo);
    }
    data();
  }, []);


  return (
    <CodeEditor quesAnsData={quesAnsData} chalNumb={chalNumb}></CodeEditor>
  );
};
