import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "../ReactChallenges.css";
import ReactChallengesJson from "../../Interviewqa/QnaJson/ReactScenarios";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const ReactScenarioChallenges = () => {
  const [challenges, setChallenges] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let data = ReactChallengesJson.data;
    setChallenges(data);
  }, []);

  return (
    <div className="react-challenges_question-main-container">
      {challenges.map((item) => {
        return (
          <div className="react-challenges-question-container">
            <div className="react-challenges-question-title">
              {item.id}. {item.ques}
            </div>
            <ul className="react-challenges-desc-container">
              {
                item.description.map((desc)=>{
                  return <li> {desc.point}</li>
                })
              }
            </ul>
            <div className="react-challenges-border"></div>
            <div className="react-challenges-buttons-container">
              <div className="react-challenges-question-diff">
                Level - {item.difficulty}
              </div>
              <div
                className="react-challenges-question-solve"
                onClick={() => {
                  ReactGA.event({
                    category: "Home",
                    action: `Clicked ${item.id} coding challenge`,
                  });
                  const intType = "ReactInt";
                  navigate(
                    `/ReactScenariosAnswer/${encodeURIComponent(
                    JSON.stringify(item.id)
                    )}`
                );
                }}
              >
                Solve
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReactScenarioChallenges;
