import { useRef, useState, useEffect } from "react";
import { Box, Stack, VStack, ChakraProvider } from "@chakra-ui/react";
import { Editor } from "@monaco-editor/react";
import LanguageSelector from "./LanguageSelector";
import { CODE_SNIPPETS } from "../constants.js";
import Output from "./Output";
import theme from "../../theme.js";
import JSCodingChallenges from "../Interviewqa/QnaJson/JSCodingChallenges";
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faJsSquare } from "@fortawesome/free-brands-svg-icons"; // JS Icon
import { faEye } from "@fortawesome/free-solid-svg-icons"; // Eye Icon
import { faFire } from "@fortawesome/free-solid-svg-icons"; // Fire Icon
import "./MonaccoEditor.css";
import NormalParas from "../../ReusableComponents/AnswersUtils/NormalParas/NormalParas.js";
import BulletParas from "../../ReusableComponents/AnswersUtils/BulletParas/BulletParas.js";
import NormalParasChallenges from "../../ReusableComponents/AnswersUtils/NormalParas/NormalParasChallenges.js";

const CodeEditor = ({ quesAnsData, chalNumb }) => {
  // console.log(quesAnsData,"quesAnsDataquesAnsData")
  const editorRef = useRef();
  const [value, setValue] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [showAnswer, setShowAnswer] = useState(false);

  const onMount = (editor) => {
    editorRef.current = editor;
    editor.focus();
  };
console.log(quesAnsData)
  const onSelect = (language) => {
    setLanguage(language);
    setValue(quesAnsData?.structure);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await JSCodingChallenges.data[chalNumb - 1];
      setValue(response?.structure);
    }
    fetchData();
  }, [chalNumb]);

  const toggleShowAnswer = () => {
    setShowAnswer((prev) => !prev);
    if (!showAnswer) {
      ReactGA.event({
        category: "Js challenges",
        action: `Ans: ${quesAnsData.id} clicked`,
      });
      setValue(quesAnsData?.solution);
    } else {
      setValue(quesAnsData?.structure);
    }
  };

  
  const renderAnswer = (item) => {
    return <NormalParasChallenges points={item} />;
  };

  return (
    <ChakraProvider theme={theme}>
      <Box p={4} bg="gray.800" minH="100vh">
        <VStack spacing={8} align="stretch">
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={4}
            align="start"
          >
            <Box
              w={{ base: "100%", md: "40%" }}
              p={4}
              bg="gray.900"
              borderRadius="md"
              boxShadow="lg"
              border="1px"
              borderColor="gray.700"
            >
              <div className="content">
                <div className="editor-question">
                  {quesAnsData?.id}. {quesAnsData?.ques} | JavaScript
                </div>

                <div className="content">
                  <div className="tags">
                    <span className="tag">
                      <FontAwesomeIcon
                        icon={faJsSquare}
                        style={{
                          marginRight: "5px",
                          color: "#F7DF1E", // JavaScript logo color
                        }}
                      />{" "}
                      JavaScript
                    </span>
                    <span className="tag beginner">
                      <FontAwesomeIcon
                        icon={faFire}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      {quesAnsData.difficulty}
                    </span>
                    <span className="views">
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      {quesAnsData?.views}
                    </span>
                  </div>

                  {/* Dotted Line */}
                  <div className="dotted-line"></div>
                  
                  <div className="description">
                  {renderAnswer(quesAnsData?.description ?? [])}                    
                  </div>
                </div>
              </div>
            </Box>
            <Box
              w={{ base: "100%", md: "60%" }}
              p={4}
              bg="gray.900"
              borderRadius="md"
              boxShadow="lg"
              border="1px"
              borderColor="gray.700"
            >
              <LanguageSelector
                language={language}
                onSelect={onSelect}
                showAnswer={showAnswer}
                toggleShowAnswer={toggleShowAnswer}
                ques={quesAnsData}
              />
              <Editor
                options={{
                  minimap: {
                    enabled: false,
                  },
                }}
                height="85vh"
                theme="vs-dark"
                language={language}
                defaultValue={value}
                onMount={onMount}
                value={value}
                onChange={(value) => setValue(value)}
              />
            </Box>
            <Box
              w={{ base: "100%", md: "50%" }}
              p={4}
              bg="gray.900"
              borderRadius="md"
              boxShadow="lg"
              border="1px"
              borderColor="gray.700"
            >
              <Output editorRef={editorRef} language={language} />
            </Box>
          </Stack>
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default CodeEditor;
