// eslint-disable-next-line import/no-anonymous-default-export
export default {
  data: [
    {
      question: " What is the output of  3+2+'7' ? ",
      answer: [
        {
          htmlElement: "Li",
          value: "57",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the output of below logic ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  const a = 1<2<3;
  const b = 1>2>3;

  console.log(a,b) //true,false
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output:",
        },
        {
          htmlElement: "div",
          value:
            "true, false",
        },
        {
          htmlElement: "Li",
          value:
            "In JavaScript, the comparison operators < and > have left-to-right associativity. So, 1 < 2 < 3 is evaluated as (1 < 2) < 3, which becomes true < 3. When comparing a boolean value (true) with a number (3), JavaScript coerces the boolean to a number, which is 1. So, true < 3 evaluates to 1 < 3, which is true.",
        },
        {
          htmlElement: "Li",
          value:
            "Similarly, 1 > 2 > 3 is evaluated as (1 > 2) > 3, which becomes false > 3. When comparing a boolean value (false) with a number (3), JavaScript coerces the boolean to a number, which is 0. So, false > 3 evaluates to 0 > 3, which is false.",
        },
        {
          htmlElement: "Li",
          value:
            "That's why console.log(a, b) prints true false.",
        },
      ],
      type: "Normal",
    },
    {
      question: " Guess the ouput ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  const p = { k: 1, l: 2 };
  const q = { k: 1, l: 2 };
  let isEqual = p==q;
  let isStartEqual = p=== q;

  console.log(isEqual, isStartEqual) 
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "OutPut:",
        },
        {
          htmlElement: "Li",
          value: "False,False",
        },
        {
          htmlElement: "div",
          value:
            "In JavaScript, when you compare objects using == or ===, you're comparing their references in memory, not their actual contents. Even if two objects have the same properties and values, they are considered unequal unless they reference the exact same object in memory.",
        },
        {
          htmlElement: "div",
          value:
            "In your code:",
        },
        {
          htmlElement: "Li",
          value: "isEqual will be false because p and q are two different objects in memory, even though they have the same properties and values. ",
        },
        {
          htmlElement: "Li",
          value: " isStartEqual will also be false for the same reason. The === operator checks for strict equality, meaning it not only compares values but also ensures that the objects being compared reference the exact same memory location.",
        },
        {
          htmlElement: "div",
          value:
            "So, console.log(isEqual, isStartEqual) will output false false.",
        },
      ],
      type: "Normal",
    },
    {
      question: " Guess the output ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  a) 2+2 = ? 
  b) "2"+"2" = ?
  c) 2+2-2 = ?
  d) "2"+"2"-"2" = ? (tricky remember this)
  e) 4+"2"+2+4+"25"+2+2 ?
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output:",
        },
        {
          htmlElement: "Syntax",
          value:`
  // a) 2+2 = ?
  console.log(2 + 2); // Output: 4

  // b) "2"+"2" = ?
  console.log("2" + "2"); // Output: "22" (string concatenation)

  // c) 2+2-2 = ?
  console.log(2 + 2 - 2); // Output: 2

  // d) "2"+"2"-"2" = ?
  console.log("2" + "2" - "2"); // Output: 20 (string "22" is converted
  to a number, then subtracted by the number 2)

  // e) 4+"2"+2+4+"25"+2+2
  console.log(4+"2"+2+4+"25"+2+2); // "42242522"
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the output of below logic ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  let a = 'jscafe'
  a[0] = 'c'

  console.log(a)
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output:",
        },
        {
          htmlElement: "Li",
          value: " 'jscafe' ",
        },
        {
          htmlElement: "Li",
          value: "Strings are immutable in javascript so we cannot change individual characters by index where as we can create a new string with desired modification as below. ",
        },
        {
          htmlElement: "Li",
          value: " a = 'cscafe' // outputs 'cscafe'",
        },
      ],
      type: "Normal",
    },
    {
      question: " Output of below logic ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  var x=10;
  function foo(){
    var x = 5;
    console.log(x)
  }

  foo();
  console.log(x)
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output:",
        },
        {
          htmlElement: "div",
          value: "5 and 10 ",
        },
        {
          htmlElement: "div",
          value: "In JavaScript, this code demonstrates variable scoping. When you declare a variable inside a function using the var keyword, it creates a new variable scoped to that function, which may shadow a variable with the same name in an outer scope. Here's what happens step by step: ",
        },
        {
          htmlElement: "Li",
          value: " var x = 10;: Declares a global variable x and initializes it with the value 10.",
        },
        {
          htmlElement: "Li",
          value: " function foo() { ... }: Defines a function named foo.",
        },
        {
          htmlElement: "Li",
          value: "var x = 5;: Inside the function foo, declares a local variable x and initializes it with the value 5. This x is scoped to the function foo and is different from the global x. ",
        },
        {
          htmlElement: "Li",
          value: "console.log(x);: Logs the value of the local variable x (which is 5) to the console from within the foo function. ",
        },
        {
          htmlElement: "Li",
          value: "foo();: Calls the foo function. ",
        },
        {
          htmlElement: "Li",
          value: "console.log(x);: Logs the value of the global variable x (which is still 10) to the console outside the foo function. ",
        },
      ],
      type: "Normal",
    },
    {
      question: "Guess the output ?  ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  console.log("Start");
  setTimeout(() => {
  console.log("Timeout");
  });
  Promise.resolve().then(() => console.log("Promise"));
  console.log("End");
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output: ",
        },
        {
          htmlElement: "div",
          value:
            "Start, End,Promise,Timeout.",
        },
        {
          htmlElement: "Li",
          value: " 'Start' is logged first because it's a synchronous operation.",
        },
        {
          htmlElement: "Li",
          value: " Then, 'End' is logged because it's another synchronous operation.",
        },
        {
          htmlElement: "Li",
          value: "'Promise' is logged because Promise.resolve().then() is a microtask and will be executed before the next tick of the event loop. ",
        },
        {
          htmlElement: "Li",
          value: "Finally, 'Timeout' is logged. Even though it's a setTimeout with a delay of 0 milliseconds, it's still a macrotask and will be executed in the next tick of the event loop after all microtasks have been executed.",
        },
      ],
      type: "Normal",
    },
    {
      question: " This code prints 6 everytime. How to print 1,2,3,4,5,6 ? (Most asked) ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
      
  function x(){
    
    for(var i=1;i<=5;i++){
      setTimeout(()=>{
        console.log(i)
      },i*1000)
    }
    
  }

  x();
          `,
        },
        {
          htmlElement: "div",
          value:
            "Solution: Either use let or closure",
        },
        {
          htmlElement: "Syntax",
          value:`
  function x() {
    function closur(x) {
      // Set a timeout to log the value of x after x seconds
      setTimeout(() => {
        console.log(x);
      }, x * 1000);
    };

    // Loop from 1 to 5
    for (var i = 1; i <= 5; i++) {
      // Call the closure function with the current value of i
      closur(i);
    }
  }

  // Call the outer function x
  x();
          `,
        },
        {
          htmlElement: "div",
          value:
            "The function we have written defines an inner function closur which is supposed to log the value of x after x seconds. The outer function x calls this inner function for values from 1 to 5.",
        },
        {
          htmlElement: "div",
          value:
            "The code will log the values 1 to 5 after 1 to 5 seconds respectively. Here's an explanation of how it works:",
        },
        {
          htmlElement: "Li",
          value: " The outer function x is called.",
        },
        {
          htmlElement: "Li",
          value: " Inside x, a loop runs from i=1 to i=5.",
        },
        {
          htmlElement: "Li",
          value: "For each iteration of the loop, the inner function closur is called with the current value of i.",
        },
        {
          htmlElement: "Li",
          value: " Inside closur, a setTimeout is set to log the value of x after x seconds.",
        },
        {
          htmlElement: "div",
          value:
            "Each call to closur(i) creates a new closure that captures the current value of i and sets a timeout to log that value after i seconds.",
        },
        {
          htmlElement: "div",
          value:
            "When you run this code, the output will be:",
        },
        {
          htmlElement: "Syntax",
          value:`
  1 (after 1 second)
  2 (after 2 seconds)
  3 (after 3 seconds)
  4 (after 4 seconds)
  5 (after 5 seconds)
          `,
        },
        {
          htmlElement: "div",
          value:
            "This happens because each iteration of the loop calls closur with a different value of i, and each setTimeout inside closur is set to log that value after i seconds.",
        },
      ],
      type: "Normal",
    },
    {
      question: " What will be the output or below code ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
      
  function x(){
    let a = 10;
    function d(){
      console.log(a);
    }
    a = 500;
    return d;
  }

  var z = x();
  z();
          `,
        },
        {
          htmlElement: "div",
          value:
            "Solution: 500 - Closures concept",
        },
        {
          htmlElement: "div",
          value:
            "In JavaScript, this code demonstrates lexical scoping and closure. Let's break it down:",
        },
        {
          htmlElement: "Li",
          value: " function x() { ... }: Defines a function named x.",
        },
        {
          htmlElement: "Li",
          value: "let a = 10;: Declares a variable a inside the function x and initializes it with the value 10. ",
        },
        {
          htmlElement: "Li",
          value: "function d() { ... }: Defines a nested function named d inside the function x. ",
        },
        {
          htmlElement: "Li",
          value: " console.log(a);: Logs the value of the variable a to the console. Since d is defined within the scope of x, it has access to the variable a defined in x.",
        },
        {
          htmlElement: "Li",
          value: "a = 500;: Changes the value of the variable a to 500. ",
        },
        {
          htmlElement: "Li",
          value: " return d;: Returns the function d from the function x.",
        },
        {
          htmlElement: "Li",
          value: "var z = x();: Calls the function x and assigns the returned function d to the variable z. ",
        },
        {
          htmlElement: "Li",
          value: " z();: Calls the function d through the variable z.",
        },
        {
          htmlElement: "div",
          value:
            "When you run this code, it will log the value of a at the time of executing d, which is 500, because d retains access to the variable a even after x has finished executing. This behavior is possible due to closure, which allows inner functions to access variables from their outer scope even after the outer function has completed execution.",
        },
      ],
      type: "Normal",
    },
    {
      question: "What’s the output of below logic ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  getData1()
  getData();

  function getData1(){
  console.log("getData11")
  }

  var getData = () => {
  console.log("Hello")
  }

  // Here declaring getData with let causes reference error.
  // ie.,"ReferenceError: Cannot access 'getData' before initialization 
  // As we are declaring with var it throws type error as show below
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output:",
        },
        {
          htmlElement: "div",
          value:
            "Uncaught TypeError: getData is not a function at index.js:2.1",
        },
        {
          htmlElement: "SubHeader",
          value:
            "Explanation:",
        },
        {
          htmlElement: "div",
          value:
            "In JavaScript, function declarations are hoisted to the top of their scope, while variable declarations using var are also hoisted but initialized with undefined. Here's what happens in your code:",
        },
        {
          htmlElement: "Li",
          value: "getData1() is a function declaration and getData() is a variable declaration with an arrow function expression assigned to it. ",
        },
        {
          htmlElement: "Li",
          value: " When the code runs:`getData1()` is a function declaration, so it's hoisted to the top and can be called anywhere in the code. However, it's not called immediately.",
        },
        {
          htmlElement: "Li",
          value: "getData is declared using var, so it's also hoisted to the top but initialized with undefined. ",
        },
        {
          htmlElement: "Li",
          value: "The arrow function assigned to getData is not hoisted because it's assigned to a variable. ",
        },
        {
          htmlElement: "Li",
          value: "When `getData()` is invoked- It will throw an error because `getData` is `undefined`, and you cannot call `undefined` as a function. ",
        },
        {
          htmlElement: "div",
          value:
            "Therefore, if you try to run the code as is, you'll encounter an error when attempting to call getData().",
        },
        {
          htmlElement: "div",
          value:
            "If you want to avoid this error, you should either define getData before calling it or use a function declaration instead of a variable declaration for getData. Here's how you can do it:",
        },
        {
          htmlElement: "SubHeader",
          value:
            "Modification needed for code:",
        },
        {
          htmlElement: "Syntax",
          value:`
  var getData = () => {
  console.log("Hello")
  }

  getData1(); // This will log "getData11"
  getData();  // This will log "Hello"
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: " Whats the output of below code ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  function func() {
    try {
      console.log(1)
      return 
    } catch (e) {
      console.log(2)
    } finally {
      console.log(3)
    }
    console.log(4)
  }

  func()
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output: ",
        },
        {
          htmlElement: "div",
          value:
            "1 & 3",
        },
        {
          htmlElement: "Li",
          value: "The function func() is defined. ",
        },
        {
          htmlElement: "Li",
          value: "Inside the `try` block.`console.log(1)` is executed, printing `1` to the console.`return` is encountered, which immediately exits the function. ",
        },
        {
          htmlElement: "Li",
          value: "The `finally` block is executed - `console.log(3)` is executed, printing `3` to the console.",
        },
        {
          htmlElement: "div",
          value: "Since return is encountered within the try block, the control exits the function immediately after console.log(1). The catch block is skipped because there are no errors, and the code in the finally block is executed regardless of whether an error occurred or not. ",
        },
        {
          htmlElement: "div",
          value: "So, when you run this code, it will only print 1 and 3 to the console. ",
        },
      ],
      type: "Normal",
    },
    {
      question: " What’s the output of below code ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  const nums = [1,2,3,4,5,6,7];
  nums.forEach((n) => {
    if(n%2 === 0) {
    break;
  }
  console.log(n);
  });
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Explanation:",
        },
        {
          htmlElement: "div",
          value:
            "Many of you might have thought the output to be 1,2,3,4,5,6,7.  But “break” statement works only loops like for, while, do…while and not for map(), forEach(). They are essentially functions by nature which takes a callback and not loops.",
        },
        {
          htmlElement: "Li",
          value: "Uncaught Syntx error: Illegal break statement ",
        },
      ],
      type: "Normal",
    },
    {
      question: " Whats the output of below code ? ",
      answer: [
      {
        htmlElement: "Syntax",
        value:`
  let a = true;
  setTimeout(() => {
    a = false;
  }, 2000)


  while(a) {
    console.log(' -- inside whilee -- ');
  }
        `,
      },
      {
        htmlElement: "SubHeader",
        value:
          "Solution:",
      },
      {
        htmlElement: "nav",
        value:
          "https://medium.com/@iamyashkhandelwal/5-output-based-interview-questions-in-javascript-b64a707f34d2",
      },
      {
        htmlElement: "div",
        value:
          "This code snippet creates an infinite loop. Let's break it down:",
      },
      {
        htmlElement: "Li",
        value:
          "let a = true;: This declares a variable a and initializes it to true.",
      },
      {
        htmlElement: "Li",
        value:
          "setTimeout(() => { a = false; }, 2000): This sets up a timer to execute a function after 2000 milliseconds (2 seconds). The function assigned to setTimeout will set the value of a to false after the timeout.",
      },
      {
        htmlElement: "Li",
        value:
          "while(a) { console.log(' -- inside whilee -- '); }: This is a while loop that continues to execute as long as the condition a is true. Inside the loop, it prints ' -- inside whilee -- '.",
      },
      {
        htmlElement: "div",
        value:
          "The issue here is that the while loop runs indefinitely because there's no opportunity for the JavaScript event loop to process the setTimeout callback and update the value of a. So, even though a will eventually become false after 2 seconds, the while loop will not terminate because it doesn't yield control to allow other tasks, like the callback, to execute.",
      },
      {
        htmlElement: "div",
        value:
          "To fix this, you might consider using asynchronous programming techniques like Promises, async/await, or handling the setTimeout callback differently.",
      },
      ],
      type: "Normal",
    },
    {
      question: "  Whats the output of below code ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  setTimeout(() => console.log(1), 0);

  console.log(2);

  new Promise(res => {
    console.log(3)
    res();
  }).then(() => console.log(4));

  console.log(5);
          `,
        },
        {
          htmlElement: "div",
          value:
            "This code demonstrates the event loop in JavaScript. Here's the breakdown of what happens:",
        },
        {
          htmlElement: "Li",
          value: " setTimeout(() => console.log(1), 0);: This schedules a callback function to be executed after 0 milliseconds. However, due to JavaScript's asynchronous nature, it doesn't guarantee that it will execute immediately after the current synchronous code block.",
        },
        {
          htmlElement: "Li",
          value: " console.log(2);: This immediately logs 2 to the console.",
        },
        {
          htmlElement: "Li",
          value: "new Promise(res => { console.log(3); res(); }).then(() => console.log(4));: This creates a new Promise. The executor function inside the Promise logs 3 to the console and then resolves the Promise immediately with res(). The then() method is chained to the Promise, so once it's resolved, it logs 4 to the console. ",
        },
        {
          htmlElement: "Li",
          value: " console.log(5);: This logs 5 to the console.",
        },
        {
          htmlElement: "div",
          value:
            "When you run this code, the order of the output might seem a bit counterintuitive:",
        },
        {
          htmlElement: "Syntax",
          value:`
  2
  3
  5
  4
  1
          `,
        },
        {
          htmlElement: "div",
          value:
            "Here's why:",
        },
        {
          htmlElement: "Li",
          value: "console.log(2); is executed first because it's synchronous code. ",
        },
        {
          htmlElement: "Li",
          value: "Then, the Promise executor is executed synchronously, so console.log(3); is logged. ",
        },
        {
          htmlElement: "Li",
          value: " After that, console.log(5); is executed.",
        },
        {
          htmlElement: "Li",
          value: " Once the current synchronous execution is done, the event loop picks up the resolved Promise and executes its then() callback, logging 4.",
        },
        {
          htmlElement: "Li",
          value: " Finally, the callback passed to setTimeout is executed, logging 1. Although it was scheduled to run immediately with a delay of 0 milliseconds, it's still processed asynchronously and placed in the event queue, after the synchronous code has finished executing.",
        },
        {
          htmlElement: "nav",
          value:
            "https://medium.com/@iamyashkhandelwal/5-output-based-interview-questions-in-javascript-b64a707f34d2",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the output of below logic ?",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  async function foo() {
    console.log("A");
    await Promise.resolve();
    console.log("B");
    await new Promise(resolve => setTimeout(resolve, 0));
    console.log("C");
  }

  console.log("D");
  foo();
  console.log("E")
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output:",
        },
        {
          htmlElement: "div",
          value:
            "D, A, E, B, C",
        },
        {
          htmlElement: "SubHeader",
          value:
            "Explanation:",
        },
        {
          htmlElement: "div",
          value:
            "The main context logs “D” because it is synchronous and executed immediately.",
        },
        {
          htmlElement: "Li",
          value:
            "The foo() function logs 'A' to the console since it's synchronous and executed immediately. await Promise.resolve() : This line awaits the resolution of a Promise. The Promise.resolve() function returns a resolved Promise immediately. The control is temporarily returned to the caller function ( foo() ), allowing other synchronous operations to execute.",
        }, 
        {
          htmlElement: "Li",
          value:
            "Back to the main context: console.log('E') : This line logs 'E' to the console since it's a synchronous operation. The foo()",
        },      
        {
          htmlElement: "Li",
          value:
            "function is still not fully executed, and it's waiting for the resolution of the Promise inside it. Inside foo()",
        }, 
        {
          htmlElement: "Li",
          value:
            "(resumed execution): console.log('B') : This line logs 'B' to the console since it's a synchronous operation. ",
        }, 
        {
          htmlElement: "Li",
          value:
            "await new Promise(resolve => setTimeout(resolve, 0)); ",
        }, 
        {
          htmlElement: "Li",
          value:
            "This line awaits the resolution of a Promise returned by the setTimeout function. Although the delay is set to 0 milliseconds, the setTimeout callback is pushed into the callback queue, allowing the synchronous code to continue.",
        }, 
        {
          htmlElement: "Li",
          value:
            "Back to the main context: The control is still waiting for the foo() function to complete.",
        }, 
        {
          htmlElement: "Li",
          value:
            "Inside foo() (resumed execution): The callback from the setTimeout is picked up from the callback queue, and the promise is resolved. This allows the execution of the next await . ",
        }, 
        {
          htmlElement: "Li",
          value:
            "console.log('C') : This line logs 'C' to the console since it's a synchronous operation. foo() function completes.",
        }
      ],
      type: "Normal",
    },
    {
      question: " Guess the output ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  let output = (function(x){ 
      delete x; 
      return x; 
  })(3);
  console.log(output);
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output: 3",
        },
        {
          htmlElement: "div",
          value:
            "Let me break it down for you:",
        },
        {
          htmlElement: "Li",
          value:
            "The code defines an immediately invoked function expression (IIFE) that takes a parameter x.",
        },
        {
          htmlElement: "Li",
          value:
            "Inside the function, delete x; is called. However, delete operator is used to delete properties from objects, not variables. When you try to delete a variable, it doesn't actually delete the variable itself, but it's syntactically incorrect and may not have any effect depending on the context (in strict mode, it throws an error). So, delete x; doesn't do anything in this case.",
        },
        {
          htmlElement: "Li",
          value:
            "Finally, the function returns x. Since x was passed as 3 when calling the function (function(x){ ... })(3), it returns 3.",
        },
        {
          htmlElement: "Li",
          value:
            "The returned value is assigned to the variable output.",
        },
        {
          htmlElement: "Li",
          value:
            "console.log(output); then logs the value of output, which is 3.",
        },
      ],
      type: "Normal",
    },
    {
      question: " Guess the output of below code ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  for (var i = 0; i < 3; i++) {
    setTimeout(function () { 
      console.log(i); 
    }, 1000 + i);
  }
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output: 3 3 3 ",
        },
        {
          htmlElement: "div",
          value:
            "This might seem counterintuitive at first glance, but it's due to how JavaScript handles closures and asynchronous execution.",
        },      
        {
          htmlElement: "Li",
          value: "The for loop initializes a variable i to 0. ",
        },
        {
          htmlElement: "Li",
          value: "It sets up a timeout for i milliseconds plus the current value of i, which means the timeouts will be 1000, 1001, and 1002 milliseconds. ",
        },
        {
          htmlElement: "Li",
          value: "After setting up the timeouts, the loop increments i. ",
        },
        {
          htmlElement: "Li",
          value: "The loop checks if i is still less than 3. Since it's now 3, the loop exits. ",
        },
        {
          htmlElement: "div",
          value:
            "When the timeouts execute after their respective intervals, they access the variable i from the outer scope. At the time of execution, i is 3 because the loop has already finished and incremented i to 3. So, all three timeouts log 3.",
        }, 
      ],
      type: "Normal",
    },
    {
      question: " What is the output for below code ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  let c=0;

  let id = setInterval(() => {
    console.log(c++)
  },10)

  setTimeout(() => {
    clearInterval(id)
  },2000)
          `,
        },
        {
          htmlElement: "div",
          value:
            "This JavaScript code sets up an interval that increments the value of c every 200 milliseconds and logs its value to the console. After 2 seconds (2000 milliseconds), it clears the interval.",
        },
        {
          htmlElement: "div",
          value:
            "Here's what each part does:",
        },      
        {
          htmlElement: "Li",
          value: " let c = 0;: Initializes a variable c and sets its initial value to 0.",
        },
        {
          htmlElement: "Li",
          value: " let id = setInterval(() => { console.log(c++) }, 200): Sets up an interval that executes a function every 200 milliseconds. The function logs the current value of c to the console and then increments c.",
        },
        {
          htmlElement: "Li",
          value: "setTimeout(() => { clearInterval(id) }, 2000): Sets a timeout function that executes after 2000 milliseconds (2 seconds). This function clears the interval identified by id, effectively stopping the logging of c. ",
        },
        {
          htmlElement: "div",
          value: "This code essentially logs the values of c at 200 milliseconds intervals until 2 seconds have passed, at which point it stops logging. ",
        }
      ],
      type: "Normal",
    },
    {
      question: "What would be the output of following code ?  ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  function getName1(){
    console.log(this.name);
  }

  Object.prototype.getName2 = () =>{
    console.log(this.name)
  }

  let personObj = {
    name:"Tony",
    print:getName1
  }

  personObj.print();
  personObj.getName2();
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output: Tony undefined",
        },
        {
          htmlElement: "div",
          value:
            "Explaination: getName1() function works fine because it's being called from personObj, so it has access to this.name property. But when while calling getnName2 which is defined under Object.prototype doesn't have any proprty named this.name. There should be name property under prototype. Following is the code:",
        },
        {
          htmlElement: "Syntax",
          value:`
  function getName1(){
    console.log(this.name);
  }

  Object.prototype.getName2 = () =>{
    console.log(Object.getPrototypeOf(this).name);
  }

  let personObj = {
    name:"Tony",
    print:getName1
  }

  personObj.print();
  Object.prototype.name="Steve";
  personObj.getName2();
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: " What would be the output of following code ? ",
      answer: [
        {
          htmlElement: "Syntax",
          value:`
  function test() {
    console.log(a);
    console.log(foo());
    var a = 1;
    function foo() {
        return 2;
    }
  }

  test();
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "Output: undefined and  2",
        },
        {
          htmlElement: "div",
          value:
            "In JavaScript, this code will result in undefined being logged for console.log(a) and 2 being logged for console.log(foo()). This is due to variable hoisting and function declaration hoisting.",
        },
        {
          htmlElement: "div",
          value:
            "Here's what's happening step by step:",
        },
        {
          htmlElement: "Li",
          value: " The test function is called.",
        },
        {
          htmlElement: "Li",
          value: "Inside`test`: `console.log(a)` is executed. Since `a` is declared later in the function, it's hoisted to the top of the function scope, but not initialized yet. So, **`a`** is **`undefined`** at this point. ",
        },
        {
          htmlElement: "Li",
          value: " console.log(foo()) is executed. The foo function is declared and assigned before it's called, so it returns 2.",
        },
        {
          htmlElement: "Li",
          value: " var a = 1; declares and initializes a with the value 1.",
        },
        {
          htmlElement: "div",
          value: "Therefore, when console.log(a) is executed, a is undefined due to hoisting, and when console.log(foo()) is executed, it logs 2, the return value of the foo function. ",
        },
      ],
      type: "Normal",
    },
    // {
    //   question: "  ",
    //   answer: [
    //     {
    //       htmlElement: "Li",
    //       value: " ",
    //     },
    //   ],
    //   type: "Normal",
    // },
    // {
    //   question: "  ",
    //   answer: [
    //     {
    //       htmlElement: "Li",
    //       value: " ",
    //     },
    //   ],
    //   type: "Normal",
    // },
    // {
    //   question: "  ",
    //   answer: [
    //     {
    //       htmlElement: "Li",
    //       value: " ",
    //     },
    //   ],
    //   type: "Normal",
    // },
    // {
    //   question: "  ",
    //   answer: [
    //     {
    //       htmlElement: "Li",
    //       value: " ",
    //     },
    //   ],
    //   type: "Normal",
    // },
    // {
    //   question: "  ",
    //   answer: [
    //     {
    //       htmlElement: "Li",
    //       value: " ",
    //     },
    //   ],
    //   type: "Normal",
    // },
    // {
    //   question: "  ",
    //   answer: [
    //     {
    //       htmlElement: "Li",
    //       value: " ",
    //     },
    //   ],
    //   type: "Normal",
    // },
    // {
    //   question: "  ",
    //   answer: [
    //     {
    //       htmlElement: "Li",
    //       value: " ",
    //     },
    //   ],
    //   type: "Normal",
    // },
    // {
    //   question: "  ",
    //   answer: [
    //     {
    //       htmlElement: "Li",
    //       value: " ",
    //     },
    //   ],
    //   type: "Normal",
    // }
  ],
};
