// eslint-disable-next-line import/no-anonymous-default-export
export default {
  data: [
    {
      id: 1,
      ques: "Write a program to remove duplicates from an array ?",
      difficulty: "Beginner",
      views: "2014",
      description: [
        {
          htmlElement: "p",
          value:
            "Given a <span class='highlight'>sorted array</span> nums of size <span class='highlight'>n</span>, remove the duplicates in place such that each element appears only once and returns the new array.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nums = [1, 1, 2];
  const newArray = removeDuplicates(nums);
  console.log(newArray); // [1, 2]
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nums = [0, 0, 2, 2, 3, 3, 4];
  const newArray = removeDuplicates(nums);
  console.log(newArray); // Output: 5
  // [0, 2, 3, 4]
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Note:",
        },
        {
          htmlElement: "p",
          value:
            "The array should be <code class='highlight'>sorted</code> before calling the function. If it's not sorted, the result may be incorrect.",
        },
      ],
      structure: `

function removeDuplicates(arr) {


// Write your logic here
// Click on show answer only when you are struck anywhere
















}

console.log(removeDuplicates([1, 2, 1, 3, 4, 2, 2, 1, 5, 6]));
        `,
      solution: `

function removeDuplicatesWay2(arr) {
  // Use the Set object to remove duplicates. 
  // This works because Sets only store unique values
  return Array.from(new Set(arr));
  // return [...new Set(arr)] => another way
}

console.log(removeDuplicatesWay2([1, 2, 1, 3, 4, 2, 2, 1, 5, 6]));

// -------------------------- (or)-----------------------------

const removeDuplicatesWay1 = (array) => {
  let uniqueArr = [];

  for (let i = 0; i <= array.length - 1; i++) {
    if (uniqueArr.indexOf(array[i]) == -1) {
      uniqueArr.push(array[i]);
    }
  }

  return uniqueArr;
};

console.log(removeDuplicatesWay1([1, 2, 1, 3, 4, 2, 2, 1, 5, 6]));
`,
    },
    {
      id: 2,
      ques: "How to check whether a string is palindrome or not ?",
      difficulty: "Beginner",
      views: "1894",
      description: [
        {
          htmlElement: "p",
          value:
            "Given a string <span class='highlight'>str</span>, determine if it is a palindrome, considering only alphanumeric characters and ignoring cases. A string is considered a palindrome if it reads the same forward and backward.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
const s = "nolemon,nomelon";
console.log(isPalindrome(s)); 
// Output: true
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
const s = "race a car";
console.log(isPalindrome(s)); 
// Output: false
          `,
        },
      ],
      structure: `

const checkPallindrome = (str) => {


};

console.log(checkPallindrome("madam"));

`,
      solution: `

const checkPallindrome = (str) => {
const len = str.length;

for (let i = 0; i < len/2; i++) {
  if (str[i] !== str[len - i - 1]) {
    return "Not pallindrome";
  }
}
return "pallindrome";
};

console.log(checkPallindrome("madam"));

`,
    },
    {
      id: 3,
      ques: "Javascript program to Find the factorial of given number ?",
      difficulty: "Beginner",
      views: "987",
      description: [
        {
          htmlElement: "p",
          value:
            "Given a <span class='highlight'> non-negative integer</span> <span class='highlight'>num</span>, your task is to compute and return the factorial of num. The factorial of a non-negative integer num is the product of all positive integers less than or equal to num. ",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const num = 5;
  console.log(factorial(num)); 
  // Output: 120
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const n = 0;
  console.log(factorial(n)); 
  // Output: 1
          `,
        },
      ],
      structure: `

const findFactorial = (num) => {


};

console.log(findFactorial(4));
        `,
      solution: `

const findFactorial = (num) => {
  if (num == 0 || num == 1) {
    return 1;
  } else {
    return num * findFactorial(num - 1);
  }
};

console.log(findFactorial(4));
        `,
    },
    {
      id: 4,
      ques: "Javascript Program to find longest word in a given sentence ?",
      difficulty: "Beginner",
      views: "2986",
      description: [
        {
          htmlElement: "p",
          value:
            "Given a string <span class='highlight'> sentence</span>, find and return the longest word in the sentence. If there are multiple words with the same maximum length, return the first such word encountered.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const sentence = "The fox jumped";
  console.log(longestWord(sentence)); 
  // Output: "jumped"
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const sentence = "Hello world";
  console.log(longestWord(sentence)); 
  // Output: "Hello"
          `,
        },
      ],
      structure: `

const findLongestWord = (sentence) => {


};

findLongestWord("Hi Iam Saikrishna Iam a UI Developer");
        `,
      solution: `

const findLongestWord = (sentence) => {
  let wordsArray = sentence.split(" ");
  let longestWord = "";

  for (let i = 0; i < wordsArray.length; i++) {
    if (wordsArray[i].length > longestWord.length) {
      longestWord = wordsArray[i];
    }
  }

  console.log(longestWord);
};

findLongestWord("Hi Iam Saikrishna Iam a UI Developer");
        `,
    },
    {
      id: 5,
      ques: "Write a JavaScript program to find the maximum number in an array ?",
      difficulty: "Beginner",
      views: "1768",
      description: [
        {
          htmlElement: "p",
          value:
            "Given an array of integers <span class='highlight'> nums</span>, your task is to find and return the maximum number in the array. You should write a function that efficiently determines the largest number in the given array.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nums = [1, 2, 3, 4, 5];
  console.log(findMax(nums)); 
  // Output: 5
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nums = [-1, -2, -3, -4, -5];
  console.log(findMax(nums)); // Output: -1
          `,
        },
      ],
      structure: `

function findMax(arr) {


}

// Example usage:
const numbers = [1, 6, -33, 9, 4, 8, 2];
console.log("Maximum number is:", findMax(numbers));
        `,
      solution: `

function findMax(arr) {
    if (arr.length === 0) {
        return undefined; // Handle empty array case
    }

    let max = arr[0]; // Initialize max with the first element

    for (let i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            max = arr[i]; // Update max if current element is greater
        }
    }

    return max;
}

// Example usage:
const numbers = [1, 6, -33, 9, 4, 8, 2];
console.log("Maximum number is:", findMax(numbers));
        `,
    },
    {
      id: 6,
      ques: "Write a JavaScript function to check if a given number is prime ?",
      difficulty: "Beginner",
      views: "1099",
      description: [
        {
          htmlElement: "p",
          value:
            "Given an integer <span class='highlight'> num</span>, your task is to determine if the number is a prime number. A prime number is a natural number greater than 1 that has no positive divisors other than 1 and itself.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const num = 5;
  console.log(isPrime(num)); 
  // Output: true
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const num = 4;
  console.log(isPrime(num)); 
  // Output: false
          `,
        },
      ],
      structure: `

function isPrime(number) {


}

// Example usage:
console.log(isPrime(17)); // true
console.log(isPrime(19)); // false        
        `,
      solution: `

function isPrime(number) {
    if (number <= 1) {
        return false; // 1 and numbers less than 1 are not prime
    }

    // Loop up to the square root of the number
    for (let i = 2; i <= Math.sqrt(number); i++) {
        if (number % i === 0) {
            return false; // If divisible by any number, not prime
        }
    }

    return true; // If not divisible by any number, it's prime
}

// Example usage:
console.log(isPrime(17)); // true
console.log(isPrime(19)); // false        
        `,
    },
    {
      id: 7,
      ques: "Program to find Reverse of a string without using built-in method ?",
      difficulty: "Beginner",
      views: "3197",
      description: [
        {
          htmlElement: "p",
          value:
            "Given a string <span class='highlight'> str</span>, your task is to return the reverse of the string without using any built-in string manipulation methods. Implement a function that reverses the given string.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const str = "hello";
  console.log(reverseString(str)); 
  // Output: "olleh"
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s = "world";
  console.log(reverseString(s)); 
  // Output: "dlrow"
          `,
        },
      ],
      structure: `

const findReverse = (sampleString) => {


};

findReverse("Hello Iam Saikrishna Ui Developer");
        `,
      solution: `

const findReverse = (sampleString) => {
  let reverse = "";

  for (let i = sampleString.length - 1; i >= 0; i--) {
    reverse += sampleString[i];
  }
  console.log(reverse);
};

findReverse("Hello Iam Saikrishna Ui Developer");
        `,
    },
    {
      id: 8,
      ques: "Javascript program Find the smallest word in a given sentence ?",
      difficulty: "Beginner",
      views: "2321",
      description: [
        {
          htmlElement: "p",
          value:
            "Given a string sentence, find and return the <span class='highlight'> sentence</span> word in the sentence. If there are multiple words with the same minimum length, return the first such word encountered.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const sentence = "The quick brown";
  console.log(smallestWord(sentence)); 
  // Output: "The"
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const sentence = "Hello a test";
  console.log(smallestWord(sentence)); 
  // Output: "a"
          `,
        },
      ],
      structure: `

function findSmallestWord() {


}

findSmallestWord();
                                                        
        `,
      solution: `

function findSmallestWord() {
  const sentence = "Find the smallest word";
  const words = sentence.split(' ');
  let smallestWord = words[0];

  for (let i = 1; i < words.length; i++) {
      if (words[i].length < smallestWord.length) {
          smallestWord = words[i];
      }
  }
  console.log(smallestWord);
}

findSmallestWord();
                                                        
        `,
    },
    {
      id: 9,
      ques: "Function that returns the Fibonacci sequence up to a given number of terms",
      difficulty: "Beginner",
      views: "1998",
      description: [
        {
          htmlElement: "p",
          value:
            "Given an integer <span class='highlight'> n</span>, your task is to generate the Fibonacci sequence up to n terms. The Fibonacci sequence is a series of numbers where each number is the sum of the two preceding ones, usually starting with 0 and 1.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const n = 5;
  console.log(fibonacciSequence(n)); 
  // Output: [0, 1, 1, 2, 3]
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const n = 10;
  console.log(fibonacciSequence(n)); 
  // Output: [0, 1, 1, 2, 3, 5, 8, 13, 21, 34]
          `,
        },
      ],
      structure: `

function fibonacciSequence(numTerms) {


}

// Example usage:
const numTerms = 10;
const fibonacciSeries = fibonacciSequence(numTerms);
console.log(fibonacciSeries); // Output: [0, 1, 1, 2, 3, 5, 8, 13, 21, 34]

        `,
      solution: `

function fibonacciSequence(numTerms) {
    if (numTerms <= 0) {
        return [];
    } else if (numTerms === 1) {
        return [0];
    }

    const sequence = [0, 1];

    for (let i = 2; i < numTerms; i++) {
        const nextFibonacci = sequence[i - 1] + sequence[i - 2];
        sequence.push(nextFibonacci);
    }

    return sequence;
}

// Example usage:
const numTerms = 10;
const fibonacciSeries = fibonacciSequence(numTerms);
console.log(fibonacciSeries); // Output: [0, 1, 1, 2, 3, 5, 8, 13, 21, 34]

        `,
    },
    {
      id: 10,
      ques: "Javascript program to Find the max count of consecutive 1’s in an array ?",
      difficulty: "Beginner",
      views: "2132",
      description: [
        {
          htmlElement: "p",
          value:
            "Given a binary array <span class='highlight'> nums</span>, find and return the maximum number of consecutive 1s in the array. Implement a function that determines the length of the longest run of 1s in the given binary array.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nums = [1, 1, 0, 1, 1, 1];
  console.log(findConsecutive(nums)); 
  // Output: 3
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nums = [1, 0, 1, 1, 0, 1];
  console.log(findConsecutive(nums)); 
  // Output: 2
          `,
        },
      ],
      structure: `

const findConsecutive = (array) => {


};

findConsecutive([1, 1, 9, 1, 9, 9, 19, 7, 1, 1, 1, 3, 2, 5, 1]);
// output: 3
        `,
      solution: `

const findConsecutive = (array) => {
  let maxCount = 0;
  let currentConsCount = 0;

  for (let i = 0; i <= array.length - 1; i++) {
    if (array[i] === 1) {
      currentConsCount += 1;
      maxCount = Math.max(currentConsCount, maxCount);
    } else {
      currentConsCount = 0;
    }
  }

  console.log(maxCount);
};

findConsecutive([1, 1, 9, 1, 9, 9, 19, 7, 1, 1, 1, 3, 2, 5, 1]);
// output: 3
        `,
    },
    {
      id: 11,
      ques: "Given 2 arrays that are sorted. Merge them and into single array and sort it ",
      difficulty: "Intermediate",
      views: "2312",
      description: [
        {
          htmlElement: "p",
          value: "Given two arrays <span class='highlight'> arr1</span> and <span class='highlight'> arr2</span>, both of which are <span class='highlight'> sorted</span> in non-decreasing order, merge them into a single sorted array. The resulting array should be in non-decreasing order and include all the elements from both arr1 and arr2.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr1 = [1, 3, 5];
  const arr2 = [2, 4, 6];
  console.log(mergeAndSort(arr1, arr2)); 
  // Output: [1, 2, 3, 4, 5, 6]
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr1 = [0, 4, 7];
  const arr2 = [1, 2, 3, 5, 6];
  console.log(mergeAndSort(arr1, arr2)); 
  // Output: [0, 1, 2, 3, 4, 5, 6, 7]
          `,
        },
      ],
      structure: `
        
const sortedData = (arr1,arr2) => {


}
  
sortedData([1,3,4,5],[2,6,8,9])
        `,
      solution: `
        
const sortedData = (arr1,arr2) => {

  let i = 1;
    let j=1;
    let array1 = arr1[0];
    let array2 = arr2[0];
    
    let mergedArray = [];
    
    while(array1 || array2){
      
      if(array2 === undefined || array1 < array2){
        mergedArray.push(array1);
        array1 = arr1[i];
        i++
      }else{
        mergedArray.push(array2);
        array2 = arr2[j];
        j++
      }
      
    }
    console.log(mergedArray)
}
  
sortedData([1,3,4,5],[2,6,8,9])
        `,
    },
    {
      id: 12,
      ques: "Find if one string can be formed by rearranging the letters of other string 'qwerty','qeywrt' ==> true",
      difficulty: "Intermediate",
      views: "3021",
      description: [
        {
          htmlElement: "p",
          value: "Given two strings <span class='highlight'> s1</span> and <span class='highlight'> s2</span>, determine if s2 can be formed by rearranging the letters of s1. Two strings are considered anagrams if they contain the same characters with the same frequency but in any order.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s1 = "qwerty";
  const s2 = "qeywrt";
  console.log(isAnagram(s1, s2)); 
  // Output: true
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s1 = "listen";
  const s2 = "silent";
  console.log(isAnagram(s1, s2)); 
  // Output: true
          `,
        },
      ],
      structure: `
        

function isStringCreated(str1,str2){


}

console.log(isStringCreated('anagram','nagaram'))
        `,
      solution: `
        

function isStringCreated(str1,str2){
  if(str1.length !== str2.length) return false
  let freq = {};
  
  for(let val of str1){
    freq[val] = (freq[val] || 0) + 1;
  }
  
  for(let val of str2){
    if(freq[val]){
      freq[val] -= 1;
    } else{
      return false;
    }    
  } 
  return true;
}

console.log(isStringCreated('anagram','nagaram'))
        `,
    },
    {
      id: 13,
      ques: "Write a program to find largest element in nested array [[3, 4, 58], [709, 8, 9, [10, 11]], [111, 2]]",
      difficulty: "Intermediate",
      views: "1768",
      description: [
        {
          htmlElement: "p",
          value: "Given a <span class='highlight'> nested array</span> where each element can be either an integer or another array (which can also contain integers or other arrays), find and return the largest integer in the entire structure.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
const nestedArray = [[-1, -5], [-2, [-3, [-4]]]];
console.log(findLargestElement(nestedArray)); 
// Output: -1
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nestedArray = [1, [2, [3, [4]]]];
  console.log(findLargestElement(nestedArray)); 
  // Output: 4
          `,
        },
      ],
      structure: `

function findLargestElement(arr) {


}

// Example usage:
const nestedArray = [[3, 4, 58], [709, 8, 9, [10, 11]], [111, 2]];
console.log("Largest element:", findLargestElement(nestedArray));
        `,
      solution: `

function findLargestElement(arr) {
    let max = Number.NEGATIVE_INFINITY; // Initialize max to smallest possible number

    // Helper function to traverse nested arrays
    function traverse(arr) {
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                // If element is an array, recursively call traverse function
                traverse(arr[i]);
            } else {
                // If element is not an array, update max if needed
                if (arr[i] > max) {
                    max = arr[i];
                }
            }
        }
    }

    // Start traversing the input array
    traverse(arr);

    return max;
}

// Example usage:
const nestedArray = [[3, 4, 58], [709, 8, 9, [10, 11]], [111, 2]];
console.log("Largest element:", findLargestElement(nestedArray));
        `,
    },
    {
      id: 14,
      ques: "Given a string, write a javascript function to count the occurrences of each character in the string",
      difficulty: "Beginner",
      views: "2112",
      description: [
        {
          htmlElement: "p",
          value: "Given a string <span class='highlight'> s</span>, your task is to count the occurrences of each character in the string and return an object where the keys are the characters and the values are their corresponding counts.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s = "hello";
  console.log(countCharacterOccurrences(s)); 
  // Output: { h: 1, e: 1, l: 2, o: 1 }
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s = "character";
  console.log(countCharacterOccurrences(s)); 
  // Output: { c: 2, h: 1, a: 2, r: 2, t: 1, e: 1 }
          `,
        },
      ],
      structure: `
        
function countCharacters(str) {


}

// Example usage:
const result = countCharacters("helaalo");
console.log(result); // Output: { h: 1, e: 1, l: 2, o: 1 }

        `,
      solution: `
        
function countCharacters(str) {
    const charCount = {}; // Object to store character counts
    const len = str.length;

    // Loop through the string and count occurrences of each character
    for (let i = 0; i < len; i++) {
        const char = str[i];
        // Increment count for each character
        charCount[char] = (charCount[char] || 0) + 1; 
    }

    return charCount;
}

// Example usage:
const result = countCharacters("helaalo");
console.log(result); // Output: { h: 1, e: 1, l: 2, o: 1 }

        `,
    },
    {
      id: 15,
      ques: "Write a javascript function that sorts an array of numbers in ascending order.",
      difficulty: "Intermediate",
      views: "2431",
      description: [
        {
          htmlElement: "p",
          value: "You are given an array of integers <span class='highlight'> nums</span>. Your task is to sort the array in ascending order and return the sorted array.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nums = [5, 3, 8, 1, 2];
  console.log(sortArray(nums)); 
  // Output: [1, 2, 3, 5, 8]
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const nums = [12, -5, 0, 7, 3];
  console.log(sortArray(nums)); 
  // Output: [-5, 0, 3, 7, 12]
          `,
        },
      ],
      structure: `
        
function quickSort(arr) {
  

}

// Example usage:
const unsortedArray = [5, 2, 9, 1, 3, 6];
const sortedArray = quickSort(unsortedArray);
console.log(sortedArray); // Output: [1, 2, 3, 5, 6, 9]
        `,
      solution: `
        
function quickSort(arr) {
    // Check if the array is empty or has only one element
    if (arr.length <= 1) {
        return arr;
    }

    // Select a pivot element
    const pivot = arr[0];
    
    // Divide the array into two partitions
    const left = [];
    const right = [];
    
    for (let i = 1; i < arr.length; i++) {
        if (arr[i] < pivot) {
            left.push(arr[i]);
        } else {
            right.push(arr[i]);
        }
    }

    // Recursively sort the partitions
    const sortedLeft = quickSort(left);
    const sortedRight = quickSort(right);

    // Concatenate the sorted partitions with the pivot and return
    return sortedLeft.concat(pivot, sortedRight);
}

// Example usage:
const unsortedArray = [5, 2, 9, 1, 3, 6];
const sortedArray = quickSort(unsortedArray);
console.log(sortedArray); // Output: [1, 2, 3, 5, 6, 9]
        `,
    },
    {
      id: 16,
      ques: "Write a function that reverses the order of words in a sentence without using built-in methods",
      difficulty: "Beginner",
      views: "2674",
      description: [
        {
          htmlElement: "p",
          value: ">Given a string <span class='highlight'> s</span representing a sentence, your task is to reverse the order of words in the sentence without using built-in methods or library functions that directly provide this functionality. A word is defined as a sequence of non-space characters separated by spaces.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s = "the quick brown fox";
  console.log(reverseWords(s));
  // Output: "fox brown quick the"

          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s = "  hello world  ";
  console.log(reverseWords(s)); 
  // Output: "world hello"
          `,
        },
      ],
      structure: `
        
const reverseWords = (sampleString) => {
 

};

// Example usage
reverseWords("learndepth is awesome"); //"awesome is learndepth"
        `,
      solution: `
        
const reverseWords = (sampleString) => {
  let reversedSentence = "";
  let word = "";

  // Iterate over each character in the sampleString
  for (let i = 0; i < sampleString.length; i++) {
    // If the character is not a space, append it to the current word
    if (sampleString[i] !== ' ') {
      word += sampleString[i];
    } else {
      // If it's a space, prepend the current word to the reversed sentence and 
      //reset the word
      reversedSentence = word + ' ' + reversedSentence;
      word = "";
    }
  }

  // Append the last word to the reversed sentence
  reversedSentence = word + ' ' + reversedSentence;

  // Trim any leading or trailing spaces and log the result
  console.log(reversedSentence.trim());
};

// Example usage
reverseWords("learndepth is awesome"); //"awesome is learndepth"
        `,
    },
    {
      id: 17,
      ques: "Implement a javascript function that flattens a nested array into a single-dimensional array",
      difficulty: "Beginner",
      views: "2762",
      description: [
        {
          htmlElement: "p",
          value: "Given a nested array <span class='highlight'> arr</span>, where each element can be either an integer or another array (which can also contain integers or other arrays), your task is to flatten the array into a single-dimensional array.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [1, [2, 3], [4, [5, [6]]]];
  console.log(flattenArray(arr)); 
  // Output: [1, 2, 3, 4, 5, 6]
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [1, [2, [3, [4, [5]]]]];
  console.log(flattenArray(arr));
  // Output: [1, 2, 3, 4, 5]
          `,
        },
      ],
      structure: `

function flattenArray(arr) {
   

}

// Example usage:
const nestedArray = [1, [2, [3, 4], [7,5]], 6];
const flattenedArray = flattenArray(nestedArray);
console.log(flattenedArray); // Output: [1, 2, 3, 4, 5, 6]

        `,
      solution: `

function flattenArray(arr) {
    const stack = [...arr];
    const result = [];

    while (stack.length) {
        const next = stack.pop();
        if (Array.isArray(next)) {
            stack.push(...next);
        } else {
            result.push(next);
        }
    }

    return result.reverse(); // Reverse the result to maintain original order
}

// Example usage:
const nestedArray = [1, [2, [3, 4], [7,5]], 6];
const flattenedArray = flattenArray(nestedArray);
console.log(flattenedArray); // Output: [1, 2, 3, 4, 5, 6]

        `,
    },
    {
      id: 18,
      ques: "Write a function which converts ('a.b.c', 'someValue') to object {a: {b: {c: 'someValue'}}}",
      difficulty: "Intermediate",
      views: "2323",
      description: [
        {
          htmlElement: "p",
          value: "Given a string path in <span class='highlight'> dot-notation</span> format and a value, your task is to convert the dot-notation path into a nested object where each dot-separated part represents a key, and the value is assigned to the deepest nested key.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const path = 'a.b.c';
  const value = 'someValue';
  console.log(dotNotationToObject(path, value));
  // Output: { a: { b: { c: 'someValue' } } }
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const path = 'x.y.z';
  const value = 42;
  console.log(dotNotationToObject(path, value));
  // Output: { x: { y: { z: 42 } } }
          `,
        },
      ],
      structure: `
        
function stringToObject(str, finalValue) {
 

}

// Test the function
const output = stringToObject("a.b.c", "someValue");
console.log(output); // Output: {a: {b: {c: "someValue"}}}

        `,
      solution: `
        
function stringToObject(str, finalValue) {
  const keys = str.split('.');
  let result = {};
  let current = result;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    current[key] = (i === keys.length - 1) ? finalValue : {};
    current = current[key];
  }

  return result;
}

// Test the function
const output = stringToObject("a.b.c", "someValue");
console.log(output); // Output: {a: {b: {c: "someValue"}}}

        `,
    },
    {
      id: 19,
      ques: "Given an array,return an array where the each value is the product of the next two items [3, 4, 5] -> [20, 15, 12]",
      difficulty: "Intermediate",
      views: "1887",
      description: [
        {
          htmlElement: "p",
          value: "Given an array of integers <span class='highlight'> arr</span>, your task is to transform it into a new array where each element at index i is the product of the next two elements in the input array.",
        }
      ],
      structure: ``,
      solution: `
        `,
    },
    {
      id: 20,
      ques: "Find the 2nd largest element from a given array ? [100,20,112,22]",
      difficulty: "Beginner",
      views: "2614",
      description: [
        {
          htmlElement: "p",
          value: "Given an array of integers <span class='highlight'> arr</span>, your task is to find the 2nd largest distinct element in the array. If there is no distinct 2nd largest element (e.g., if all elements are the same or there are fewer than two distinct elements), return -1.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [100, 20, 112, 22];
  console.log(findSecondLargest(arr)); 
  // Output: 100
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [1, 2, 2, 3, 3];
  console.log(findSecondLargest(arr)); 
  // Output: 2
          `,
        },
      ],
      structure: `
function findSecondLargest(arr) {
   

}

// Example usage:
const array = [10, 5, 20, 8, 12];
console.log(findSecondLargest(array)); // Output: 12
        `,
      solution: `
function findSecondLargest(arr) {
    if (arr.length < 2) {
        throw new Error("Array must contain at least two elements.");
    }

    let largest = -Infinity;
    let secondLargest = -Infinity;

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] > largest) {
            secondLargest = largest;
            largest = arr[i];
        } else if (arr[i] > secondLargest && arr[i] < largest) {
            secondLargest = arr[i];
        }
    }

    if (secondLargest === -Infinity) {
        throw new Error("There is no second largest element in the array.");
    }

    return secondLargest;
}

// Example usage:
const array = [10, 5, 20, 8, 12];
console.log(findSecondLargest(array)); // Output: 12
        `,
    },
    {
      id: 21,
      ques: "Write a program to get below output from given input ? I/P: abbcccddddeea, O/P: 1a2b3c4d2e1a",
      difficulty: "Intermediate",
      views: "1323",
      description: [
        {
          htmlElement: "p",
          value: "Given a string <span class='highlight'> s</span>, your task is to encode it using run-length encoding. In run-length encoding, consecutive occurrences of the same character are replaced with the character followed by the number of occurrences.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s = "abbcccddddeea";
  console.log(runLengthEncoding(s)); 
  // Output: "1a2b3c4d2e1a"
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const s = "aabbcc";
  console.log(runLengthEncoding(s)); 
  // Output: "2a2b2c"
          `,
        },
      ],
      structure: `
function encodeString(input) {

}

const input = "abbcccddddeea";
const output = encodeString(input);
console.log(output);  // Outputs: 1a2b3c4d2e1a
        `,
      solution: `
function encodeString(input) {
    if (input.length === 0) return "";

    let result = "";
    let count = 1;

    for (let i = 1; i < input.length; i++) {
        if (input[i] === input[i - 1]) {
            count++;
        } else {
            result += count + input[i - 1];
            count = 1;
        }
    }

    // Add the last sequence
    result += count + input[input.length - 1];

    return result;
}

const input = "abbcccddddeea";
const output = encodeString(input);
console.log(output);  // Outputs: 1a2b3c4d2e1a
        `,
    },
    {
      id: 22,
      ques: "Find pairs from given input ? input1 = [1, 2, 3, 4, 5, 6, 7, 8, 9]; input2 = 10; output = [[4, 6], [3, 7], [2, 8], [1, 9]]",
      difficulty: "Beginner",
      views: "1234",
      description: [
        {
          htmlElement: "p",
          value: "Given an array of integers <span class='highlight'> arr</span> and a target integer target, your task is to find all unique pairs of integers in the array that sum up to the target value. Each pair should be returned in ascending order, and the result should be a list of pairs sorted by the first element of each pair.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const target = 10;
  console.log(findPairsWithSum(arr, target));
  // Output: [[1, 9], [2, 8], [3, 7], [4, 6]]
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [5, 7, 9, 11];
  const target = 16;
  console.log(findPairsWithSum(arr, target));
  // Output: [[7, 9]]

          `,
        },
      ],
      structure: `
function findPairs(input1, input2) {

}

const input1 = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const input2 = 10;

const output = findPairs(input1, input2);
console.log(output); // [[1, 9], [2, 8], [3, 7], [4, 6], [5, 5]]

        `,
      solution: `
function findPairs(input1, input2) {
  const pairs = [];
  const seen = new Set();

  for (const num of input1) {
    const complement = input2 - num;
    if (seen.has(complement)) {
      pairs.push([complement, num]);
    }
    seen.add(num);
  }

  return pairs;
}

const input1 = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const input2 = 10;

const output = findPairs(input1, input2);
console.log(output); // [[1, 9], [2, 8], [3, 7], [4, 6], [5, 5]]

        `,
    },
    {
      id: 23,
      ques: "Write a javascript  function that sorts an array of numbers in descending order",
      difficulty: "Beginner",
      views: "1321",
      description: [
        {
          htmlElement: "p",
          value: "Given an array of integers <span class='highlight'> arr</span>, your task is to sort the array in descending order and return the sorted array.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [4, 2, 9, 1, 5, 6];
  console.log(sortDescending(arr)); 
  // Output: [9, 6, 5, 4, 2, 1]
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [3, 7, 2, 8, 5];
  console.log(sortDescending(arr)); 
  // Output: [8, 7, 5, 3, 2]
          `,
        },
      ],
      structure: `
function quickSort(arr) {

}

const arr = [3, 1, 4, 1, 5, 9, 2, 6, 5];
const sortedArr = quickSort(arr);
console.log(sortedArr); // Output: [9, 6, 5, 5, 4, 3, 2, 1, 1]
        `,
      solution: `
function quickSort(arr) {
    if (arr.length <= 1) {
        return arr;
    }

    const pivot = arr[0];
    const left = [];
    const right = [];

    for (let i = 1; i < arr.length; i++) {
        if (arr[i] >= pivot) {
            left.push(arr[i]);
        } else {
            right.push(arr[i]);
        }
    }

    return [...quickSort(left), pivot, ...quickSort(right)];
}

const arr = [3, 1, 4, 1, 5, 9, 2, 6, 5];
const sortedArr = quickSort(arr);
console.log(sortedArr); // Output: [9, 6, 5, 5, 4, 3, 2, 1, 1]
        `,
    },
    {
      id: 24,
      ques: "function that return true if every value in arr1 has corresponding value squared in array2. The frequency of values must be same.",
      difficulty: "Beginner",
      views: "1109",
      description: [
        {
          htmlElement: "p",
          value: "Given two arrays <span class='highlight'> arr1</span> and <span class='highlight'> arr2</span>, your task is to determine if every value in arr1 has a corresponding value squared in arr2, such that the frequency of these squared values matches the frequency of the original values in arr1. Specifically, for each value x in arr1, there must be a value x^2 in arr2 with the same frequency.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr1 = [1, 2, 3, 4];
  const arr2 = [1, 4, 9, 16];
  console.log(hasMatchingSquares(arr1, arr2)); 
  // Output: true
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr1 = [1, 2, 3];
  const arr2 = [1, 4, 9, 9];
  console.log(hasMatchingSquares(arr1, arr2)); 
  // Output: false
          `,
        },
      ],
      structure: `

function isSameFrequency(arr1,arr2){

}

console.log(isSameFrequency([1,2,5],[25,4,1]))

// [1,2,3],[4,1,9] ==> true
// [1,2,3],[1,9] ====> false
// [1,2,1],[4,4,1] ===> false (must be same frequency)
        `,
      solution: `

function isSameFrequency(arr1,arr2){

  if(arr1.length !== arr2.length){
      return false;
  }

  let arrFreq1={};
  let arrFreq2={};

  for(let val of arr1){
    arrFreq1[val] = (arrFreq1[val] || 0) + 1;
  }

  for(let val of arr2){
    arrFreq2[val] = (arrFreq2[val] || 0) + 1;
  }

  for(let key in arrFreq1){
        if(!key*key in arrFreq2) return false;
        if(arrFreq1[key] !== arrFreq2[key*key]) return false
  }
  return true;

}

console.log(isSameFrequency([1,2,5],[25,4,1]))
        `,
    },
    {
      id: 25,
      ques: "Write a function sumOfThirds(arr) which should return a sum of every third number in the array, starting from the first one.",
      difficulty: "Beginner",
      views: "998",
      description: [
        {
          htmlElement: "p",
          value: "Given an array of integers <span class='highlight'> arr</span>, your task is to calculate the sum of every third number in the array, starting from the first one. Specifically, you should sum the elements at indices 0, 3, 6, 9, and so on.",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [10, 20, 30, 40, 50, 60, 70, 80, 90];
  console.log(sumOfThirds(arr)); 
  // Output: 10 + 40 + 70 = 120
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  console.log(sumOfThirds(arr)); 
  // Output: 1 + 4 + 7 + 10 = 22
          `,
        },
      ],
      structure: `

const sumOfThirds = (arr) => {

};           

console.log(sumOfThirds([1,2,3,4,5,6,-10]))                                    
        `,
      solution: `

const sumOfThirds = (arr) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i += 3) {
    sum += arr[i];
  }
  return sum;
};                                        
        
console.log(sumOfThirds([1,2,3,4,5,6,-10]))                                    
        `,
    },
    {
      id: 26,
      ques: "Find the longest common prefix among all strings in given array ['flower', 'flow', 'flight'] ",
      difficulty: "Intermediate",
      views: "197",
      description: [
        {
          htmlElement: "p",
          value: "Given an array of strings strs, find the longest common prefix among all the strings. If there is no common prefix, return an empty string ''",
        },
        {
          htmlElement: "SubHeader",
          value: "Example 1",
        },
        {
          htmlElement: "Syntax",
          value: `
  const strs = ["flower", "flow", "flight"];
  console.log(longestCommonPrefix(strs));
  // Output: "fl"
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "Example 2",
        },
        {
          htmlElement: "Syntax",
          value: `
  const strs = ["dog", "racecar", "car"];
  console.log(longestCommonPrefix(strs)); 
  // Output: ""
          `,
        },
      ],
      structure: `

  const longestCommonPrefix = (arr) => {






  };           

  console.log(longestCommonPrefix(["flower", "flow", "flight"]))                                    
        `,
      solution: `

  const longestCommonPrefix = (arr) => {






  };           
  
  console.log(longestCommonPrefix(["flower", "flow", "flight"]))                                     
        `,
    },
  ],
};
