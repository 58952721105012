// eslint-disable-next-line import/no-anonymous-default-export
export default {
  data: [
    {
      question: "What is VueJS?",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue.js is an open-source, progressive Javascript framework for building user interfaces that aim to be incrementally adoptable. The core library of VueJS is focused on the view layer only, and is easy to pick up and integrate with other libraries or existing projects.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the major features of VueJS?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the some of major features available with VueJS</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Virtual DOM: It uses virtual DOM similar to other existing frameworks such as ReactJS, Ember etc. Virtual DOM is a light-weight in-memory tree representation of the original HTML DOM and updated without affecting the original DOM. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Components: Used to create reusable custom elements in VueJS applications. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Templates: VueJS provides HTML based templates that bind the DOM with the Vue instance data  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Routing: Navigation between pages is achieved through vue-router </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Light weight: VueJS is light weight library compared to other frameworks. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the lifecycle methods of VueJS?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Lifecycle hooks are a window into how the library you’re using works behind-the-scenes. By using these hooks, you will know when your component is created, added to the DOM, updated, or destroyed. Let's look at lifecycle diagram before going to each lifecycle hook in detail,</span>",
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span> Creation(Initialization): </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Creation Hooks allow you to perform actions before your component has even been added to the DOM. You need to use these hooks if you need to set things up in your component both during client rendering and server rendering. Unlike other hooks, creation hooks are also run during server-side rendering.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>beforeCreate: This hook runs at the very initialization of your component. hook observes data and initialization events in your component. Here, data is still not reactive and events that occur during the component’s lifecycle have not been set up yet.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          new Vue({
            data: {
             count: 10
            },
            beforeCreate: function () {
              console.log('Nothing gets called at this moment')
              // 'this' points to the view model instance
              console.log('count is ' + this.count);
            }
          })
             // count is undefined
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>created: This hook is invoked when Vue has set up events and data observation. Here, events are active and access to reactive data is enabled though templates have not yet been mounted or rendered.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          new Vue({
            data: {
             count: 10
            },
            created: function () {
              // 'this' points to the view model instance
              console.log('count is: ' + this.count)
            }
          })
             // count is: 10
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: Remember that, You will not have access to the DOM or the target mounting element (this.$el) inside of creation hooks</span>",
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>Mounting(DOM Insertion): </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Mounting hooks are often the most-used hooks and they allow you to access your component immediately before and after the first render.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>beforeMount: The beforeMount allows you to access your component immediately before and after the first render.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          new Vue({
            beforeMount: function () {
              // 'this' points to the view model instance
              console.log('this.$el is yet to be created');
            }
          })
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>mounted: This is a most used hook and you will have full access to the reactive component, templates, and rendered DOM (via. this.$el). The most frequently used patterns are fetching data for your component.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="app">
    <p>I’m text inside the component.</p>
  </div>
  
  new Vue({
    el: ‘#app’,
      mounted: function() {
        console.log(this.$el.textContent); // I'm text inside the component.
      }
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Updating (Diff & Re-render): Updating hooks are called whenever a reactive property used by your component changes, or something else causes it to re-render</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>beforeUpdate: The beforeUpdate hook runs after data changes on your component and the update cycle begins, right before the DOM is patched and re-rendered.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
      <div id="app">
      <p>{{counter}}</p>
      </div>
      
      ...// rest of the code
      new Vue({
      el: '#app',
      data() {
        return {
          counter: 0
        }
      },
      created: function() {
        setInterval(() => {
          this.counter++
        }, 1000)
      },
    
      beforeUpdate: function() {
        console.log(this.counter) // Logs the counter value every second, before the DOM updates.
      }
      })
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>updated: This hook runs after data changes on your component and the DOM re-renders.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
      <div id="app">
        <p ref="dom">{{counter}}</p>
      </div>
      ...//
      new Vue({
        el: '#app',
        data() {
          return {
            counter: 0
          }
        },
         created: function() {
          setInterval(() => {
            this.counter++
          }, 1000)
        },
        updated: function() {
          console.log(+this.$refs['dom'].textContent === this.counter) // Logs true every second
        }
      })
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>Destruction (Teardown): </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Destruction hooks allow you to perform actions when your component is destroyed, such as cleanup or analytics sending.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>beforeDestroy: beforeDestroy is fired right before teardown. If you need to cleanup events or reactive subscriptions, beforeDestroy would probably be the time to do it. Your component will still be fully present and functional.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          new Vue ({
            data() {
              return {
                message: 'Welcome VueJS developers'
              }
            },
          
            beforeDestroy: function() {
              this.message = null
              delete this.message
            }
          })
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>destroyed: This hooks is called after your component has been destroyed, its directives have been unbound and its event listeners have been removed.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          new Vue ({
            destroyed: function() {
              console.log(this) // Nothing to show here
            }
          })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the conditional directives?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>VueJS provides set of directives to show or hide elements based on conditions. The available directives are: v-if, v-else, v-else-if and v-show</span>",
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span> v-if:  </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> The v-if directive adds or removes DOM elements based on the given expression. For example, the below button will not show if isLoggedIn is set to false. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          <button v-if="isLoggedIn">Logout</button>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> You can also control multiple elements with a single v-if statement by wrapping all the elements in a <template> element with the condition. For example, you can have both label and button together conditionally applied, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template v-if="isLoggedIn">
    <label> Logout </button>
    <button> Logout </button>
  </template>
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>v-else: </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>This directive is used to display content only when the expression adjacent v-if resolves to false. This is similar to else block in any programming language to display alternative content and it is preceded by v-if or v-else-if block. You don't need to pass any value to this. For example, v-else is used to display LogIn button if isLoggedIn is set to false(not logged in).</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          <button v-if="isLoggedIn"> Logout </button>
          <button v-else> Log In </button>
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>v-else-if:</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>This directive is used when we need more than two options to be checked. For example, we want to display some text instead of LogIn button when ifLoginDisabled property is set to true. This can be achieved through v-else statement.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          <button v-if="isLoggedIn"> Logout </button>
          <label v-else-if="isLoginDisabled"> User login disabled </label>
          <button v-else> Log In </button>
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>v-show:</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>This directive is similar to v-if but it renders all elements to the DOM and then uses the CSS display property to show/hide elements. This directive is recommended if the elements are switched on and off frequently.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          <span v-show="user.name">Welcome user,{{user.name}}</span>
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "What is the difference between v-show and v-if directives?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are some of the main differences between v-show and v-if directives,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>v-if only renders the element to the DOM if the expression passes whereas v-show renders all elements to the DOM and then uses the CSS display property to show/hide elements based on expression.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>v-if supports v-else and v-else-if directives whereas v-show doesn't support else directives.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>v-if has higher toggle costs while v-show has higher initial render costs. i.e, v-show has a performance advantage if the elements are switched on and off frequently, while the v-if has the advantage when it comes to initial render time.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>v-if supports <template> tab but v-show doesn't support.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "   What is the purpose of v-for directive? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The built-in v-for directive allows us to loop through items in an array or object. You can iterate on each element in the array or object.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Array usage:  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <ul id="list">
  <li v-for="(item, index) in items">
    {{ index }} - {{ item.message }}
  </li>
  </ul>

  var vm = new Vue({
    el: '#list',
    data: {
      items: [
        { message: 'John' },
        { message: 'Locke' }
      ]
    }
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can also use of as the delimiter instead of in, similar to javascript iterators.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Object usage:  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="object">
  <div v-for="(value, key, index) of user">
    {{ index }}. {{ key }}: {{ value }}
  </div>
  </div>

  var vm = new Vue({
    el: '#object',
    data: {
      user: {
        firstName: 'John',
        lastName: 'Locke',
        age: 30
      }
    }
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is vue instance?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Every Vue application works by creating a new Vue instance with the Vue function. Generally the variable vm (short for ViewModel) is used to refer Vue instance. You can create vue instance as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  var vm = new Vue({
    // options
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>As mentioned in the above code snippets, you need to pass options object. You can find the full list of options in the API reference.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you achieve conditional group of elements?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can achieve conditional group of elements(toggle multiple elements at a time) by applying v-if directive on <template> element which works as invisible wrapper(no rendering) for group of elements.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, you can conditionally group user details based on valid user condition. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template v-if="condition">
    <h1>Name</h1>
    <p>Address</p>
    <p>Contact Details</p>
  </template>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you reuse elements with key attribute?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue always tries to render elements as efficient as possible. So it tries to reuse the elements instead of building them from scratch. But this behavior may cause problems in few scenarios.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, if you try to render the same input element in both v-if and v-else blocks then it holds the previous value as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template v-if="loginType === 'Admin'">
    <label>Admin</label>
    <input placeholder="Enter your ID">
  </template>
  <template v-else>
    <label>Guest</label>
    <input placeholder="Enter your name">
  </template>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>In this case, it shouldn't reuse. We can make both input elements as separate by applying key attribute as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template v-if="loginType === 'Admin'">
    <label>Admin</label>
    <input placeholder="Enter your ID" key="admin-id">
  </template>
  <template v-else>
    <label>Guest</label>
    <input placeholder="Enter your name" key="user-name">
  </template>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>The above code make sure both inputs are independent and doesn't impact each other.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "Why should not use if and for directives together on the same element?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>It is recommended not to use v-if on the same element as v-for. Because v-if directive has a higher priority than v-for.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> There are two cases where developers try to use this combination, </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> To filter items in a list </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, if you try to filter the list using v-if tag,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <ul>
    <li
      v-for="user in users"
      v-if="user.isActive"
      :key="user.id"
    >
      {{ user.name }}
    <li>
  </ul>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>This can be avoided by preparing the filtered list using computed property on the initial list</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  computed: {
    activeUsers: function () {
      return this.users.filter(function (user) {
        return user.isActive
      })
    }
  }
  ...... //
  ...... //
  <ul>
    <li
      v-for="user in activeUsers"
      :key="user.id">
      {{ user.name }}
    <li>
  </ul>
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>To avoid rendering a list if it should be hidden </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, if you try to conditionally check if the user is to be shown or hidden</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <ul>
    <li
      v-for="user in users"
      v-if="shouldShowUsers"
      :key="user.id"
    >
      {{ user.name }}
    <li>
  </ul>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>This can be solved by moving the condition to a parent by avoiding this check for each user</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <ul v-if="shouldShowUsers">
    <li
      v-for="user in users"
      :key="user.id"
    >
      {{ user.name }}
    <li>
  </ul>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Why do you need to use key attribute on for directive?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In order to track each node’s identity, and thus reuse and reorder existing elements, you need to provide a unique key attribute for each item with in v-for iteration. An ideal value for key would be the unique id of each item.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let us take an example usage, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div v-for="item in items" :key="item.id">
    {{item.name}}
  </div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Hence, It is always recommended to provide a key with v-for whenever possible, unless the iterated DOM content is simple.  </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Note: You shouldn’t use non-primitive values like objects and arrays as v-for keys. Use string or numeric values instead. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What are the array detection mutation methods?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>As the name suggests, mutation methods modifies the original array.            </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Below are the list of array mutation methods which trigger view updates. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  push()
  pop()
  shift()
  unshift()
  splice()
  sort()
  reverse()
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> If you perform any of the above mutation method on the list then it triggers view update. For example, push method on array named 'items' trigger a view update, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  vm.todos.push({ message: 'Baz' })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the array detection non-mutation methods?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The methods which do not mutate the original array but always return a new array are called non-mutation methods.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Below are the list of non-mutation methods, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  filter()
  concat()
  slice()
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, lets take a todo list where it replaces the old array with new one based on status filter, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  vm.todos = vm.todos.filter(function (todo) {
    return todo.status.match(/Completed/)
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> This approach won't re-render the entire list due to VueJS implementation. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "What are the caveats of array changes detection?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue cannot detect changes for the array in the below two cases,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> When you directly set an item with the index,For example </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  vm.todos[indexOfTodo] = newTodo
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> When you modify the length of the array, For example </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  vm.todos.length = todosLength
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can overcome both the caveats using set and splice methods, Let's see the solutions with an examples,</span>",
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>First use case solution  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Vue.set
  Vue.set(vm.todos, indexOfTodo, newTodoValue)
  (or)
  // Array.prototype.splice
  vm.todos.splice(indexOfTodo, 1, newTodoValue)
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>Second use case solution  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  vm.todos.splice(todosLength)
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the caveats of object changes detection?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue cannot detect changes for the object in property addition or deletion</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Lets take an example of user data changes, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  var vm = new Vue({
    data: {
      user: {
        name: 'John'
      }
    }
  })
  
  // 'vm.name' is now reactive
  
  vm.user.email = john@email.com // 'vm.user.email' is NOT reactive
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> You can overcome this scenario using the Vue.set(object, key, value) method or Object.assign(), </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.set(vm.user, 'email', 'john@email.com');
  // (or)
  vm.user = Object.assign({}, vm.user, {
    email: john@email.com
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   How do you use v-for directive with a range? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can also use integer type(say 'n') for v-for directive which repeats the element many times.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div>
    <span v-for="n in 20">{{ n }} </span>
  </div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> It displays the number 1 to 20. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you use v-for directive on template?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<spanJust similar to v-if directive on template, you can also use a <template> tag with v-for directive to render a block of multiple elements.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take a todo example,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <ul>
    <template v-for="todo in todos">
      <li>{{ todo.title }}</li>
      <li class="divider"></li>
    </template>
  </ul>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "How do you use event handlers?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use event handlers in vue similar to plain javascript. The method calls also support the special $event variable.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <button v-on:click="show('Welcome to VueJS world', $event)">
    Submit
  </button>

  methods: {
    show: function (message, event) {
      // now we have access to the native event
      if (event) event.preventDefault()
      console.log(message);
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the event modifiers provided by vue?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Normally, javascript provides event.preventDefault() or event.stopPropagation() inside event handlers. You can use methods provided by vue, but these methods are meant for data logic instead of dealing with DOM events. Vue provides below event modifiers for v-on and these modifiers are directive postfixes denoted by a dot.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  .stop
  .prevent
  .capture
  .self
  .once
  .passive
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take an example of stop modifier,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- the click event's propagation will be stopped -->
  <a v-on:click.stop="methodCall"></a>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can also chain modifiers as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- modifiers can be chained -->
  <a v-on:click.stop.prevent="doThat"></a>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What are key modifiers? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue supports key modifiers on v-on for handling keyboard events. Let's take an example of keyup event with enter keycode.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- only call 'vm.show()' when the 'keyCode' is 13 -->
  <input v-on:keyup.13="show">
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Remembering all the key codes is really difficult. It supports the full list of key codes aliases</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  .enter
  .tab
  .delete (captures both “Delete” and “Backspace” keys)
  .esc
  .space
  .up
  .down
  .left
  .right
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now the above keyup code snippet can be written with aliases as follows,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input v-on:keyup.enter="submit" />
  <!-- OR with shorthand notation -->
  <input @keyup.enter="submit" />
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: The use of keyCode events is deprecated and may not be supported in new browsers.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you define custom key modifier aliases?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can define custom key modifier aliases via the global config.keyCodes. There are few guidelines for the properties</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> You can't use camelCase. Instead you can use kebab-case with double quotation marks </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> You can define multiple values in an array format </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.config.keyCodes = {
    f1: 112,
    "media-play-pause": 179,
    down: [40, 87]
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the supported System Modifier Keys?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue supports below modifiers to trigger mouse or keyboard event listeners when the corresponding key is pressed,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  .ctrl
  .alt
  .shift
  .meta
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Lets take an example of control modifier with click event,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- Ctrl + Click -->
  <div @click.ctrl="doSomething">Do something</div>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the supported Mouse Button Modifiers?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue supports below mouse button modifiers</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  .left
  .right
  .middle
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, the usage of .right modifier as below</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <button
    v-if="button === 'right'"
    v-on:mousedown.right="increment"
    v-on:mousedown.left="decrement"
  />
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you implement two-way binding?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use the v-model directive to create two-way data bindings on form input, textarea, and select elements.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Lets take an example of it using input component,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input v-model="message" placeholder="Enter input here">
  <p>The message is: {{ message }}</p>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Remember, v-model will ignore the initial value, checked or selected attributes found on any form elements. So it always use the Vue instance data as the source of truth.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the supported modifiers on model?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>There are three modifiers supported for v-model directive.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>1. lazy: By default, v-model syncs the input with the data after each input event. You can add the lazy modifier to instead sync after change events.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- synced after "change" instead of "input" -->
  <input v-model.lazy="msg" >
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>2.number: If you want user input to be automatically typecast as a number, you can add the number modifier to your v-model. Even with type='number', the value of HTML input elements always returns a string. So, this typecast modifier is required.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input v-model.number="age" type="number">  
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> 3. trim: If you want whitespace from user input to be trimmed automatically, you can add the trim modifier to your v-model. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input v-model.trim="msg">
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are components and give an example?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Components are reusable Vue instances with a name. They accept the same options as new Vue, such as data, computed, watch, methods, and lifecycle hooks(except few root-specific options like el).</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Lets take an example of counter component,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Define a new component called button-counter
  Vue.component('button-counter', {
    template: '<button v-on:click="count++">You clicked me {{ count }} times.</button>'
    data: function () {
      return {
        count: 0
      }
    },
  })
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Let's use this component inside a root Vue instance created with new Vue </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="app">
    <button-counter></button-counter>
  </div>

  var vm = new Vue({ el: '#app' });
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are props?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Props are custom attributes you can register on a component. When a value is passed to a prop attribute, it becomes a property on that component instance. You can pass those list of values as props option and use them as similar to data variables in template.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('todo-item', {
    props: ['title'],
    template: '<h2>{{ title }}</h2>'
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Once the props are registered, you can pass them as custom attributes.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <todo-item title="Learn Vue conceptsnfirst"></todo-item>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  When component needs a single root element?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In VueJS 2.x, every component must have a single root element when template has more than one element. In this case, you need to wrap the elements with a parent element.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <div class="todo-item">
    <h2>{{ title }}</h2>
    <div v-html="content"></div>
    </div>
  </template>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Otherwise there will an error throwing, saying that 'Component template should contain exactly one root element...'.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Whereas in 3.x, components now can have multiple root nodes. This way of adding multiple root nodes is called as fragments.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <h2>{{ title }}</h2>
    <div v-html="content"></div>
  </template>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you communicate from child to parent using events?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If you want child wants to communicate back up to the parent, then emit an event from child using $emit object to parent,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('todo-item', {
    props: ['todo'],
    template: '
      <div class="todo-item">
        <h3>{{ todo.title }}</h3>
        <button v-on:click="$emit('increment-count', 1)">
          Add
        </button>
        <div v-html="todo.description"></div>
      </div>
    '
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now you can use this todo-item in parent component to access the count value.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <ul v-for="todo in todos">
  <li>
    <todo-item
      v-bind:key="todo.id"
      v-bind:todo="todo"
      v-on:increment-count="total += 1"
    /></todo-item>
  </li>
  </ul>
  <span> Total todos count is {{total}}</span>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you implement model on custom input components?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The custom events can also be used to create custom inputs that work with v-model. The input inside the component must follow below rules,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Bind the value attribute to a value prop            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  On input, emit its own custom input event with the new value.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take a custom-input component as an example,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('custom-input', {
    props: ['value'],
    template: '
      <input
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
      />
    '
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Now you can use v-model with this component, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <custom-input v-model="searchInput"></custom-input>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are slots?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue implements a content distribution API using the element to serve as distribution outlets for content created after the current Web Components spec draft.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's create an alert component with slots for content insertion, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('alert', {
    template: '
      <div class="alert-box">
        <strong>Error!</strong>
        <slot></slot>
      </div>
    '
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now you can insert dynamic content as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <alert>
    There is an issue with in application.
  </alert>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is global registration in components?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The components which are globally registered can be used in the template of any root Vue instance (new Vue) created after registration.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> In the global registration, the components created using Vue.component as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('my-component-name', {
    // ... options ...
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take multiple components which are globally registered in the vue instance,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('component-a', { /* ... */ })
  Vue.component('component-b', { /* ... */ })
  Vue.component('component-c', { /* ... */ })

  new Vue({ el: '#app' })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> The above components can be used in the vue instance, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="app">
    <component-a></component-a>
    <component-b></component-b>
    <component-c></component-c>
  </div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Remember that the components can be used in subcomponents as well. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Why do you need local registration?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Due to global registration, even if you don't use the component it could still be included in your final build. So it will create unnecessary javascript in the application. This can be avoided using local registration with the below steps,</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> First you need to define your components as plain JavaScript objects </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  var ComponentA = { /* ... */ }
  var ComponentB = { /* ... */ }
  var ComponentC = { /* ... */ }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Locally registered components will not be available in sub components. In this case, you need to add them in components section  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  var ComponentA = { /* ... */ }

  var ComponentB = {
    components: {
    'component-a': ComponentA
    },
    // ...
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> You can use the components in the components section of the vue instance, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  new Vue({
    el: '#app',
    components: {
      'component-a': ComponentA,
      'component-b': ComponentB
    }
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the difference between local and global registration in module system?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In local registration, you need to create each component in components folder(optional but it is recommended) and import them in another component file components section.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's say you want to register component A and B in component C, the configuration seems as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import ComponentA from './ComponentA'
  import ComponentB from './ComponentB'

  export default {
    components: {
      ComponentA,
      ComponentB
    },
    // ...
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now both ComponentA and ComponentB can be used inside ComponentC‘s template.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> In global registration, you need to export all common or base components in a separate file. But some of the popular bundlers like webpack make this process simpler by using require.context to globally register base components in the below entry file(one-time). </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import Vue from 'vue'
  import upperFirst from 'lodash/upperFirst'
  import camelCase from 'lodash/camelCase'

  const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /Base[A-Z]\w+\.(vue|js)$/
  )

  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    const componentName = upperFirst(
      camelCase(
        // Strip the leading './' and extension from the filename
        fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
      )
    )

    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on '.default', which will
      // exist if the component was exported with 'export default',
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    )
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are possible prop types?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can declare props with type or without type. But it is recommended to have prop types because it provides the documentation for the component and warns the developer for any incorrect data type being assigned.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  props: {
    name: String,
    age: Number,
    isAuthenticated: Boolean,
    phoneNumbers: Array,
    address: Object
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>As mentioned in the above code snippet, you can list props as an object, where the properties’ names and values contain the prop names and types, respectively.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the data flow followed by props?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>All props follows a one-way-down binding between the child property and the parent one. i.e, When the parent property is updated then that latest prop value will be passed down to the child, but not the otherway(child to parent) around. The child component should not mutate the prop otherwise it throws a warning in the console. The possible mutation cases can be solved as below,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>When you try to use parent prop as initial value for child property:</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>In this case you can define a local property in child component and assign parent value as initial value</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  props: ['defaultUser'],
  data: function () {
    return {
      username: this.defaultUser
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>When you try to transform the parent prop:  </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>You can define a computed property using the prop’s value,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  props: ['environment'],
  computed: {
    localEnvironment: function () {
      return this.environment.trim().toUpperCase()
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are non prop attributes?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A non-prop attribute is an attribute that is passed to a component, but does not have a corresponding prop defined.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, If you are using a 3rd-party custom-input component that requires a data-tooltip attribute on the input then you can add this attribute to component instance, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <custom-input data-tooltip="Enter your input" />
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> If you try to pass the props from parent component the child props with the same names will be overridden. But props like class and style are exception to this, these values will be merged in the child component. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- Child component -->
  <input type="date" class="date-control">

  <!-- Parent component -->
  <custom-input class="custom-class" />
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Describe about validations available for props?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue provides validations such as types, required fields, default values along with customized validations. You can provide an object with validation requirements to the value of props as below,</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take an example of user profile Vue component with possible validations, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('user-profile', {
    props: {
      // Basic type check ('null' matches any type)
      age: Number,
      // Multiple possible types
      identityNumber: [String, Number],
      // Required string
      email: {
        type: String,
        required: true
      },
      // Number with a default value
      minBalance: {
        type: Number,
        default: 10000
      },
      // Object with a default value
      message: {
        type: Object,
        // Object or array defaults must be returned from
        // a factory function
        default: function () {
          return { message: 'Welcome to Vue' }
        }
      },
      // Custom validator function
      location: {
        validator: function (value) {
          // The value must match one of these strings
          return ['India', 'Singapore', 'Australia'].indexOf(value) !== -1
        }
      }
    }
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "How do you customize model directive for a component?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The v-model directive on a component uses value as the prop and input as the event, but some input types such as checkboxes and radio buttons may need to use the value attribute for a server side value. In this case, it is preferred to customize model directive.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take an example of checkbox component,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('custom-checkbox', {
    model: {
      prop: 'checked',
      event: 'change'
    },
    props: {
      checked: Boolean
    },
    template: '
      <input
        type="checkbox"
        v-bind:checked="checked"
        v-on:change="$emit('change', $event.target.checked)"
      >
    '
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>  Now you can use v-model on this customized component as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <custom-checkbox v-model="selectFramework"></custom-checkbox>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> The selectFramework property will be passed to the checked prop and same property will be updated when custom checkbox component emits a change event with a new value. </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the possible ways to provide transitions?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>There are many ways Vue provides transition effects when items are inserted, updated, or removed from the DOM.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Below are the possible ways,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Automatically apply classes for CSS transitions and animations</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Integrate 3rd-party CSS animation libraries. For example, Animate.css</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Use JavaScript to directly manipulate the DOM during transition hooks </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Integrate 3rd-party JavaScript animation libraries. For example, Velocity.js </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is vue router and their features?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue Router is a official routing library for single-page applications designed for use with the Vue.js framework.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Below are their features,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Nested route/view mapping  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Modular, component-based router configuration  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Route params, query, wildcards </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  View transition effects powered by Vue.js' transition system</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Fine-grained navigation control  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Links with automatic active CSS classes </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> HTML5 history mode or hash mode, with auto-fallback in IE9 </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Restore scroll position when going back in history mode </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the steps to use vue router and give an example?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>It is easy to integrate vue router in the vue application.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let us see the example with step by step instructions</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Step 1: Configure router link and router view in the template </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <script src="https://unpkg.com/vue/dist/vue.js"></script>
  <script src="https://unpkg.com/vue-router/dist/vue-router.js"></script>

  <div id="app">
    <h1>Welcome to Vue routing app!</h1>
    <p>
      <!-- use router-link component for navigation using 'to' prop. It rendered as an '<a>' tag -->
      <router-link to="/home">Home</router-link>
      <router-link to="/services">Services</router-link>
    </p>
    <!-- route outlet in which component matched by the route will render here -->
    <router-view></router-view>
  </div>
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Step 2: Import Vue and VueRouter packages and then apply router </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import Vue from 'vue';
  import VueRouter from 'vue-router';

  Vue.use(VueRouter)
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Step 3: Define or import route components. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const Home = { template: '<div>Home</div>' }
  const Services = { template: '<div>Services</div>' }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Step 4: Define your route where each one maps to a component </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const routes = [
    { path: '/home', component: Home },
    { path: '/services', component: Services }
  ]
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Step 5: Create the router instance and pass the routes option  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const router = new VueRouter({
    routes // short for 'routes: routes'
  })
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Step 6: Create and mount the root instance. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const app = new Vue({
    router
  }).$mount('#app')
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Now you are able to navigate different pages(Home, Services) with in Vue application. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is dynamic route matching?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Sometimes it may be required to map routes to the same component based on a pattern.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take a user component with the mapped URLs like /user/john/post/123 and /user/jack/post/235 using dynamic segments, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const User = {
    template: '<div>User {{ $route.params.name }}, PostId: {{ route.params.postid }}</div>'
  }
  
  const router = new VueRouter({
    routes: [
      // dynamic segments start with a colon
      { path: '/user/:name/post/:postid', component: User }
    ]
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How to make router param changes as reactive?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>When you navigate from one URL to other(mapped with a single component) using routes with params then the same component instance will be reused. Even though it is more efficient than destroying the old instance and then creating a new one, the lifecycle hooks of the component will not be called.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> This problem can be solved using either of the below approaches, </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Watch the $route object:  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const User = {
    template: '<div>User {{ $route.params.name }} </div>',
    watch: {
      '$route' (to, from) {
        // react to route changes...
      }
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Use beforeRouteUpdate navigation guard: This is only available since 2.2 version  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const User = {
    template: '<div>User {{ $route.params.name }} </div>',
    beforeRouteUpdate (to, from, next) {
      // react to route changes and then call next()
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note that the beforeRouteEnter guard does NOT have access to this. Instead you can pass a callback to next to access the vm instance.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is route matching priority?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Sometimes the URL might be matched by multiple routes and the confusion of which route need to be mapped is resolved by route matching priority. The priority is based on order of routes configuration. i.e, The route which declared first has higher priority.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const router = new VueRouter({
    routes: [
      // dynamic segments start with a colon
      { path: '/user/:name', component: User } // This route gets higher priority
      { path: '/user/:name', component: Admin }
      { path: '/user/:name', component: Customer }
    ]
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What are nested routes?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Generally, the app is composed of nested components which are nested multiple levels deep. The segments of a URL corresponds to a certain structure of these nested components. To render components into the nested outlet, you need to use the children option in VueRouter constructor config.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take a user app composed of profile and posts nested components with respective routes. You can also define a default route configuration when there is no matching nested route.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const router = new VueRouter({
    routes: [
      { path: '/user/:id', component: User,
        children: [
          {
            // UserProfile will be rendered inside User's <router-view> when /user/:id/profile is matched
            path: 'profile',
            component: UserProfile
          },
          {
            // UserPosts will be rendered inside User's <router-view> when /user/:id/posts is matched
            path: 'posts',
            component: UserPosts
          },
            // UserHome will be rendered inside User's <router-view> when /user/:id is matched
          {  path: '',
              component: UserHome },
        ]
      }
    ]
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are single file components?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Single File Components are an easy concept to understand. Earlier you might heard about all three parts(HTML, JavaScript and CSS) of your application kept in different components. But Single File Components encapsulate the structure, styling and behaviour into one file. In the beginning, it seems strange to have all three parts in one file, but it actually makes a lot more sense.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take an example of Singile File Components</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <div>
      <h1>Welcome {{ name }}!</h1>
    </div>
  </template>

  <script>
  module.exports = {
    data: function() {
      return {
        name: 'John'
      }
    }
  }
  </script>

  <style scoped>
  h1 {
    color: #34c779;
    padding: 3px;
  }
  </style>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Is Single File Components violating separation of concerns?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>As for the latest modern UI development, separation of concerns is not equal to separation of file types. So it is preferred to divide codebase layers into loosely-coupled components and compose them instead of dividing the codebase into three huge layers that interweave with one another. This way makes Single File Components more cohesive and maintainable by combining template, logic and styles together inside a component. You can also still maintain javascript and CSS files separately with hot-reloading and pre-compilation features.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <div>This section will be pre-compiled and hot reloaded</div>
  </template>
  <script src="./my-component.js"></script>
  <style src="./my-component.css"></style>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the problems solved by Single File Components?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Single File Components solve the common problems occurred in a javascript driven application with a .vue extension. The list of issues are,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Global definitions force unique names for every component</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>String templates lack syntax highlighting and require ugly slashes for multiline HTML</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>No CSS support means that while HTML and JavaScript are modularized into components, CSS is conspicuously left out</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>No build step restricts us to HTML and ES5 JavaScript, rather than preprocessors like Pug (formerly Jade) and Babel.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are filters?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Filters can be used to apply common text formatting. These Filters should be appended to the end of the JavaScript expression, denoted by the “pipe” symbol. You can use them in two specific cases:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> mustache interpolations </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>v-bind expressions  </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, Let's define a local filter named capitalize in a component’s options</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now you can use the filter in either mustache interpolation or v-bind expression,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- in mustaches -->
  {{ username | capitalize }}

  <!-- in v-bind -->
  <div v-bind:id="username | capitalize"></div>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the different ways to create filters?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can define filters in two ways,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Local filters: You can define local filters in a component’s options. In this case, filter is applicable to that specific component.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Global filters: You can also define a filter globally before creating the Vue instance. In this case, filter is applicable to all the components with in the vue instance, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  })
  
  new Vue({
    // ...
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you chain filters?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can chain filters one after the other to perform multiple manipulations on the expression. The generic structure of filter chain would be as below,</span>",
        },
       {
          htmlElement: "Syntax",
          value:`
  {{ message | filterA | filterB | filterB ... }}
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>In the above chain stack, you can observe that message expression applied with three filters, each separated by a pipe(|) symbol. The first filter(filterA) takes the expression as a single argument and the result of the expression becomes an argument for second filter(filterB) and the chain continue for remaining filters.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, if you want to transform date expression with a full date format and uppercase then you can apply dateFormat and uppercase filters as below,</span>",
        },
       {
          htmlElement: "Syntax",
          value:`
  {{ birthday | dateFormat | uppercase }}
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Is it possible to pass parameters for filters?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, you can pass arguments for a filter similar to a javascript function. The generic structure of filter parameters would be as follows,</span>",
        },
       {
          htmlElement: "Syntax",
          value:`
  {{ message | filterA('arg1', arg2) }}
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>In this case, filterA takes message expression as first argument and the explicit parameters mentioned in the filter as second and third arguments.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can find the exponential strength of a particular value</span>",
        },
       {
          htmlElement: "Syntax",
          value:`
  {{ 2 | exponentialStrength(10) }} <!-- prints 2 power 10 = 1024 -->
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What are plugins and their various services? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Plugins provides global-level functionality to Vue application. The plugins provide various services,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Add some global methods or properties. For example, vue-custom-element </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Add one or more global assets (directives, filters and transitions). For example, vue-touch </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Add some component options by global mixin. For example, vue-router </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Add some Vue instance methods by attaching them to Vue.prototype.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> A library that provides an API of its own, while at the same time injecting some combination of the above. For example, vue-router </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How to create a plugin?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Plugin is created by exposing an install method which takes Vue constructor as a first argument along with options. The structure of VueJS plugin with possible functionality would be as follows,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  MyPlugin.install = function (Vue, options) {
    // 1. add global method or property
    Vue.myGlobalMethod = function () {
      // some logic ...
    }
  
    // 2. add a global asset
    Vue.directive('my-directive', {
      bind (el, binding, vnode, oldVnode) {
        // some logic ...
      }
      // ...
    })
  
    // 3. inject some component options
    Vue.mixin({
      created: function () {
        // some logic ...
      }
      // ...
    })
  
    // 4. add an instance method
    Vue.prototype.$myMethod = function (methodOptions) {
      // some logic ...
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "How to use a plugin?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use plugin by passing your plugin to Vue's use global method. You need to apply this method before start your app by calling new Vue().</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // calls 'MyPlugin.install(Vue, { someOption: true })'
  Vue.use(MyPlugin)

  new Vue({
    //... options
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are mixins?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Mixin gives us a way to distribute reusable functionalities in Vue components. These reusable functions are merged with existing functions. A mixin object can contain any component options. Let us take an example of mixin with created lifecycle which can be shared across components,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const myMixin = {
    created(){
      console.log("Welcome to Mixins!")
    }
  }
  var app = new Vue({
    el: '#root',
    mixins: [myMixin]
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: Multiple mixins can be specified in the mixin array of the component</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are global mixins?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Sometimes there is a need to extend the functionality of Vue or apply an option to all Vue components available in our application. In this case, mixins can be applied globally to affect all components in Vue. These mixins are called as global mixins.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take an example of global mixin,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.mixin({
    created(){
      console.log("Write global mixins")
    }
  })
  
  new Vue({
    el: '#app'
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>In the above global mixin, the mixin options spread across all components with the console running during the instance creation. These are useful during test, and debugging or third party libraries. At the same time, You need to use these global mixins sparsely and carefully, because it affects every single Vue instance created, including third party components.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you use mixins in CLI?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Using Vue CLI, mixins can be specified anywhere in the project folder but preferably within /src/mixins for ease of access. Once these mixins are created in a .js file and exposed with the export keyword, they can be imported in any component with the import keyword and their file paths.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the merging strategies in mixins?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>When a mixin and the component itself contain overlapping options, the options will be merged based on some strategies.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The data objects undergo a recursive merge, with the component’s data taking priority over mixins in cases of overlapping or conflicts.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  var mixin = {
    data: function () {
      return {
        message: 'Hello, this is a Mixin'
      }
    }
  }
  new Vue({
    mixins: [mixin],
    data: function () {
      return {
        message: 'Hello, this is a Component'
      }
    },
    created: function () {
      console.log(this.$data); // => { message: "Hello, this is a Component'" }
    }
  })
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>The Hook functions which are overlapping merged into an array so that all of them will be called. Mixin hooks will be called before the component’s own hooks.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const myMixin = {
    created(){
      console.log("Called from Mixin")
    }
  }
  
  new Vue({
    el: '#root',
    mixins: [myMixin],
    created(){
      console.log("Called from Component")
    }
  })
  
  // Called from Mixin
  // Called from Component
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>The options that expect object values(such as methods, components and directives) will be merged into the same object. In this case, the component’s options will take priority when there are conflicting keys in these objects.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  var mixin = {
    methods: {
      firstName: function () {
        console.log('John')
      },
      contact: function () {
        console.log('+65 99898987')
      }
    }
  }
  
  var vm = new Vue({
    mixins: [mixin],
    methods: {
      lastName: function () {
        console.log('Murray')
      },
      contact: function () {
        console.log('+91 893839389')
      }
    }
  })
  
  vm.firstName() // "John"
  vm.lastName() // "Murray"
  vm.contact() // "+91 893839389"
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are custom options merging strategies?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue uses the default strategy which overwrites the existing value while custom options are merged. But if you want a custom option merged using custom login then you need to attach a function to Vue.config.optionMergeStrategies</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For the example, the structure of myOptions custom option would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.config.optionMergeStrategies.myOption = function (toVal, fromVal) {
    // return mergedVal
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take below Vuex 1.0 merging strategy as an advanced example,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const merge = Vue.config.optionMergeStrategies.computed

  Vue.config.optionMergeStrategies.vuex = function (toVal, fromVal) {
    if (!toVal) return fromVal
    if (!fromVal) return toVal
    return {
      getters: merge(toVal.getters, fromVal.getters),
      state: merge(toVal.state, fromVal.state),
      actions: merge(toVal.actions, fromVal.actions)
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are custom directives?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Custom Directives are tiny commands that you can attach to DOM elements. They are prefixed with v- to let the library know you're using a special bit of markup and to keep syntax consistent. They are typically useful if you need low-level access to an HTML element to control a bit of behavior.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's create a custom focus directive to provide focus on specific form element during page load time,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Register a global custom directive called 'v-focus'
  Vue.directive('focus', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
      // Focus the element
      el.focus()
    }
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now you can use v-focus directive on any element as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input v-focus>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you register directives locally?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can also register directives locally(apart from globally) using directives option in component as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  directives: {
    focus: {
      // directive definition
      inserted: function (el) {
        el.focus()
      }
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now you can use v-focus directive on any element as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input v-focus>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the hook functions provided by directives?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A directive object can provide several hook functions,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>bind: This occurs once the directive is attached to the element.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> inserted: This hook occurs once the element is inserted into the parent DOM. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> update: This hook is called when the element updates, but children haven't been updated yet. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> componentUpdated: This hook is called once the component and the children have been updated. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>unbind: This hook is called only once when the directive is removed.  </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: There are several arguments that can be passed to the above hooks.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you pass multiple values to a directive?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A directive can take any valid javascript expression. So if you want to pass multiple values then you can pass in a JavaScript object literal.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's pass object literal to an avatar directive as below</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div v-avatar="{ width: 500, height: 400, url: 'path/logo', text: 'Iron Man' }"></div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now let us configure avatar directive globally,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.directive('avatar', function (el, binding) {
    console.log(binding.value.width) // 500
    console.log(binding.value.height)  // 400
    console.log(binding.value.url) // path/logo
    console.log(binding.value.text)  // "Iron Man"
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is function shorthand in directive hooks?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In few cases, you may want the same behavior on bind and update hooks irrespective of other hooks. In this situation you can use function shorthand,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.directive('theme-switcher', function (el, binding) {
    el.style.backgroundColor = binding.value
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the benefit of render functions over templates?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In VueJS, the templates are very powerful and recommended to build HTML as part of your application. However, some of the special cases like dynamic component creation based on input or slot value can be achieved through render functions. Also, these functions gives the full programmatic power of javascript eco system.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What is a render function?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Render function is a normal function which receives a createElement method as it’s first argument used to create virtual nodes. Internally Vue.js' templates actually compile down to render functions at build time. Hence templates are just syntactic sugar of render functions.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take an example of simple Div markup and corresponding render function. The HTML markup can be written in template tag as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <div :class="{'is-rounded': isRounded}">
      <p>Welcome to Vue render functions</p>
    </div>
  </template>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>and the compiled down or explicit render function would appear as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  render: function (createElement) {
    return createElement('div', {
      'class': {
        'is-rounded': this.isRounded
        }
    }, [
      createElement('p', 'Welcome to Vue render functions')
    ]);
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: The react components are built with render functions in JSX.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Explain the structure of createElement with arguments?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The createElement accepts few arguments to use all the template features.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let us see the basic structure of createElement with possible arguments,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // @returns {VNode}
  createElement(
  // An HTML tag name, component options, or async function resolving to one of these.
  // Type is {String | Object | Function}
  // Required.
  'div',

  // A data object corresponding to the attributes you would use in a template.
  // Type is {Object}
  // Optional.
  {
    // Normal HTML attributes
    attrs: {
      id: 'someId'
    },
    // Component props
    props: {
      myProp: 'somePropValue'
    },
    // DOM properties
    domProps: {
      innerHTML: 'This is some text'
    },
    // Event handlers are nested under 'on'
    on: {
      click: this.clickHandler
    },
    // Similar to 'v-bind:style', accepting either a string, object, or array of objects.
    style: {
      color: 'red',
      fontSize: '14px'
    },
    // Similar to 'v-bind:class', accepting either a string, object, or array of strings and objects.
    class: {
      classsName1: true,
      classsName2: false
    }
    // ....
  },

  // Children VNodes, built using 'createElement()', or using strings to get 'text VNodes'.
  // Type is {String | Array}
  // Optional.
  [
    'Learn about createElement arguments.',
    createElement('h1', 'Headline as a child virtual node'),
    createElement(MyComponent, {
      props: {
        someProp: 'This is a prop value'
      }
    })
  ]
  )
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How can you write duplicate virtual nodes in a component?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>All virtual nodes(VNodes) in the component tree must be unique.i.e, You can't write duplicated nodes in a straightforward way. If you want to duplicate the same element/component many times then you should use factory function.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>The below render function is invalid where you are trying to duplicate h1 element 3 times,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  render: function (createElement) {
    var myHeadingVNode = createElement('h1', 'This is a Virtual Node')
    return createElement('div', [
      myHeadingVNode, myHeadingVNode, myHeadingVNode
    ])
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> You can make duplicates with factory function, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  render: function (createElement) {
    return createElement('div',
      Array.apply(null, { length: 3 }).map(function () {
        return createElement('h1', 'This is a Virtual Node')
      })
    )
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are functional components?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The functional components are just simple functions to create simple components just by passing a context. Every functional component follows two rules,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Stateless: It doesn’t keep any state by itself  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Instanceless: It has no instance, thus no this</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>You need to define functional: true to make it functional. Let's take an example of functional components</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('my-component', {
    functional: true,
    // Props are optional
    props: {
      // ...
    },
    // To compensate for the lack of an instance,
    // we are now provided a 2nd context argument.
    render: function (createElement, context) {
      // ...
    }
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: The functional components are quite popular in React community too.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the similarities between VueJS and ReactJS?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Even though ReactJS and VueJS are two different frameworks there are few similarities(apart from the common goal of utilized in interface design) between them.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Both frameworks are based on the Virtual DOM model</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>They provide features such Component-based structure and reactivity  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  They are intended for working with the root library, while all the additional tasks are transferred to other libraries(routing, state management etc).</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the advantages of VueJS over ReactJS?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue has the following advantages over React</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Vue is smaller and faster </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The convenient templates ease the process of developing  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> It has simpler javascript syntax without learning JSX </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the advantages of ReactJS over VueJS?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>React has the following advantages over Vue</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  ReactJS gives more flexibility in large apps developing</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Easy to test </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Well-suited for mobile apps creation </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> The eco system is quite big and well matured. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are dynamic components?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The dynamic component will allow you to dynamically switch beetween multiple components without updating the route of the application and even retaining the state of the component when switching back to the initial component. It works by using <component> tag with v-bind:is attribute which accept dynamic component.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>  Let's create a dynamic component vue instance to switch between different pages of a website,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  new Vue({
    el: '#app',
    data: {
      currentPage: 'home'
    },
    components: {
      home: {
        template: "<p>Home</p>"
      },
      about: {
        template: "<p>About</p>"
      },
      contact: {
        template: "<p>Contact</p>"
      }
    }
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now you can use the dynamic component which holds the current page,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="app">
  <component v-bind:is="currentPage">
      <!-- component changes when currentPage changes! -->
      <!-- output: Home -->
  </component>
  </div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>The component will be unmounted when it is switched away but it is possible to force the inactive component alive using <keep-alive> component  </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Note: The value of :is attribute can be either name of the registered component or the actual imported object itself. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of keep alive tag?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Keep-alive tag is an abstract component used to preserve component state or avoid re-rendering. When you wrapped tag around a dynamic component, it caches the inactive component instances without destroying them.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's see the example usage of it, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- Inactive components will be cached! -->
  <keep-alive>
    <component v-bind:is="currentTabComponent"></component>
  </keep-alive>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> When there are multiple conditional children, it requires that only one child is rendered at a time. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- multiple conditional children -->
  <keep-alive>
    <comp-a v-if="a > 1"></comp-a>
    <comp-b v-else></comp-b>
  </keep-alive>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>  Note: Remember that keep-alive tag doesn’t render a DOM element itself, and doesn’t show up in the component parent chain.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "What are async components?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In large applications, we may need to divide the app into smaller chunks and only load a component from the server when it’s needed. To make this happen, Vue allows you to define your component as a factory function that asynchronously resolves your component definition. These components are known as async component.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's see an example of async component using webpack code-splitting feature,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('async-webpack-example', function (resolve, reject) {
    // Webpack automatically split your built code into bundles which are loaded over Ajax requests.
    require(['./my-async-component'], resolve)
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Vue will only trigger the factory function when the component needs to be rendered and will cache the result for future re-renders. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the structure of async component factory?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Async component factory is useful to resolve the component asynchronously. The async component factory can return an object of the below format.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const AsyncComponent = () => ({
    // The component to load (should be a Promise)
    component: import('./MyComponent.vue'),
    // A component to use while the async component is loading
    loading: LoadingComponent,
    // A component to use if the load fails
    error: ErrorComponent,
    // Delay before showing the loading component. Default: 200ms.
    delay: 200,
    // The error component will be displayed if a timeout is
    // provided and exceeded. Default: Infinity.
    timeout: 3000
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are inline templates?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If you keep an inline-template on a child component then it will use its inner content as a template instead of treating as reusable independent content.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <my-component inline-template>
    <div>
    <h1>Inline templates</h1>
    <p>Treated as component component owne content</p>
    </div>
  </my-component>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: Even though this inline-templates gives more flexibility for template authoring, it is recommended to define template using template property or tag inside .vue component.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are X Templates?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Apart from regular templates and inline templates, you can also define templates using a script element with the type text/x-template and then referencing the template by an id.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's create a x-template for simple use case as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <script type="text/x-template" id="script-template">
    <p>Welcome to X-Template feature</p>
  </script>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Now you can define the template using reference id, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('x-template-example', {
    template: '#script-template'
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are recursive components?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Components that can recursively invoke themselves in their own template are known as recursive components.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('recursive-component', {
    template: '<!--Invoking myself!-->
                <recursive-component></recursive-component>'
  });
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Recursive components are useful for displaying comments on a blog, nested menus, or basically anything where the parent and child are the same, eventhough with different content.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: Remember that recursive component can lead infinite loops with max stack size exceeded error, so make sure recursive invocation is conditional(for example, v-if directive).</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How do you resolve circular dependencies between components?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In complex applications, vue components will actually be each other’s descendent and ancestor in the render tree.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's say componentA and componentB included in their respective templates which makes circular dependency,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          //ComponentA
  <div>
    <component-b >
  </div>
          `,
        },
        {
          htmlElement: "Syntax",
          value:`
          //ComponentB
  <div>
    <component-a >
  </div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>This can be solved by either registering(or wait until) the child component in beforeCreate hook or using webpack's asynchronous import while registering the component,  </span>",
        },
        {
          htmlElement: "Li",
          value:
  "<span>Solution1:            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  beforeCreate: function () {
    this.$options.components.componentB = require('./component-b.vue').default
    }
          `,
        },
        {
          htmlElement: "Li",
          value:
  "<span> Solution2:            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  components: {
    componentB: () => import('./component-b.vue')
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you make sure vue application is CSP complaint?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Some environments(Google Chrome Apps) prohibits the usage of new Function() for evaluating expressions and the full builds of vue applications depends on this feature to compile templates. Due to this reason, the full builds of VueJS application are not CSP complaint.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>In this case you can use runtime-only builds with Webpack + vue-loader or Browserify + vueify technology stack through which templates will be precompiled into render functions. This way you can make sure VueJS applications are 100% CSP complaint.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the difference between full and runtime only builds?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>There are two types of builds provided by VueJS,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>1. Full: These are the builds that contain both the compiler and the runtime.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>2. Runtime Only: These builds doesn't include compiler but the code is responsible for creating Vue instances, rendering and patching virtual DOM. These are about 6KB lighter min+gzip.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you configure vuejs in webpack?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can configure vueJS in webpack using alias as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  module.exports = {
    // ...
    resolve: {
      alias: {
        'vue$': 'vue/dist/vue.esm.js' // 'vue/dist/vue.common.js' for webpack 1
      }
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of vuejs compiler?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The compiler is is responsible for compiling template strings into JavaScript render functions.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, the below code snippet shows the difference of templates which need compiler and not,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // this requires the compiler
  new Vue({
    template: '<div>{{ message }}</div>'
  })

  // this does not
  new Vue({
    render (h) {
      return h('div', this.message)
    }
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is the browser support of VueJS?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>It supports all ECMAScript5 complaint browsers as mentioned in this url. VueJS doesn't support IE8 browser and below, because it uses ECMAScript 5 features that are un-shimmable(require support from the underlying JS engine) in IE8.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you use various CDNs?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>VueJS is available in jsdelivr, unpkg and cdnjs etc CDNs. Normally you can use them for prototyping or learning purposes.            </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can use them using jsdelivr with latest versions as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <script src="https://cdn.jsdelivr.net/npm/vue@2.6.7/dist/vue.js"></script>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can use it for native ES modules as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <script type="module">
    import Vue from 'https://cdn.jsdelivr.net/npm/vue@2.6.7/dist/vue.esm.browser.js'
  </script>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: You can remove version number to get latest version.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you force update?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>It is extremely rare situation of having to manually force an update despite the fact that no reactive data has changed. i.e, To force the Vue instance to re-render manually. You can do it force update using vm.$forceUpdate() API method.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: It does not affect all child components but only the instance itself and child components with inserted slot content.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of vuejs once directive?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If you want to render a lot of static content then you need to make sure it only evaluated once and then cached thereafter. In this case, you can use v-once directive by wrapping at the root level.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>The example usage of v-once directive would be as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.component('legal-terms', {
    template: '
      <div v-once>
        <h1>Legal Terms</h1>
        ... a lot of static content goes here...
      </div>
    '
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: It is recommended not to overuse unless there is slow rendering due to lot of static content.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How do you access the root instance?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The root instance(new Vue()) can be accessed with the $root property.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's see the usage of root instance with an example.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>First let's create a root instance with properties and methods as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // The root Vue instance
  new Vue({
    data: {
      age: 26
    },
    computed: {
      fullName: function () { /* ... */ }
    },
    methods: {
      interest: function () { /* ... */ }
    }
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Now you can access root instance data and it's methods with in subcomponents as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Get root data
  this.$root.age

  // Set root data
  this.$root.age = 29

  // Access root computed properties
  this.$root.fullName

  // Call root methods
  this.$root.interest()
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>It is recommend using Vuex to manage state instead of using root instance as a global store.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "List out top 10 organizations using Vuejs?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the top 10 organizations using VueJS for their applications or products,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Facebook - Used on marketing side of its Newsfeed </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Netflix - Used in two internal apps for building movie streaming interfaces  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Adobe - Used for Portfolio, a custom website builder designed to help users showcase their creative work </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Xiaomi - Used for products where it sells from consumer electronics to software  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Alibaba - Provide their apps an excellent experience to its customers </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> WizzAir - A budget airline WizzAir used for their customers user interface </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> EuroNews </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Laracasts </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>GitLab  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Laracasts </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of renderError?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>When the default render function encounters an error then you can use rennderError as an alternative render output. The error will be passed to renderError as the second argument.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> The example usage of renderError is as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  new Vue({
    render (h) {
      throw new Error('An error')
    },
    renderError (h, err) {
      return h('div', { style: { color: 'red' }}, err.stack)
    }
  }).$mount('#app')
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you access parent instance?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The $parent object refers to the immediate outer scope. The parent will be accessible as this.$parent for the child, and the child will be pushed into the parent’s $children array. It establishes a parent-child relationship between the two instances(parent and child). You can access parent data and properties similar to $root.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is vuex?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vuex is a state management pattern + library (Flux-inspired Application Architecture) for Vue.js applications. It serves as a centralized store for all the components in an application, with rules ensuring that the state can only be mutated in a predictable fashion.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the major components of State Management Pattern?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The state management has state, view and actions as major components. The pattern followed by these components in a application is known as State Management Pattern. Below are the components in a detail,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The state, which is the source of truth that drives our app</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The view, which is just a declarative mapping of the state</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The actions, which are the possible ways the state could change in reaction to user inputs from the view.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let us take a counter example which follows state management pattern with the above 3 components, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  new Vue({
    // state
    data () {
      return {
        count: 0
      }
    },
    // view
    template: '
      <div>{{ count }}</div>
    ',
    // actions
    methods: {
      increment () {
        this.count++
      }
    }
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is a vuejs loader?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue loader is a loader for webpack that allows you to author Vue components in a format called Single-File Components (SFCs).</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, it authors HelloWorld component in a SFC,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <div class="greeting">{{ message }}</div>
  </template>

  <script>
  export default {
    data () {
      return {
        message: 'Hello world for vueloader!'
      }
    }
  }
  </script>

  <style>
  .greeting {
    color: blue;
  }
  </style>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you configure vue loader in webpack?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue Loader's configuration is a bit different from other loaders by adding Vue Loader's plugin to your webpack config. The vue loader plugin is required for cloning any other rules(js and css rules) defined and applying them to the corresponding language blocks(<script> and <style>) in .vue files.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>  For example, the simple demonistration of webpack configuration for vue loader would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // webpack.config.js
  const VueLoaderPlugin = require('vue-loader/lib/plugin')

  module.exports = {
    mode: 'development',
    module: {
      rules: [
        {
          test: /\.vue$/,
          loader: 'vue-loader'
        },
        // this will apply to both plain '.js' files and '<script>' blocks in '.vue' files
        {
          test: /\.js$/,
          loader: 'babel-loader'
        },
        // this will apply to both plain '.css' files and '<style>' blocks in '.vue' files
        {
          test: /\.css$/,
          use: [
            'vue-style-loader',
            'css-loader'
          ]
        }
      ]
    },
    plugins: [
      // make sure to include the plugin for cloning and mapping them to respective language blocks
      new VueLoaderPlugin()
    ]
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are asset url transform rules?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of Asset URL transform rules</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Absolute path: If the URL is an absolute path (for example, /images/loader.png)then it will be preserved as-is. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Relative path: If the URL starts with . (for example, ./images/loader.png) then it will be interpreted as a relative module request and resolved based on the folder structure on your file system.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>URLs starts with ~ symbol: If the URL starts with ~ symbol(for example, ./some-node-package/loader.png) then it is interpreted as a module request. This way it can reference assets inside node modules too.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>URLs starts with @ symbol: If the URL starts with @ symbol then it is interpreted as a module request. This is useful if your webpack config has an alias for @, which by default points to /src path.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you work with preprocessors using vue loader?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue-loader will automatically infer the proper loaders to use based on the lang attribute of a language block and the rules defined in webpack config. You can use pre-processors such as SASS,LESS, Stylus and PostCSS using vuejs loader.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is scoped CSS?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Scoped CSS is a mechanism in VueJS Single File Components(SFC) that prevents styles from leaking out of the current component and affecting other unintended components on your page. i.e, When a <style> tag has the scoped attribute, its CSS will apply to elements of the current component only. It uses PostCSS to transform scoped css to plain CSS.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take an example usage of scoped css, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <style scoped>
  .greeting {
  color: green;
  }
  </style>

  <template>
  <div class="greeting">Let's start Scoped CSS</div>
  </template>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> The above code will be converted to plain CSS, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <style scoped>
  .greeting[data-v-f3f3eg9] {
    color: green;
  }
  </style>
  
  <template>
    <div class="greeting" data-v-f3f3eg9>Let's start Scoped CSS</div>
  </template>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Is it possible to mix both local and global styles?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, you can include both scoped and non-scoped styles in the same component. If you don't mention scoped attribute then it will become global style.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <style>
  /* global styles */
  </style>

  <style scoped>
  /* local styles */
  </style>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you use deep selectors?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In scoped css, if you need to modify the styles of a child component using deep selectors(i,e from parent scoped css) then you need to use >>> combinator.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, the scoped deep selector on parent scoped css would be as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <style scoped>
  .class1 >>> .class2 { /* ... */ }
  </style>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> It will be converted as, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  .class1[data-v-f3f3eg9] .class2 { /* ... */ }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: If you preprocessors such as SASS then it may not be able to processs >>> properly. In such cases use the /deep/ or ::v-deep combinator instead >>> combinator.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Is parent styles leaked into child components in scoped css?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The parent component's styles will not leak into child components. But a child component's root node will be affected by both the parent's scoped CSS and the child's scoped CSS. i.e, your child component's root element has a class that also exists in the parent component, the parent component's styles will leak to the child. Anyway this is by design so that the parent can style the child root element for layout purposes.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, the background color property of parent component leaked into child component as below,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> //parent.vue </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <div class="wrapper">
      <p>parent</p>
      <ChildMessageComponent/>
    </div>
  </template>

  <script>
  import ChildMessageComponent from "./components/child";

  export default {
    name: "App",
    components: {
      ChildMessageComponent
    }
  };
  </script>
  <style scoped>
  .wrapper {
    background: blue;
  }
  </style>
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> //child.vue </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <div class="wrapper">
      <p>child</p>
    </div>
  </template>

  <script>
  export default {
    name: "Hello, Scoped CSS",
  };
  </script>
  <style scoped>
  .wrapper {
    background: red;
  }
  </style>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you style dynamic generated content using scoped css?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The scoped css style doesn't impact v-html directive's dynamically generated content. In this case, you can use deep selectors to solve this styling issue.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  Is CSS modules supported in Vuejs?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, vue-loader provides first-class integration with CSS Modules as an alternative for simulated scoped CSS.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Can I use runtime builds for all templates?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, templates (or any Vue-specific HTML) are ONLY allowed in .vue files and render functions are required in other cases.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "How to use CSS modules in vuejs?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the steps to use css modules in VueJS,            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Enable CSS modules: CSS Modules must be enabled by passing modules: true option to css-loader </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // webpack.config.js
  {
    module: {
      rules: [
        // ... other rules omitted
        {
          test: /\.css$/,
          use: [
            'vue-style-loader',
            {
              loader: 'css-loader',
              options: {
                // enable CSS Modules
                modules: true,
                // customize generated class names
                localIdentName: '[local]_[hash:base64:8]'
              }
            }
          ]
        }
      ]
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Add module attribute: Add the module attribute to your <style> </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <style module>
  .customStyle {
    background: blue;
  }
  </style>
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Inject CSS modules: You can inject CSS modules object with computed property $style  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
    <div :class="$style.blue">
      Background color should be in blue
    </p>
  </template>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Can I use CSS modules for preprocessors?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, you can use preprocessors with CSS Modules.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, sass-loader can configured in webpack file for sass preprocessor. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // webpack.config.js -> module.rules
  {
    test: /\.scss$/,
    use: [
      'vue-style-loader',
      {
        loader: 'css-loader',
        options: { modules: true }
      },
      'sass-loader'
    ]
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Is it possible to use custom inject name for CSS modules?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can customize the name of the injected computed property by giving the module attribute a value. This will be helpful to avoid overwriting injected styled if you have more than one <style> tags in a single *.vue component.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can use module attribute as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <style module="a">
    /* identifiers injected as a */
  </style>

  <style module="b">
    /* identifiers injected as b */
  </style>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is hot reloading in vue loader?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Hot reloading is not about reloading the page when you edit any .vue file. Instead, when you edit a *.vue file, all instances of that component will be swapped in without reloading the page. It improves the development experience when you are tweaking the templates or styling of your components.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What is the default behavior of hot reloading?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Hot Reload is always enabled except below situations:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> webpack target is node (SSR) </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  webpack minifies the code</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> process.env.NODE_ENV === 'production' </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you disable hot reloading explicitly?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use hotReload: false option to disable the Hot Reload explicitly.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> It can be configured as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  module: {
    rules: [
      {
        test: /\.vue$/,
        loader: 'vue-loader',
        options: {
          hotReload: false // disables Hot Reload
        }
      }
    ]
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you use hot reloading?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The vue loader plugin internally uses hot reloading. If you are scaffolding project with vue-cli, hot reloading comes out of the box but if you are manually setting up the project then hot reloading is enabled by default with webpack-dev-server --hot command.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are state preservation rules in hot reloading?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the state preservation rules in hot reloading,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  When editing the <template> of a component, instances of the edited component will re-render in place, preserving all current private state.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>When editing the <script> part of a component, instances of the edited component will be destroyed and re-created in place.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> When editing the <style> hot reload operates on its own via vue-style-loader without affecting application state. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How to create functional components using vue loader?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can create functional components by adding functional attribute to template block,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template functional>
    <div>{{ props.msg }}</div>
  </template>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "How do you access global properties of functional components?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If you need to access properties defined globally on Vue.prototype then you can access them on parent,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template functional>
    <div>{{ parent.$someProperty }}</div>
  </template>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you perform testing in vuejs?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can perform testing in two ways,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Using vue-cli: It offers pre-configured unit testing and e2e testing setups </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Manual setup: You can manually setting up unit tests for *.vue files using either mocha-webpack or jest </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How do you apply linting for css?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The stylelint linter supports linting style parts of Vue single file components. You can run linter on particular vue file as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  stylelint MyComponent.vue
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Other option is configuring stylelint-webpack-plugin in webpack. It can be configured as a dev dependency.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // webpack.config.js
  const StyleLintPlugin = require('stylelint-webpack-plugin');
  module.exports = {
    // ... other options
    plugins: [
      new StyleLintPlugin({
        files: ['**/*.{vue,htm,html,css,sss,less,scss,sass}'],
      })
    ]
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   How do you use eslint plugin? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The official eslint-plugin-vue supports linting both the template and script parts of Vue single file components. You can configure plugin in your ESLint config,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // .eslintrc.js
  module.exports = {
    extends: [
      "plugin:vue/essential"
    ]
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can run linter on particular component as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  eslint --ext js,vue MyComponent.vue
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is the purpose of eslint loader? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use eslint-loader for *.vue files in order to automatically linted on save during development. It can be installed as npm module,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install -D eslint eslint-loader
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>After that you need to add it as pre-loader,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // webpack.config.js
  module.exports = {
    // ... other options
    module: {
      rules: [
        {
          enforce: 'pre',
          test: /\.(js|vue)$/,
          loader: 'eslint-loader',
          exclude: /node_modules/
        }
      ]
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is CSS extraction? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>CSS Extraction is used to extract all the processed CSS in all Vue components into a single CSS file. For webpack4, you need to install below npm command,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install -D mini-css-extract-plugin
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can configure this plugin in webpack as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // webpack.config.js
  var MiniCssExtractPlugin = require('mini-css-extract-plugin')

  module.exports = {
    // other options...
    module: {
      rules: [
        // ... other rules omitted
        {
          test: /\.css$/,
          use: [
            process.env.NODE_ENV !== 'production'
              ? 'vue-style-loader'
              : MiniCssExtractPlugin.loader,
            'css-loader'
          ]
        }
      ]
    },
    plugins: [
      // ... Vue Loader plugin omitted
      new MiniCssExtractPlugin({
        filename: 'style.css'
      })
    ]
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are custom blocks?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can define custom language blocks inside *.vue files based on the lang attribute of the block, the block's tag name, and the rules in your webpack config. You can also use resourceQuery to match a rule against a custom block with no lang.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, to match against <message> custom blocks. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    module: {
      rules: [
        {
          resourceQuery: /blockType=message/,
          loader: 'loader-to-use'
        }
      ]
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the features of stylelint?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of major stylelint features</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  It has more than 160 built-in rules to catch errors, apply limits and enforce stylistic conventions</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Understands latest CSS syntax including custom properties and level 4 selectors </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> It extracts embedded styles from HTML, markdown and CSS-in-JS object & template literals </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Parses CSS-like syntaxes like SCSS, Sass, Less and SugarSS </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Supports Plugins for reusing community plugins and creating own plugins</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Does Vuex support hot reloading?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, vuex supports hot-reloading for mutations, modules, actions and getters during development. You need to use either webpack's hot module replacement API or browserify's hot module replacement plugin.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of hotUpdate API of vuex store?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The store.hotUpdate() API method is used for mutations and modules.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, you need to configure vuex store as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // store.js
  import Vue from 'vue'
  import Vuex from 'vuex'
  import mutations from './mutations'
  import myModule from './modules/myModule'

  Vue.use(Vuex)

  const state = { message: "Welcome to hot reloading" }

  const store = new Vuex.Store({
    state,
    mutations,
    modules: {
      moduleA: myModule
    }
  })

  if (module.hot) {
    // accept actions and mutations as hot modules
    module.hot.accept(['./mutations', './modules/newMyModule'], () => {
      // Get the updated modules
      const newMutations = require('./mutations').default
      const newMyModule = require('./modules/myModule').default
      //swap in the new modules and mutations
      store.hotUpdate({
        mutations: newMutations,
        modules: {
          moduleA: newMyModule
        }
      })
    })
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you test mutations?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Since mutations are just functions that completely rely on their arguments it will be easier to test. You need to keep mutations inside your store.js file and should also export the mutations as a named export apart from default export.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take an example of increment mutations, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // mutations.js
  export const mutations = {
    increment: state => state.counter++
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> And test them using mocha and chai as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // mutations.spec.js
  import { expect } from 'chai'
  import { mutations } from './store'

  // destructure assign 'mutations'
  const { increment } = mutations

  describe('mutations', () => {
    it('INCREMENT', () => {
      // mock state
      const state = { counter: 10 }
      // apply mutation
      increment(state)
      // assert result
      expect(state.counter).to.equal(11)
    })
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you test your getters?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>It is easier to test getters similar to mutations. It is recommended to test these getters if they have complicated computation.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take a simple todo filter as a getter  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // getters.js
  export const getters = {
    filterTodos (state, status) {
      return state.todos.filter(todo => {
        return todo.status === status
      })
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> And the test case for above getter as follows, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // getters.spec.js
  import { expect } from 'chai'
  import { getters } from './getters'

  describe('getters', () => {
    it('filteredTodos', () => {
      // mock state
      const state = {
        todos: [
          { id: 1, title: 'design', status: 'Completed' },
          { id: 2, title: 'testing', status: 'InProgress' },
          { id: 3, title: 'development', status: 'Completed' }
        ]
      }
      // mock getter
      const filterStatus = 'Completed'

      // get the result from the getter
      const result = getters.filterTodos(state, filterStatus)

      // assert the result
      expect(result).to.deep.equal([
        { id: 1, title: 'design', status: 'Completed' },
        { id: 2, title: 'development', status: 'Completed' }
      ])
    })
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the procedure to run tests in node?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>By proper mocking, you can bundle tests with webpack and run them on node without having depenceny on Browser API. It involves 2 steps,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Create webpack config: Create webpack config with proper .babelrc</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // webpack.config.js
  module.exports = {
    entry: './test.js',
    output: {
      path: __dirname,
      filename: 'test-bundle.js'
    },
    module: {
      loaders: [
        {
          test: /\.js$/,
          loader: 'babel-loader',
          exclude: /node_modules/
        }
      ]
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Run testcases: First you need to bundle and then run them using mocha as below  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  webpack
  mocha test-bundle.js
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the procedure to run tests in browser?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the steps to run tests in real browser,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Install mocha-loader. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Configure webpack config entry point to 'mocha-loader!babel-loader!./test.js'. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Start webpack-dev-server using the config. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Go to localhost:8080/webpack-dev-server/test-bundle to see the test result  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of strict mode in vuex?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In strict mode, whenever Vuex state is mutated outside of mutation handlers, an error will be thrown. It make sure that all state mutations can be explicitly tracked by debugging tools. You can just enable this by passing strict: true while creating the vuex store.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const store = new Vuex.Store({
    // ...
    strict: true
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Can I use strict mode in production environment?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, it is not recommended to use strict mode in production environment. Strict mode runs a synchronous deep watcher on the state tree for detecting inappropriate mutations and it can be quite expensive when you perform large amount of mutations. i.e, It can impact performance if you enable in production mode. Hence it should be handled through build tools,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const store = new Vuex.Store({
    // ...
    strict: process.env.NODE_ENV !== 'production'
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is vuex plugin?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The vuex plugin is an option hat exposes hooks for each mutation. It is a normal function that receives the store as the only argument. You can create your own plugin or use built-in plugins. The plugin skeleton would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const myPlugin = store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
      // called after every mutation.
      // The mutation comes in the format of '{ type, payload }'.
    })
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>After that plugin can be configured for plugins options as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const store = new Vuex.Store({
    // ...
    plugins: [myPlugin]
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "How do you mutate state in plugins?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Similar to components you can't mutate state directly but they can trigger changes by by committing mutations. This way a plugin can be used to sync a data source to the store.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, createWebSocketPlugin plugin is used to sync a websocket data source to the store.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  export default function createWebSocketPlugin (socket) {
    return store => {
      socket.on('data', data => {
        store.commit('receiveData', data)
      })
      store.subscribe(mutation => {
        if (mutation.type === 'UPDATE_DATA') {
          socket.emit('update', mutation.payload)
        }
      })
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>And then configure plugin in vuex store as below</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const plugin = createWebSocketPlugin(socket)

  const store = new Vuex.Store({
    state,
    mutations,
    plugins: [plugin]
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is vuex store?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A Vuex 'store' is basically a container that holds your application state. The store creation is pretty straightforward.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of instructions to use vuex in an increment application,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Configure vuex in vuejs ecosystem </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import Vuex from "vuex";
  Vue.use(Vuex)
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Provide an initial state object and some mutations </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Make sure to call Vue.use(Vuex) first if using a module system

  const store = new Vuex.Store({
    state: {
      count: 0
    },
    mutations: {
      increment (state) {
        state.count++
      }
    }
  })
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Trigger state change with commit and access state variables  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  store.commit('increment')

  console.log(store.state.count) // -> 1
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What are the differences of vuex store and plain global object?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the two major differences between vuex store and plain global object,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Vuex stores are reactive: If the store's state changes then vue components will reactively and efficiently get updated </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Cannot directly mutate the store's state: The store's state is changed by explicitly committing mutations to ensure that every state change leaves a track-able record for tooling purpose  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the reason not to update the state directly?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>We want to explicitly track application state in order to implement tools that can log every mutation, take state snapshots, or even perform time travel debugging. So we need to commit a mutation instead of changing store's state directly.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is Single state tree?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vuex's single state tree is single object contains all your application level state and serves as the 'single source of truth'. It does not conflict with modularity when you split state and mutations into sub modules.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you install vuex?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can install vuex using npm or yarn as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install vuex --save
  (or)
  yarn add vuex
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>In a module system, you must explicitly install Vuex via Vue.use()</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import Vue from 'vue'
  import Vuex from 'vuex'

  Vue.use(Vuex)
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Do I need promise for vuex?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, vuex requires Promise. If your supporting browsers do not implement Promise (e.g. IE), you can use a polyfill library, such as es6-promise using npm or yarn.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install es6-promise --save # NPM
  yarn add es6-promise # Yarn
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>After that import into anywhere in your application,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import 'es6-promise/auto'
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you display store state in vue components?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Since Vuex stores are reactive, you can retrieve' state from store by simply returning store's state from within a computed property. i.e, Whenever store state changes, it will cause the computed property to re-evaluate, and trigger associated DOM updates.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take a hello word component which display store's state in the template,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // let's create a hello world component
  const Greeting = {
    template: '<div>{{ greet }}</div>',
    computed: {
      greet () {
        return store.state.msg
      }
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you inject store into child components?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vuex provides a mechanism to 'inject' the store into all child components from the root component with the store option. It will be enabled by vue.use(vuex).</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, let's inject into our app component as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const app = new Vue({
    el: '#app',
    // provide the store using the "store" option.
    // this will inject the store instance to all child components.
    store,
    components: { Greeting },
    template: '
      <div class="app">
        <greeting></greeting>
      </div>
    '
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Now the store will be injected into all child components of the root and will be available on them as this.$store </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // let's create a hello world component
  const Greeting = {
    template: '<div>{{ greet }}</div>',
    computed: {
      greet () {
        return this.$store.state.msg
      }
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is mapState helper?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Vuex application, creating a computed property every time whenever we want to access the store's state property or getter is going to be repetitive and verbose, especially if a component needs more than one state property. In this case, we can make use of the mapState helper of vuex which generates computed getter functions for us.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take an increment example to demonstrate mapState helper, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // in full builds helpers are exposed as Vuex.mapState
  import { mapState } from 'vuex'

  export default {
    // ...
    computed: mapState({
      // arrow functions can make the code very succinct!
      username: state => state.username,

      // passing the string value 'username' is same as 'state => state.username'
      usernameAlias: 'username',

      // to access local state with 'this', a normal function must be used
      greeting (state) {
        return this.localTitle + state.username
      }
    })
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> We can also pass a string array to mapState when the name of a mapped computed property is the same as a state sub tree name </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  computed: mapState([
    // map this.username to store.state.username
    'username'
  ])
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you combine local computed properties with mapState helper?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use object spread operator syntax in order to combine mapState helper(which returns an object) with other local computed properties. This way it simplify merging techniques using utilities.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  computed: {
    localComputed () { /* ... */ },
    // mix this into the outer object with the object spread operator
    ...mapState({
      // ...
    })
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Do you need to replace entire local state with vuex?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, if a piece of state strictly belongs to a single component, it could be just fine leaving it as local state. i.e, Even though vuex used in the application, it doesn't mean that you need to keep all the local state in vuex store. Other than that the code becomes more verbose and indirect although it makes your state mutations more explicit and debuggable.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are vuex getters??  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vuex getters acts as computed properties for stores to compute derived state based on store state. Similar to computed properties, a getter's result is cached based on its dependencies, and will only re-evaluate when some of its dependencies have changed.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take a todo example which as completedTodos getter to find all completed todos, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const store = new Vuex.Store({
    state: {
      todos: [
        { id: 1, text: 'Vue course', completed: true },
        { id: 2, text: 'Vuex course', completed: false },
        { id: 2, text: 'Vue Router course', completed: true }
      ]
    },
    getters: {
      completedTodos: state => {
        return state.todos.filter(todo => todo.completed)
      }
    }
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is a property style access?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can access values of store's getter object(store.getters) as properties. This is known as property style access.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can access todo's status as a property,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  store.getters.todosStatus
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> The getters can be passed as 2nd argument for other getters. For example, you can derive completed todo's count based on their status as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  getters: {
    completedTodosCount: (state, getters) => {
      return getters.todosStatus === 'completed'
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is a method style access?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can access store's state in a method style by passing arguments.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, you can pass user id to find user profile information as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  getters: {
    getUserProfileById: (state) => (id) => {
      return state.users.find(user => user.id === id)
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> After that you can access it as a method call, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  store.getters.getUserProfileById(111); {id: '111', name: 'John', age: 33}
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is mapGetter helper??  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The mapGetters is a helper that simply maps store getters to local computed properties.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>  For example, the usage of getters for todo app would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      // mix the getters into computed with object spread operator
      ...mapGetters([
        'completedTodos',
        'todosCount',
        // ...
      ])
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What are mutations? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vuex mutations are similar to any events with a string type and a handler. The handler function is where we perform actual state modifications, and it will receive the state as the first argument.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, the counter example with increment mutation would be as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const store = new Vuex.Store({
    state: {
      count: 0
    },
    mutations: {
      increment (state) {
        // mutate state
        state.count++
      }
    }
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> You can't directly invoke mutation instead you need to call store.commit with its type. The above mutation would be triggered as folows </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  store.commit('increment')
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you commit with payload?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can also pass payload for the mutation as an additional argument to store.commit.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, the counter mutation with payload object would be as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  mutations: {
    increment (state, payload) {
      state.count += payload.increment
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>And then you can trigger increment commit  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  store.commit('increment', {
    increment: 20
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is object style commit?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can also commit a mutation is by directly using an object that has a type property.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  store.commit({
    type: 'increment',
    value: 20
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>  Now the entire object will be passed as the payload to mutation handlers(i.e, without any changes to handler signature).</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  mutations: {
    increment (state, payload) {
      state.count += payload.value
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the caveats with vuex mutations?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Since a Vuex store's state is made reactive by Vue, the same reactivity caveats of vue will apply to vuex mutations. These are the rules should be followed for vuex mutations,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> It is recommended to initialize store's initial state with all desired fields upfront </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Add new properties to state Object either by set method or object spread syntax  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Vue.set(stateObject, 'newProperty', 'John')
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Why mutations should be synchronous?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You need to remember that mutation handler functions must be synchronous. This is why because any state mutation performed in the callback is essentially un-trackable. It is going to be problematic when the devtool will need to capture a 'before' and 'after' snapshots of the state during the mutations.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  mutations: {
    someMutation (state) {
      api.callAsyncMethod(() => {
        state.count++
      })
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you perform mutations in components?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can commit mutations in components with either this.$store.commit('mutation name') or mapMutations helper to map component methods to store.commit calls.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, the usage of mapMutations helper on counter example would be as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { mapMutations } from 'vuex'

  export default {
    methods: {
      ...mapMutations([
        'increment', // map 'this.increment()' to 'this.$store.commit('increment')

        // mapMutations also supports payloads:
        'incrementBy' // map 'this.incrementBy(amount)'' to 'this.$store.commit('incrementBy', amount)'
      ]),
      ...mapMutations({
        add: 'increment' // map 'this.add()' to 'this.$store.commit('increment')'
      })
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Is it mandatory to use constants for mutation types?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, it is not mandatory. But you might observed that State management implementations such Flux and Redux use constants for mutation types. This convention is just a preference and useful to take advantage of tooling like linters, and putting all constants in a single file allows your collaborators to get an at-a-glance view of what mutations are possible in the entire application.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, the mutations can be declared as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // mutation-types.js
  export const SOME_MUTATION = 'SOME_MUTATION'
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> And you can configure them in store as follows, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // store.js
  import Vuex from 'vuex'
  import { SOME_MUTATION } from './mutation-types'

  const store = new Vuex.Store({
    state: { ... },
    mutations: {
      // ES2015 computed property name feature to use a constant as the function name
      [SOME_MUTATION] (state) {
        // mutate state
      }
    }
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you perform asynchronous operations?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Vuex, mutations are synchronous transactions. But if you want to handle asynchronous operations then you should use actions.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are differences between mutations and actions?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Actions are similar to mutations, but there are two main differences,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Mutations perform mutations on the state, actions commit mutations.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Actions can contain arbitrary asynchronous operations unlike mutations.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  Give an example usage of actions?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vuex provides actions property similar mutations property in order to define action handlers. These action handlers receive context object as an argument which has same properties and methods of store instance.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>  Let's see counter example to demonstrate increment action which commits respective mutation,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const store = new Vuex.Store({
    state: {
      count: 0
    },
    mutations: {
      increment (state) {
        state.count++
      }
    },
    actions: {
      increment (context) {
        context.commit('increment')
      }
    }
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you dispatch actions?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Actions are simply triggered with the store.dispatch method as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  store.dispatch('increment')
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Can you dispatch an action using payload or object style?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, actions support both payload and object style format similar to mutations</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // dispatch with a payload
  store.dispatch('incrementAsync', {
    amount: 10
  })

  // dispatch with an object
  store.dispatch({
    type: 'incrementAsync',
    amount: 10
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Can I use Styled Components in VueJS?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Styled components is a CSS-in-JS library used mainly for ReactJS applications. If you want to use it for VueJS applications, there is a VueJS styled components library port available, however, it is not a common practice.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you dispatch actions in components?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can dispatch actions in components with this.$store.dispatch('action name'), or use the mapActions helper which maps component methods to store.dispatch calls.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, you can dispatch increment actions in counter component as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { mapActions } from 'vuex'

  export default {
    // ...
    methods: {
      ...mapActions([
        'increment', // map 'this.increment()' to 'this.$store.dispatch('increment')'

        // 'mapActions' also supports payloads:
        'incrementBy' // map 'this.incrementBy(amount)' to 'this.$store.dispatch('incrementBy', amount)'
      ]),
      ...mapActions({
        add: 'increment' // map 'this.add()' to 'this.$store.dispatch('increment')'
      })
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you compose actions?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can write multiple actions together to handle more complex async flows either by chaining promises or async/await. i.e, store.dispatch can handle Promise returned by the triggered action handler and it also returns Promise.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>  Let's take two actions to see how they are combined and handled async flows,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  actions: {
    actionOne ({ commit }) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          commit('first mutation')
          resolve()
        }, 1000)
      })
    },
    actionTwo ({ dispatch, commit }) {
      return dispatch('actionA').then(() => {
        commit('second mutation')
      })
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> As per the above example, When you try to dispatch actionTwo it dispatchs actionOne first and then commits respective mutation. You can still simplify with async/await as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  actions: {
    async actionOne ({ commit }) {
      commit('first mutation', await getDataAsPromise())
    },
    async actionTwo ({ dispatch, commit }) {
      await dispatch('actionOne') // wait for 'actionA' to finish
      commit('second mutation', await getSomeDataAsPromise())
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are modules in vuex?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If you keep all state of our application in a single big state, the store can get really bloated. To solve this problem, Vuex allows us to divide our store into modules. Here, each module can contain its own state, mutations, actions, getters, and even nested modules.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take an example with multiple modules, configuring them in vuex and accessing different modules, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const moduleOne = {
    state: { ... },
    mutations: { ... },
    actions: { ... },
    getters: { ... }
  }
  
  const moduleTwo = {
    state: { ... },
    mutations: { ... },
    actions: { ... },
    getters: { ... }
  }
  
  const store = new Vuex.Store({
    modules: {
      one: moduleOne,
      two: moduleTwo
    }
  })
  
  store.state.one // -> 'moduleOne's state
  store.state.two // -> 'moduleTwo's state
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is module local state?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>When you use modules the local state will be available to mutations, getters and actions in different ways.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Both mutations and getters will receive module local state as first argument  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const moduleOne = {
    state: { count: 0 },
    mutations: {
      increment (state) {
        state.count++; // Here state refers local module state
      }
    },
  
    getters: {
      average (state) {
        return state.count / 2
      }
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>In actions, local state will be available as first argument  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const moduleOne = {
    actions: {
      incrementConditional ({ state, commit, rootState }) {
        if (state.count < rootState.count) {
          commit('increment')
        }
      }
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the default namespace behavior in vuex?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>By default, actions, mutations and getters inside modules are still registered under the global namespace. Because of that multiple modules react to the same mutation/action type.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " When do you reuse modules?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Sometime you may need to create multiple instances of a module.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, it is needed in the below cases,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>If multiple stores that use the same module</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Register the same module multiple times in the same store.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>In those cases, you need to assign to a variable and export it for reusability, javascript const MyReusableModule = { // state // mutations, actions, getters... } </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What are the principles enforced by vuex?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vuex enforces below high-level principles,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The Application-level state need to be centralized in the store</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The state should be mutated by committing mutations only(i.e, for synchronous transactions)            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The actions should be used for asynchronous transactions. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Can I perform mutations directly in strict mode?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In strict mode, you can't mutate state directly using v-model attribute. If you use v-model it throws an error because mutation is not performed inside an explicit Vuex mutation handler.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, the below input throws an error due to v-model usage </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input v-model="stateObject.message">
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>In this case, you need to bind the 's value. It can be resolved using value attribute as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input :value="username" @input="updateProfile">

  computed: {
    ...mapState({
      username: state => state.user.username
    })
  },
  methods: {
    updateProfile (e) {
      this.$store.commit('updateProfile', e.target.value)
    }
  },
  mutations: {
    updateProfile (state, username) {
      state.user.username = username
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How to use model directive with two way computed property?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can still use model directive using two-way computed property with a setter.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input v-model="username">
  computed: {
    username: {
      get () {
        return this.$store.state.user.username
      },
      set (value) {
        this.$store.commit('updateProfile', value)
      }
    }
  }
  mutations: {
        updateProfile (state, username) {
          state.user.username = username
        }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is Vue CLI? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue CLI is a simple command line interface for scaffolding Vue.js projects. It will be helpful for rapid Vue.js development. You can install the npm package globally as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install -g @vue/cli
  # OR
  yarn global add @vue/cli
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> You can find the install version using vue --version command. Note: Vue CLI requires Node.js version 8.9 or above (8.11.0+ recommended). </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the features provided by Vue CLI?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>VueCLI provides below major features,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Interactive project scaffolding via @vue/cli  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> A rich collection of official plugins integrating the best tools in the frontend ecosystem. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>A full graphical user interface to create and manage Vue.js projects.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Zero config rapid prototyping via combination of @vue/cli and @vue/cli-service-global </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> A runtime dependency (@vue/cli-service) built on top of webpack and extensible via plugins            </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is instant prototyping?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Vue CLI, Instant prototyping is known as rapid prototyping with just a single *.vue file with the vue serve(similar to vue create) and vue build commands. But you need to install below global addon for this.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install -g @vue/cli-service-global
  # or
  yarn global add @vue/cli-service-global
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can also provide entry component for vue serve and target file for vue build using below commands</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  vue serve MyComponent.vue
  vue build MyComponent.vue
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are plugins in vue CLI?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue CLI uses a plugin-based architecture where each plugin can modify the internal webpack configuration and inject commands to vue-cli-service. i.e, Each feature is implemented as a plugin. This architecture makes Vue CLI flexible and extensible.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you install plugins in an existing Vue CLI project?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can install a plugin into an already created project with the vue add command.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  vue add @vue/eslint
  (OR)
  vue add @vue/cli-plugin-eslint
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can also add options for plugin</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  vue add @vue/eslint --config airbnb --lintOn save
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>If a plugin is already installed, you can skip the installation and only invoke its generator with the vue invoke command.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How to access local plugins in a project?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If you need access to the plugin API in your project without creating a full plugin, you can use the vuePlugins.service option in your package.json file</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "vuePlugins": {
      "service": ["my-service.js"]
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you create UI plugins kind of behavior?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can also add files that will behave like UI plugins with the vuePlugins.ui option</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "vuePlugins": {
      "ui": ["my-ui.js"]
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are presets?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A Vue CLI preset is a JSON object that contains pre-defined options and plugins for creating a new project without interactive prompts to select them. During project creation(using vue create), the presets will be saved in a ~/.vuerc which can modified at any time.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, the generated JSON object(or preset) would be as below</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "useConfigFiles": true,
    "router": true,
    "vuex": true,
    "cssPreprocessor": "sass",
    "plugins": {
      "@vue/cli-plugin-babel": {},
      "@vue/cli-plugin-eslint": {
        "config": "airbnb",
        "lintOn": ["save", "commit"]
      }
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the versioning behavior in preset plugins?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can explicitly specify versions of the plugins being used.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "plugins": {
      "@vue/cli-plugin-eslint": {
        "version": "^3.0.0",
        // ... other options for this plugin
      }
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>For official plugins, the CLI will automatically use the latest version available in the registry</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you allow plugin prompts?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Each plugin can inject its own prompts during the project creation process irrespective of preset declarations using prompts: true setting</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, user can pick their own ESLint config using the below configuration</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "plugins": {
      "@vue/cli-plugin-eslint": {
        // let the users pick their own ESLint config
        "prompts": true
      }
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are remote presets?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can share a preset with other developers by publishing it in a git repo. The repo can be published in either github, GitLab or BitBucket. The repo will contain below files,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> preset.json: The main file containing the preset data and it is required. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  generator.js: A generator that can inject or modify files in the project.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> prompts.js: A prompts file that can collect options for the generator. You can apply --preset option to use remote presets while creating the project </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  # use preset from GitHub repo
  vue create --preset username/repo my-project
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Can I use local presets?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, Vue CLI will load local presets if the value for the --preset option is a relative or absolute file path, or ends with .json. i.e, You can work with local presets directly. These local presets avoids repeatedly pushing the preset to a remote repo to test.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Directory contains preset.json file
  vue create --preset ./my-preset my-project
  (OR)
  vue create --preset my-preset.json my-project
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of browserslist option?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The browserslist option is available in package.json file in order to specify a range of browsers the project is supported. This value is going to be used by babel and autoprefixer to transpile javascript features and applying vendor prefixes.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can declare it as follows,            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  "browserslist": [
    "last 1 version",
    "> 1%",
    "IE 10"
  ]
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you find VueJS version using API?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The community plugins and components might need different strategies for different versions. In this case, you can use Vue.version which provides installed version of Vue as a string.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can implement different logic based on different versions</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  let version = Number(Vue.version.split('.')[0])

  if (version === 2) {
    // Vue v2.x.x
  } else if (version === 1) {
    // Vue v1.x.x
  } else {
    // Unsupported versions of Vue
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you create reactive objects?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In version 3.0 you can create a reactive object with the reactive() API.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const reactiveState = reactive({
    count: 0
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>In version 2.6, you can create reactive objects with Vue.observable() global API.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const reactiveState = Vue.observable({
    count: 0
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>These observable objects can be used directly in computed properties and render functions.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const Demo = {
    render(h) {
      return h('button', {
        on: { click: () => { reactiveState.count++ }}
      }, 'count is: $ {state.count}')
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is the purpose new slot directive?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Vue 2.6 version, the new slot syntax is provided using v-slot directive which aligns syntax with Vue 3.0. This is going to be replacement for old slot syntax.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> The comparison for old and new slot syntax: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!-- old -->
  <user>
    <template slot="header" slot-scope="{ msg }">
      text slot: {{ msg }}
    </template>
  </user>

  <!-- new -->
  <user>
    <template v-slot:header="{ msg }">
      text slot: {{ msg }}
    </template>
  </user>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the use of compile method?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>VueJS provides compile method which is used to compile a template string into a render function. This method is only available in the full build.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, you can compile template message: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  var result = Vue.compile('<div><span>{{ msg }}</span></div>')

  new Vue({
    data: {
      msg: 'Welcome to Vue world'
    },
    render: result.render,
    staticRenderFns: result.staticRenderFns
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What does nextTick do in VueJS?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The nextTick method is just a comfortable way to execute a function after the data has been set, and the DOM has been updated. As an example, the usage is going to be similar to setTimeout:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // modify data
  vm.msg = 'Welcome to Vue'
  // DOM not updated yet
  Vue.nextTick(function () {
    // DOM updated
  })

  // usage as a promise (2.1.0+)
  Vue.nextTick()
    .then(function () {
      // DOM updated
    })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is async error handling?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>From 2.6 version onwards, Vue's built-in error handling mechanism can capture errors inside v-on handlers. Also,if any of your lifecycle hooks or event handlers performs asynchronous operations, you can now return a Promise from the function so that any uncaught errors from that Promise chain are also sent to your error handlers.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take an example of mounted lifecycle hook, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  export default {
    async mounted() {
      // if an async error is thrown here, it now will get
      // caught by errorCaptured and Vue.config.errorHandler
      this.todos = await api.getTodos()
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are Dynamic Directive Arguments?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Vue 2.6 release onwards, Directive arguments can now accept dynamic JavaScript expressions. i.e, the specific argument that we want to use is only known at runtime.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's assign dynamic key and event directives for a div element, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div v-bind:[key]="value"></div>
  <div v-on:[event]="handler"></div>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the drawbacks of dynamic directive arguments?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Apart from the benefits of dynamic directives arguments, it brings two drawbacks or considerations on the usage            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Constraints on expressions: When you perform complex JavaScript expressions, make sure that html attribute names cannot contain spaces and quotes. The below expression doesn't work as expected </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div :[key + 'unique']="value"></div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Instead you may need to use string template syntax</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div :['$ {key}unique']="value"></div>
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Custom Directives: The custom directive implementations need to have potential argument changes in addition to value changes.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the special handling for null values in dynamic directive arguments?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Dynamic argument values are expected to be strings but it allows null as a special value that explicitly indicates that the binding should be removed. Other types will be treated as mistakes and will trigger a warning. So null value can be applied for v-bind and v-on.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  Can I use dynamic directive null value for slots?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No. It can be applied only for v-bind and v-on but not v-slot. This is because v-slot is not a binding and cannot be removed.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is Vue I18n plugin?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Vue I18n is an internationalization plugin of Vue.js. It easily integrates some localization features to your Vue.js Application.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>The simple usage with in html would be as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <script src="https://unpkg.com/vue/dist/vue.js"></script>
  <script src="https://unpkg.com/vue-i18n/dist/vue-i18n.js"></script>

  <div id="app">
    <p>{{ $t("user.message") }}</p>
  </div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>and after that configure them in javascript  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Ready translated locale messages
  const messages = {
    en: {
      user: {
        message: 'Good morning'
      }
    },
    de: {
      user: {
        message: 'Guten Morgen'
      }
    }
  }

  // Create VueI18n instance with options
  const i18n = new VueI18n({
    locale: 'de', // set locale
    messages, // set locale messages
  })


  // Create a Vue instance with 'i18n' option
  new Vue({ i18n }).$mount('#app')

          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> The output is going to be like this,Guten Morgen </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is custom formatting?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use custom formatting for some of the formatting cases such as ICU formatting syntax (message 'pattern' strings with variable-element placeholders enclosed in {curly braces}). It implement Formatter Interface.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Custom Formatter implementation
  class CustomFormatter {
      constructor (options) {
        // ...
      }
  interpolate (message, values) {
        // return the interpolated array
        return ['resolved message string']
      }
  }

  // register with 'formatter' option
  const i18n = new VueI18n({
    locale: 'en-US',
    formatter: new CustomFormatter(/* here the constructor options */),
    messages: {
      'en-US': {
        // ...
      },
      // ...
    }
  })

  // Run!
  new Vue({ i18n }).$mount('#app')
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you handle Pluralization?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can translate with pluralization by defining the locale that have a pipe | separator, and define plurals in pipe separator. Remember that template should use $tc() instead of $t().</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> First you need to define the messages, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const messages = {
    en: {
      user: 'user | users',
      friend: 'no friend | one friend | {count} friends'
    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> And the template can configure the messages with values </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <p>{{ $tc('user', 1) }}</p>
  <p>{{ $tc('user', 10) }}</p>

  <p>{{ $tc('friend', 0) }}</p>
  <p>{{ $tc('friend', 1) }}</p>
  <p>{{ $tc('friend', 10, { count: 10 }) }}</p>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Finally it outputs the result as below  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <p>user</p>
  <p>users</p>

  <p>no friend</p>
  <p>one friend</p>
  <p>10 friends</p>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How to implement DateTime localization?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can localize the datetime with definition formats(e.g. short, long, etc).</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Lets follow below steps to localize date and time,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> For example, you can add definition formats for English and Jappan locale as below            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const dateTimeFormats = {
    'en-US': {
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
      }
    },
    'ja-JP': {
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
      }
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> After that You need to specify the dateTimeFormats option of VueI18n constructor            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const i18n = new VueI18n({
    dateTimeFormats
  })
  
  new Vue({
    i18n
  }).$mount('#app')
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>  And then add them to the template</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
<div id="app">
  <p>{{ $d(new Date(), 'short') }}</p>
  <p>{{ $d(new Date(), 'long', 'ja-JP') }}</p>
</div>
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Finally it outputs the result            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="app">
    <p>May 20, 2019</p>
    <p>2019年5月20日</p>
  </div>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you implement Number localization?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can localize the number with definition formats(e.g. currency, etc)</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Lets follow below steps to localize numbers,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> You need to add definition formats. For example, lets add it for English and Japanese locales </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const numberFormats = {
    'en-US': {
      currency: {
        style: 'currency', currency: 'USD'
      }
    },
    'ja-JP': {
      currency: {
        style: 'currency', currency: 'JPY', currencyDisplay: 'symbol'
      }
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>  After that specify the numberFormats option of VueI18n constructor</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const i18n = new VueI18n({
    numberFormats
  })
  
  new Vue({
    i18n
  }).$mount('#app')
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Now let's configure them in template </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="app">
    <p>{{ $n(10, 'currency') }}</p>
    <p>{{ $n(50, 'currency', 'ja-JP') }}</p>
  </div>
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Finally it outputs the result </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="app">
    <p>$10.00</p>
    <p>￥50</p>
  </div>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you perform locale changing?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>All child components of a root instance are localized using the locale property of the VueI18n class. You can change the value of the locale property of the VueI18n instance as below.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const i18n = new VueI18n({
    locale: 'de', // set locale
    ...
  })
  
  // create root Vue instance
  new Vue({
    i18n,
    ...
  }).$mount('#app')
  
  // change other locale
  i18n.locale = 'en'
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can also use component's VueI18n instance referenced as the $i18n property which will be used to change the locale.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <template>
  <div class="locale-changer">
      <select v-model="$i18n.locale">
        <option v-for="(lang, i) in langs" :key='Lang$ {i}' :value="lang">{{ lang }}</option>
      </select>
    </div>
  </template>

  <script>
  export default {
    name: 'locale-changer',
    data () {
      return { langs: ['de', 'en'] }
    }
  }
  </script>
          `,
        }
      ],
      type: "Normal",
    }









































  ],
};
