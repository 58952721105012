import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import { NavbarHome } from "../../Components/NavbarHome/NavbarHome";
import Home from "../../Components/Home/Home";
import Interviewqa from "../../Components/Interviewqa/Interviewqa";
import ReactGA from "react-ga4";
import "./LayoutWeb.css";
import { BrowserRouter as Router, Route, Routes,useLocation  } from "react-router-dom";
import {
  setSessionStorage,
} from "../../Shared/Utils/StorageUtils";
import CommonConstants from "../../Shared/Constants/CommonConstants";
import Challenges from "../../Components/Challenges/Challenges";
import { ChallengesAnswers } from "../../Components/Challenges/ChallengesAnswers/ChallengesAnswers";
import ReactChallenges from "../../Components/ReactChallenges/ReactChallenges";
import { ReactChallengeAnswer } from "../../Components/ReactChallenges/Answers/ReactChallengeAnswer";
import appLogo from "../../assets/applogo.png";
import ReactScenarioChallenges from "../../Components/ReactChallenges/ReactScenarioChallenges/ReactScenarioChallenges";
import { ReactScenariosAnswerEditor } from "../../Components/ReactChallenges/ReactScenarioChallenges/ReactScenariosAnswerEditor/ReactScenariosAnswerEditor";

export const LayoutWeb = () => {
  const location = useLocation();

  const isChallengeAnswerPage = location.pathname.includes("/ChallengeAnswer");

  const [selCourse, setSelCourse] = useState("none");
  const navigate = useNavigate();
  const storedClickedItem = sessionStorage.getItem(
    CommonConstants.CLICKED_INDEX
  );
  const clickedItem = storedClickedItem ? JSON.parse(storedClickedItem) : 0;

  const handleSelCourse = (selVal) => {
    setSelCourse(selVal);
  };

  return (
    <div className="layout-web-inner-container">
      {!isChallengeAnswerPage && <div className="layout-web-left-container">
        <div className="layout-web-left-container__top-content-container">
          <div className="layout-web-header-text">
          <img
            className="app-logo"
            src={appLogo}
            alt={`app logo`}
          />
          </div>
          <div className="layout-web-second-header-container">
            {/* <div className="layout-web-all-intques-text">
              All Interview Questions
            </div>
            <div className="layout-web-kill-intw-text">
              Crack your Interview...🎉
            </div> */}
            <div className="layout-web__left-container-course-types-container">
              <div
                className={`layout-web__left-cont__course-type-course ${
                  clickedItem === 1 &&
                  "layout-web__left-cont__course-type-course-clicked"
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(1)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked All courses",
                  });
                  navigate(`/`);
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-graduation-cap"
                />
                Courses
              </div>
              {/* <div
                className={`layout-web__left-cont__course-javascript-intw ${
                  clickedItem === 5
                    ? "layout-web__left-cont__course-javascript-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(5)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked Javascript Interview ques",
                  });
                  const intType = "JavascriptInt";
                  navigate(
                    `/InterviewQa/${encodeURIComponent(
                      JSON.stringify(intType)
                    )}`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                Javascript Theory Questions
              </div> */}
              <div
                className={`layout-web__left-cont__course-javascript-intw ${
                  clickedItem === 14
                    ? "layout-web__left-cont__course-javascript-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(14)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "left/realtime reactjs scenarios",
                  });
                  navigate(
                    `/ReactScenarioChallenges`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                React Real-time scenarios
              </div>
              {/* <div
                className={`layout-web__left-cont__course-javascript-intw ${
                  clickedItem === 13
                    ? "layout-web__left-cont__course-javascript-output-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(13)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked Javascript output Interview ques",
                  });
                  const intType = "JavascriptOutInt";
                  navigate(
                    `/InterviewQa/${encodeURIComponent(
                      JSON.stringify(intType)
                    )}`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                Javascript Output based Questions
              </div> */}
              <div
                className={`layout-web__left-cont__course-javascript-intw ${
                  clickedItem === 11
                    ? "layout-web__left-cont__course-javascript-output-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(11)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked Javascript challenges ques",
                  });
                  navigate(`/Challenges`);
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                Javascript Coding Challenges
              </div>
              {/* <div
                className={`layout-web__left-cont__course-type__react-intw ${
                  clickedItem === 2
                    ? "layout-web__left-cont__course-type__react-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(2)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked React Interview ques",
                  });
                  const intType = "ReactInt";
                  navigate(
                    `/InterviewQa/${encodeURIComponent(
                      JSON.stringify(intType)
                    )}`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                React Interview questions
              </div> */}
              <div
                className={`layout-web__left-cont__course-type__react-intw ${
                  clickedItem === 12
                    ? "layout-web__left-cont__course-type__react-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(12)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "React Machine - left",
                  });
                  const intType = "ReactInt";
                  navigate(`/ReactChallenges`);
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                React Machine Coding 
              </div>
              <div
                className={`layout-web__left-cont__course-Angular-intw ${
                  clickedItem === 3
                    ? "layout-web__left-cont__course-Angular-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(3)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked Angular Interview ques",
                  });
                  const intType = "AngularInt";
                  navigate(
                    `/InterviewQa/${encodeURIComponent(
                      JSON.stringify(intType)
                    )}`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                Angular Interview questions
              </div>
              {/* <div
                className={`layout-web__left-cont__course-vuejs-intw ${
                  clickedItem === 4
                    ? "layout-web__left-cont__course-vuejs-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(4)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked Vue Interview ques",
                  });
                  const intType = "VueInt";
                  navigate(
                    `/InterviewQa/${encodeURIComponent(
                      JSON.stringify(intType)
                    )}`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                VueJs Interview questions
              </div> */}
              <div
                className={`layout-web__left-cont__course-html-intw ${
                  clickedItem === 6
                    ? "layout-web__left-cont__course-html-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(6)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked Html Interview ques",
                  });
                  const intType = "HtmlInt";
                  navigate(
                    `/InterviewQa/${encodeURIComponent(
                      JSON.stringify(intType)
                    )}`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                Html Interview questions
              </div>
              <div
                className={`layout-web__left-cont__course-css-intw ${
                  clickedItem === 7
                    ? "layout-web__left-cont__course-css-intw-clicked"
                    : ""
                }`}
                onClick={() => {
                  setSessionStorage(
                    CommonConstants.CLICKED_INDEX,
                    JSON.stringify(7)
                  );
                  ReactGA.event({
                    category: "Home",
                    action: "Clicked Css Interview ques",
                  });
                  const intType = "CssInt";
                  navigate(
                    `/InterviewQa/${encodeURIComponent(
                      JSON.stringify(intType)
                    )}`
                  );
                }}
              >
                <FontAwesomeIcon
                  className="layout-web__left-course__icon"
                  icon="fa-solid fa-code"
                />
                Css Interview questions
              </div>
            </div>
          </div>
        </div>
        <div className="layout-web-left-bottom-content-container">
          <div>Contact Us</div>
          <div>customercare@learndepth.com</div>
        </div>
      </div>}
      <div className={!isChallengeAnswerPage ? "layout-web-right-container": "layout-challenges-container"}>
        <NavbarHome handleSelCourse={handleSelCourse}></NavbarHome>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/InterviewQa/:intType" element={<Interviewqa />} />
          <Route path="/Challenges" element={<Challenges />} />
          <Route path="/ChallengeAnswer/:chalNumb" element={<ChallengesAnswers/>}/>
          <Route path="/ReactChallenges" element={<ReactChallenges />} />
          <Route path="/ReactScenarioChallenges" element={<ReactScenarioChallenges />} />
          <Route path="/ReactChallengeAnswer/:reactChalNumb" element={<ReactChallengeAnswer />} />
          <Route
              path="/ReactScenariosAnswer/:reactChalNumb"
              element={<ReactScenariosAnswerEditor />}
            />
        </Routes>
      </div>
    </div>
  );
};
