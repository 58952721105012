// eslint-disable-next-line import/no-anonymous-default-export
export default {
  data: [
    {
      id: 1,
      ques: "Create a nested circles based on input value given by user in textbox",
      description: [
        {
          id: "a",
          point: "Create a textbox where user can enter the number",
        },
        {
          id: "b",
          point:
            "Nested circles should be generated based on the value entered by the user",
        },
      ],
      difficulty: "Medium",
      solution: "https://stackblitz.com/edit/so-58128062-upload-progress-vqywnx?embed=1&file=index.js",
    },
    {
      id: 2,
      ques: "Implement a traffic light system",
      description: [
        {
          id: "a",
          point:
            "lights switch from green to yellow, yellow to red and red to green and loop indefinitely",
        },
        {
          id: "b",
          point: "Each light should be for predetermined interval",
        },
        {
          id: "c",
          point: "Red light: 4000ms, Yellow light: 500ms, Green light: 3000ms",
        },
      ],
      difficulty: "Easy",
      solution: "https://stackblitz.com/edit/so-58128062-upload-progress-kjiaqs?file=index.js",
    },
    {
      id: 3,
      ques: "Implement a Student transfer box",
      description: [
        {
          id: "a",
          point: "Create 2 mock API’s which returns a list of students and each student has a name, some marks and a unique registration ID. Data from the 2 API’s can have common students i.e. mock API 1 can have a student as — ABC / 98% / 1234 (name / marks / registration ID) and this same data can be there in mock API 2 response as well. Now after creating these 2 API’s using Promises and hard-coded data, you need to merge the data coming from both API’s and have to delete the duplicates.",
        },
        {
          id: "b",
          point: "Use the above data and render it in Box 1. Each student has a checkbox before its name and we can select multiple students at once. All the selected students can be moved to Box 2 by clicked on the 1st button and vice-versa for Box-2.",
        },
      ],
      difficulty: "Easy",
      solution: "https://stackblitz.com/edit/so-58128062-upload-progress-ueeqqe?file=index.js",
    },
  ],
};
