import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";
import "./NavbarHome.css";
import { useNavigate } from "react-router-dom";
import faHome from "@fortawesome/fontawesome-free-solid";
import "font-awesome/css/font-awesome.min.css";
import { setSessionStorage } from "../../Shared/Utils/StorageUtils";
import CommonConstants from "../../Shared/Constants/CommonConstants";
import { getInterviewTypeText } from "../../Shared/Helpers/CommonHelper";
import { useLocation  } from "react-router-dom";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import appLogo from "../../assets/applogo.png";

export const NavbarHome = (props) => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="layout-mobile-container">
        <div className="navbar-right__container">
          <div
            className="layout-web-homecontainer-left"
            onClick={() => {
              setSessionStorage(
                CommonConstants.CLICKED_INDEX,
                JSON.stringify(1)
              );
              navigate(`/`);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-house-door-fill"
              viewBox="0 0 16 16"
            >
              <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
            </svg>
            <div className="layoutweb-interview-type-header-home">
              {getInterviewTypeText()}
            </div>
          </div>
          
          <a
            className="telegram-link"
            href={"https://www.instagram.com/learn_frontend_web_development/"}
            target="_blank"
            rel="noopener noreferrer"
          >
          <FontAwesomeIcon
              className="layout-web__instagram__icon"
              icon={faInstagram}
            />
          </a>
        </div>
        <Menu
          isOpen={isOpen}
          onOpen={handleIsOpen}
          onClose={handleIsOpen}
          {...props}
        >
          <div className="layout-web-left-container">
            <div className="layout-web-left-container__top-content-container">
              <div className="layout-web-header-text"><img
            className="app-logo"
            src={appLogo}
            alt={`app logo`}
          /></div>
              <div className="layout-web-second-header-container">
                {/* <div className="layout-web-all-intques-text">
                  All Interview Questions
                </div>
                <div className="layout-web-kill-intw-text">
                  Crack your Interview...🎉
                </div> */}
                <div className="layout-web__left-container-course-types-container">
                  <div
                    className="layout-web__left-cont__course-type-course"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(1));
                      closeSideBar();
                      navigate(`/`);
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-graduation-cap"
                    />
                    Courses
                  </div>
                  {/* <div
                    className="layout-web__left-cont__course-javascript-intw"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(5));
                      ReactGA.event({
                        category: "Home",
                        action: "Clicked Javascript Interview ques",
                      });
                      closeSideBar();
                      const intType = "JavascriptInt";
                      navigate(
                        `/InterviewQa/${encodeURIComponent(
                          JSON.stringify(intType)
                        )}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-code"
                    />
                    Javascript Interview questions
                  </div> */}
                  {/* <div
                    className="layout-web__left-cont__course-javascript-intw"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(10));
                      ReactGA.event({
                        category: "Home",
                        action: "Clicked Javascript output Interview ques",
                      });
                      closeSideBar();
                      const intType = "JavascriptOutInt";
                      navigate(
                        `/InterviewQa/${encodeURIComponent(
                          JSON.stringify(intType)
                        )}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-code"
                    />
                    Javascript Output based questions
                  </div> */}
                  <div
                    className="layout-web__left-cont__course-javascript-intw"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(14));
                      closeSideBar();
                      ReactGA.event({
                        category: "Home",
                        action: "left/realtime reactjs scenarios",
                      });
                      navigate(
                        `/ReactScenarioChallenges`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-code"
                    />
                    Reactjs Real-time scenarios
                  </div>
                  {/* <div
                    className="layout-web__left-cont__course-type__react-intw"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(2));
                      ReactGA.event({
                        category: "Home",
                        action: "Clicked React Interview ques",
                      });
                      closeSideBar();
                      const intType = "ReactInt";
                      navigate(
                        `/InterviewQa/${encodeURIComponent(
                          JSON.stringify(intType)
                        )}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-code"
                    />
                    React Interview questions
                  </div> */}
                  <div
                    className="layout-web__left-cont__course-Angular-intw"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(3));
                      ReactGA.event({
                        category: "Home",
                        action: "Clicked Angular Interview ques",
                      });
                      closeSideBar();
                      const intType = "AngularInt";
                      navigate(
                        `/InterviewQa/${encodeURIComponent(
                          JSON.stringify(intType)
                        )}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-code"
                    />
                    Angular Interview questions
                  </div>
                  {/* <div
                    className="layout-web__left-cont__course-vuejs-intw"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(4));
                      ReactGA.event({
                        category: "Home",
                        action: "Clicked Vuejs Interview ques",
                      });
                      closeSideBar();
                      const intType = "VueInt";
                      navigate(
                        `/InterviewQa/${encodeURIComponent(
                          JSON.stringify(intType)
                        )}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-code"
                    />
                    VueJs Interview questions
                  </div> */}
                  <div
                    className="layout-web__left-cont__course-html-intw"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(6));
                      ReactGA.event({
                        category: "Home",
                        action: "Clicked Html Interview ques",
                      });
                      closeSideBar();
                      const intType = "HtmlInt";
                      navigate(
                        `/InterviewQa/${encodeURIComponent(
                          JSON.stringify(intType)
                        )}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-code"
                    />
                    Html Interview questions
                  </div>
                  <div
                    className="layout-web__left-cont__course-css-intw"
                    onClick={() => {
                      setSessionStorage(CommonConstants.CLICKED_INDEX,JSON.stringify(7));
                      ReactGA.event({
                        category: "Home",
                        action: "Clicked Css Interview ques",
                      });
                      closeSideBar();
                      const intType = "CssInt";
                      navigate(
                        `/InterviewQa/${encodeURIComponent(
                          JSON.stringify(intType)
                        )}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      className="layout-web__left-course__icon"
                      icon="fa-solid fa-code"
                    />
                    Css Interview questions
                  </div>
                </div>
              </div>
            </div>
            <div className="layout-web-left-bottom-content-container">
              <div>Contact Us</div>
              <div>customercare@learndepth.com</div>
            </div>
          </div>
        </Menu>
      </div>
      <div className="layout-web-container">
        <div className="navbar-right__container">
          <div
            className="layout-web-homecontainer-left"
            onClick={() => {
              setSessionStorage(
                CommonConstants.CLICKED_INDEX,
                JSON.stringify(1)
              );
              navigate(`/`);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-house-door-fill"
              viewBox="0 0 16 16"
            >
              <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
            </svg>
            <div className="layoutweb-interview-type-header-home">
              {getInterviewTypeText()}
            </div>
          </div>
          <a
            className="telegram-link"
            href={"https://www.instagram.com/learn_frontend_web_development/"}
            target="_blank"
            rel="noopener noreferrer"
          >
                      <FontAwesomeIcon
              className="layout-web__instagram__icon"
              icon={faInstagram}
            />
          </a>
        </div>
      </div>
    </>
  );
};
