import React from "react";
import SyntaxParas from "../SyntaxParas/SyntaxParas";
import "./NormalParas.css";
import sociallogin from "../../../assets/sociallogin.JPG";

const NormalParas = ({ points }) => {
  const imagesArray = {
    sociallogin: sociallogin,
  };
  return (
    <ul className="normal-main-points-container">
      {points.map((itemAns, index) => {
        if (itemAns.htmlElement === "div") {
          return (
            <div
              className={"normal__each-point"}
              dangerouslySetInnerHTML={{ __html: itemAns.value }}
            />
          );
        } else if (itemAns.htmlElement === "Syntax") {
          return (
            <SyntaxParas
              className={"bullet__each-point"}
              content={itemAns.value}
            />
          );
        } else if (itemAns.htmlElement === "Li") {
          return (
            <li
              className={"bullet__each-point"}
              dangerouslySetInnerHTML={{ __html: itemAns.value }}
            />
          );
        } else if (itemAns.htmlElement === "nav") {
          return (
            <div>
              Good Reference:{" "}
              <a
                // className={"bullet__each-point"}s
                target="_blank"
                href={itemAns.value}
                dangerouslySetInnerHTML={{ __html: itemAns.value }}
              />
            </div>
          );
        } else if (itemAns.htmlElement === "SubHeader") {
          return (
            <div
              className={"normal__each-point-subheader"}
              dangerouslySetInnerHTML={{ __html: itemAns.value }}
            />
          );
        } else if (itemAns.htmlElement === "img") {
          return (
            <img
              className="syntax__paras-container"
              src={imagesArray[itemAns.value]}
            ></img>
          );
        } else if (itemAns.htmlElement === "p") {
          return <p dangerouslySetInnerHTML={{ __html: itemAns.value }} />;
        }
      })}
    </ul>
  );
};

export default NormalParas;
