import React, { useState, useEffect, useRef } from "react";
import reactJson from "./QnaJson/ReactQnaData";
import angularJson from "./QnaJson/AngularQnaData";
import VueQnaData from "./QnaJson/VueQnaData";
import JavascriptQnaData from "./QnaJson/JavascriptQnaData";
import JavascriptOutputQnaData from "./QnaJson/JavascriptOutputQnaData";
import HtmlQnaData from "./QnaJson/HtmlQnaData";
import CssQnaData from "./QnaJson/CssQnaData";
import BulletParas from "../../ReusableComponents/AnswersUtils/BulletParas/BulletParas";
import NormalParas from "../../ReusableComponents/AnswersUtils/NormalParas/NormalParas";
import { useParams } from "react-router-dom";
import JsChallenges from "./QnaJson/JSCodingChallenges";
import "./Interviewqa.css";

const Interviewqa = () => {
  const [qajson, setqajson] = useState([]);
  const [visibleItems, setVisibleItems] = useState(10); // Number of items to display initially
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const { intType } = useParams();
  const selType = JSON.parse(decodeURIComponent(intType));
  let type = selType;
  const containerRef = useRef(null);

  useEffect(() => {
    let jsonData;
    switch (type) {
      case "ReactInt":
        jsonData = reactJson.data;
        break;
      case "AngularInt":
        jsonData = angularJson.data;
        break;
      case "VueInt":
        jsonData = VueQnaData.data;
        break;
      case "JavascriptInt":
        jsonData = JavascriptQnaData.data;
        break;
      case "JavascriptOutInt":
        jsonData = JavascriptOutputQnaData.data;
        break;
      case "HtmlInt":
        jsonData = HtmlQnaData.data;
        break;
      case "CssInt":
        jsonData = CssQnaData.data;
        break;
      default:
        jsonData = [];
    }
    setqajson(jsonData);
    setVisibleItems(10);
    setIsLoading(false); // Set loading state to false after data is fetched
  }, [type]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      if (
        container.scrollTop + container.clientHeight + 50 >=
        container.scrollHeight
      ) {
        setVisibleItems((prevVisibleItems) =>
          Math.min(prevVisibleItems + 10, qajson.length)
        );
      }
    };
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [qajson, visibleItems]);

  const renderAnswer = (item) => {
    switch (item.type) {
      case "Normal":
        return <NormalParas points={item.answer}></NormalParas>;
      case "Bullet":
        return <BulletParas points={item.answer}></BulletParas>;
      default:
        return <NormalParas points={item.answer}></NormalParas>;
    }
  };

  return (
    <div className="ques__main-container" ref={containerRef}>
      <div>
        {(qajson.slice(0, visibleItems) ?? []).map((item, index) => (
          <div className="ques__main-intquesans-container" key={index}>
            <div className="ques__main-intques">
              Q{index + 1}. {item.question}
            </div>
            <div className="ques__main-intansw">{renderAnswer(item)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interviewqa;
