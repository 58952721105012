import CommonConstants from "../Constants/CommonConstants";

export const getInterviewTypeText = () => {
  const storedClickedItem = sessionStorage.getItem(
    CommonConstants.CLICKED_INDEX
  );
  const clickedItem = storedClickedItem ? JSON.parse(storedClickedItem) : 0;

  switch (clickedItem) {
    case 2:
      return "200+ React Interview Questions";
    case 14:
      return "React Real-time scenarios";
    case 3:
      return "200+ Angular Interview Questions";
    case 4:
      return "200+ VueJs Interview Questions";
    case 5:
      return "60+ Javascript Interview Questions";
    case 6:
      return "50+ Html Interview Questions";
    case 7:
      return "50+ Css Interview Questions";
    case 9:
      return "10+ ReactJs Package Integrations";
    case 10:
      return "20+ JS Output Based Q&A";
    default:
      return "Crack Your Interview Now";
  }
};
