// eslint-disable-next-line import/no-anonymous-default-export
export default {
  data: [
    {
      question: "  Describe HTML layout structure.  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "Every web page has different components to display the intended content and a specific UI. But still, there are few things which are templated and are globally accepted way to structure the web page, such as:",
        },
        {
          htmlElement: "Li",
          value:
            "<span> header: Stores the starting information about the web page. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> footer: Represents the last section of the page. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> nav: The navigation menu of the HTML page </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>article: It is a set of information.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> section: It is used inside the article block to define the basic structure of a page. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> <aside>: Sidebar content of the page. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What are tags and attributes in HTML?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Tags are the primary component of the HTML that defines how the content will be structured/ formatted, whereas Attributes are used along with the HTML tags to define the characteristics of the element. For example, <p align=” center”>Interview questions</p>, in this the ‘align’ is the attribute using which we will align the paragraph to show in the center of the view.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are void elements in HTML?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>HTML elements which do not have closing tags or do not need to be closed are Void elements. </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What is the advantage of collapsing white space?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In HTML, a blank sequence of whitespace characters is treated as a single space character, Because the browser collapses multiple spaces into a single space character and this helps a developer to indent lines of text without worrying about multiple spaces and maintain readability and understandability of HTML codes.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the ‘class’ attribute in HTML?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The class attribute is used to specify the class name for an HTML element. Multiple elements in HTML can have the same class value. Also, it is mainly used to associate the styles written in the stylesheet with the HTML elements</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the difference between the ‘id’ attribute and the ‘class’ attribute of HTML elements?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Multiple elements in HTML can have the same class value, whereas a value of id attribute of one element cannot be associated with another HTML element.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Define multipart form data?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Multipart form data is one of the values of the enctype attribute. It is used to send the file data to the server-side for processing. The other valid values of the enctype attribute are text/plain and application/x-www-form-urlencoded.            </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  How to optimize website assets loading?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>To optimize website load time we need to optimize its asset loading and for that:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> CDN hosting - A CDN or content delivery network is geographically distributed servers to help reduce latency. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>File compression - This is a method that helps to reduce the size of an asset to reduce the data transfer  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> File concatenation - This reduces the number of HTTP calls </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Minify scripts - This reduces the overall file size of js and CSS files </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Parallel downloads - Hosting assets in multiple subdomains can help to bypass the download limit of 6 assets per domain of all modern browsers. This can be configured but most general users never modify these settings</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Lazy Loading - Instead of loading all the assets at once, the non-critical assets can be loaded on a need basis.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the various formatting tags in HTML?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span> HTML has various formatting tags</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> b - makes text bold </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>i - makes text italic  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> em - makes text italic but with added semantics importance </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> big - increases the font size of the text by one unit </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> small - decreases the font size of the text by one unit </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> sub - makes the text a subscript </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> sup - makes the text a superscript </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> del - displays as strike out text </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> strong - marks the text as important </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> mark - highlights the text </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> ins - displays as added text </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the different kinds of Doctypes available?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The three kinds of Doctypes which are available:            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Strict Doctype             </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Transitional Doctype            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Frameset Doctype            </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is HTML?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>HTML stands for Hyper Text Markup Language. It is a language of World Wide Web. It is a standard text formatting language which is used to create and display pages on the Web. It makes the text more interactive and dynamic. It can turn text into images, tables, links</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are Tags?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>HTML tags are composed of three things: an opening tag, content and ending tag. Some tags are unclosed tags.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>HTML documents contain two things:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>content, and  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> tags </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>When a web browser reads an HTML document, the browser reads it from top to bottom and left to right. HTML tags are used to create HTML documents and render their properties. Each HTML tags have different properties.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <tag> content </tag>  
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Content is placed between tags to display data on the web page.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is formatting in HTML?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The HTML formatting is a process of format the text for a better look and feel. It uses different tags to make text bold, italicized, underlined</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How many types of heading does an HTML contain?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The HTML contains six types of headings which are defined with the h1 to h6 tags. Each type of heading tag displays different text size from another. So, h1 is the largest heading tag and h6 is the smallest one. For example:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <h1>Heading no. 1</h1>    
  <h2>Heading no. 2</h2>    
  <h3>Heading no. 3</h3>    
  <h4>Heading no. 4</h4>    
  <h5>Heading no. 5</h5>    
  <h6>Heading no. 6</h6>    
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How to create a hyperlink in HTML?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The HTML provides an anchor tag to create a hyperlink that links one page to another page. These tags can appear in any of the following ways:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Unvisited link - It is displayed, underlined and blue. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Visited link - It is displayed, underlined and purple. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Active link - It is displayed, underlined and red.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  Which HTML tag is used to display the data in the tabular form?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The HTML table tag is used to display data in tabular form (row * column). It also manages the layout of the page, e.g., header section, navigation bar, body content, footer section. Here is the list of tags used while displaying the data in the tabular form:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <table>     It defines a table.
  <tr>	      It defines a row in a table.
  <th>	      It defines a header cell in a table.
  <td>	      It defines a cell in a table.
  <caption>   It defines the table caption.
  <colgroup>  It specifies a group of one or more columns in a table for formatting.
  <col>       It is used with <colgroup> element to specify column properties for each column.
  <tbody>     It is used to group the body content in a table.
  <thead>     It is used to group the header content in a table.
  <tfooter>   It is used to group the footer content in a table.
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are some common lists that are used when designing a page?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>There are many common lists which are used to design a page. You can choose any or a combination of the following list types:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Ordered list - The ordered list displays elements in numbered format.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  It is represented by <ol> tag.
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Unordered list - The unordered list displays elements in bulleted format.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  It is represented by <ul> tag.
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Definition list - The definition list displays elements in definition form like in dictionary. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  The <dl>, <dt> and <dd> tags are used to define description list.
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "What is semantic HTML?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Semantic HTML is a coding style. It is the use of HTML markup to reinforce the semantics or meaning of the content.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>For example: In semantic HTML  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <b> </b> tag is not used for bold statement as well as <i> </i> tag is used for italic. 
  Instead of these we use <strong></strong> and <em></em> tags.
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is an image map?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Image map facilitates you to link many different web pages using a single image. It is represented by <map> tag. You can define shapes in images that you want to make part of an image mapping. </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How to insert a copyright symbol on a browser page?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can insert a copyright symbol by using &amp;copy; or &amp;#169; in an HTML file.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How to create a nested webpage in HTML?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The HTML iframe tag is used to display a nested webpage. In other words, it represents a webpage within a webpage. The HTML iframe tag defines an inline frame. For example:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <!DOCTYPE html>    
  <html>    
  <body>    
  <h2>HTML Iframes example</h2>    
  <p>Use the height and width attributes to specify the size of the iframe:</p>    
  <iframe src="https://www.javatpoint.com/" height="300" width="400"></iframe>    
  </body>    
  </html>   
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you keep list elements straight in an HTML file?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can keep the list elements straight by using indents.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "Does a hyperlink only apply to text?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, you can use hyperlinks on text and images both. The HTML anchor tag defines a hyperlink that links one page to another page. The 'href' attribute is the most important attribute of the HTML anchor tag.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <a href = "..........."> Link Text </a>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is a style sheet?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A style sheet is used to build a consistent, transportable, and well-designed style template. You can add these templates on several different web pages. It describes the look and formatting of a document written in markup language.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Can you create a multi-colored text on a web page?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes. To create a multicolor text on a web page you can use &lt;font color='color'&gt;Text&lt;/font&gt; </&font> for the specific texts you want to color.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Is it possible to change the color of the bullet?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The color of the bullet is always the color of the first text of the list. So, if you want to change the color of the bullet, you must change the color of the text.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is a marquee?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Marquee is used to put the scrolling text on a web page. It scrolls the image or text up, down, left or right automatically. You should put the text which you want to scroll within the &lt;marquee>......&lt;/marquee> tag.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How many tags can be used to separate a section of texts?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Three tags are used to separate the texts.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> &lt;br> tag - Usually &lt;br> tag is used to separate the line of text. It breaks the current line and conveys the flow to the next line </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>&lt;p> tag - The &lt;p> tag contains the text in the form of a new paragraph.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>&lt;blockquote> tag - It is used to define a large quoted section. If you have a large quotation, then put the entire text within &lt;blockquote>.............&lt;/blockquote> tag.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How to make a picture of a background image of a web page?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>To make a picture a background image on a web page, you should put the following tag code after the </head> tag.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <body background = "image.gif">  
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Here, replace the 'image.gif' with the name of your image file which you want to display on your web page.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "What are empty elements?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>HTML elements with no content are called empty elements. For example: &lt;br>, &lt;hr> etc.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the use of a span tag? Give one example.   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The span tag is used for following things:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> For adding color on text </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> For adding background on text </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Highlight any color text  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <p>  
    <span style="color:#ffffff;">  
      In this page we use span.  
    </span>  
  </p>  
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the use of an iframe tag?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>An iframe is used to display a web page within a web page.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <iframe src="URL"></iframe>  
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Example:            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <iframe src="demo_iframe.html" width="200px" height="200px"></iframe>  
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Target to a link:            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <iframe src="http://www.javatpoint.com" name="iframe_a"></iframe>  
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the entities in HTML?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The HTML character entities are used as a replacement for reserved characters in HTML. You can also replace characters that are not present on your keyboard by entities. These characters are replaced because some characters are reserved in HTML.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "Why is a URL encoded in HTML?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>An URL is encoded to convert non-ASCII characters into a format that can be used over the Internet because a URL is sent over the Internet by using the ASCII character-set only. If a URL contains characters outside the ASCII set, the URL has to be converted. The non-ASCII characters are replaced with a '%' followed by hexadecimal digits.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  Does a <!DOCTYPE html> tag is a HTML tag?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, the <!DOCTYPE html> declaration is not an HTML tag. There are many type of HTML e.g. HTML 4.01 Strict, HTML 4.01 Transitional, HTML 4.01 Frameset, XHTML 1.0 Strict, XHTML 1.0 Transitional, XHTML 1.0 Frameset, XHTML 1.1 etc. So, <!DOCTYPE html> is used to instruct the web browser about the HTML page.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What is the canvas element in HTML5?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The &lt;canvas> element is a container that is used to draw graphics on the web page using scripting language like JavaScript. It allows for dynamic and scriptable rendering of 2D shapes and bitmap images. There are several methods in canvas to draw paths, boxes, circles, text and add images. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> For Example:  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <canvas id="myCanvas1" width="300" height="100" style="border:2px solid;">    
    Your browser does not support the HTML5 canvas tag.    
  </canvas>    
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is SVG?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>HTML SVG is used to describe the two-dimensional vector and vector/raster graphics. SVG images and their behaviors are defined in XML text files. So as XML files, you can create and edit an SVG image with the text editor. It is mostly used for vector type diagrams like pie charts, 2-Dimensional graphs in an X, Y coordinate system.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <svg width="100" height="100">    
    <circle cx="50" cy="50" r="40" stroke="yellow" stroke-width="4" fill="red" />    
  </svg>   
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the different new form element types in HTML 5?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Following is a list of 10 frequently used new elements in HTML 5:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Color
  Date
  Datetime-local
  Email
  Time
  Url
  Range
  Telephone
  Number
  Search
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Is there any need to change the web browsers to support HTML5?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No. Almost all browsers (updated versions) support HTML 5. For example Chrome, Firefox, Opera, Safari, IE.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Which type of video formats are supported by HTML5?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>HTML 5 supports three types of video format</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> mp4 </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> WebM </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Ogg </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Is audio tag supported in HTML 5?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes. It is used to add sound or music files on the web page. There are three supported file formats for HTML 5 audio tag.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> mp3 </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> WAV </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Ogg </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's see the code to play mp3 file using HTML audio tag.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <audio controls>    
    <source src="koyal.mp3" type="audio/mpeg">    
    Your browser does not support the html audio tag.    
  </audio>    
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Instead of koyal.mp3, you can pass any mp3 file name.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the difference between progress and meter tag?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The progress tag is used to represent the progress of the task only while the meter tag is used to measure data within a given range</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the use of figure tag in HTML 5?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The figure tag is used to add a photo in the document on the web page. It is used to handle the group of diagrams, photos, code listing with some embedded content.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <p>The Taj Mahal is widely recognized as "the jewel of Muslim art in India and one of the universally admired masterpieces of the world's heritage."</p>    
  <figure>    
    <img src="htmlpages/images/tajmahal.jpg" alt="Taj Mahal"/>    
  </figure>     
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is the use of figcaption tag in HTML 5?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The &lt;figcaption> element is used to provide a caption to an image. It is an optional tag and can appear before or after the content within the &lt;figure> tag. The &lt;figcaption> element is used with &lt;figure> element and it can be placed as the first or last child of the &lt;figure> element.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <figure>    
  <img src="htmlpages/images/tajmahal.jpg" alt="Taj Mahal"/>    
    <figcaption>Fig.1.1 - A front view of the great Taj Mahal in Agra.</figcaption>    
  </figure>      
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is button tag?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The button tag is used in HTML 5. It is used to create a clickable button within the HTML form on the web page. It is generally used to create a 'submit' or 'reset' button. Let's see the code to display the button.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <button name="button" type="button">Click Here</button>   
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is the use of details and summary tag?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The details tag is used to specify some additional details on the web page. It can be viewed or hidden on demand. The summary tag is used with details tag</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is datalist tag?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The HTML 5 datalist tag provides an autocomplete feature on the form element. It facilitates users to choose the predefined options to the users to select data.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <label>    
  Enter your favorite cricket player: Press any character<br />    
  <input type="text" id="favCktPlayer" list="CktPlayers">    
  <datalist id="CktPlayers">    
  <option value="Sachin Tendulkar">    
  <option value="Brian Lara">    
  <option value="Jacques Kallis">     
  <option value="Ricky Ponting">     
  <option value="Rahul Dravid">     
  <option value="Shane Warne">     
  <option value="Rohit Sharma">     
  <option value="Donald Bradman">     
  <option value="Saurav Ganguly ">     
  <option value="AB diVilliers">     
  <option value="Mahendra Singh Dhoni">     
  <option value="Adam Gilchrist">    
  </datalist>    
  </label>     
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  If I do not put <!DOCTYPE html> will HTML 5 work?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, the browser will not be able to identify that it is an HTML document and HTML 5 tags do not function properly..</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the use of the required attribute in HTML5?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>It forces a user to fill text on the text field or text area before submitting the form. It is used for form validation.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Name: <input type="text" name="name" required>  
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is a manifest file in HTML5?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The manifest file is used to list down resources that can be cached. Browsers use this information to make the web page load faster than the first time. There are 3 sections in the manifest file.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>CACHE Manifest : Files needs to be cached  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Network : File never to be cached, always need a network connection.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Fallback : Fallback files in case a page is inaccessible  </span>",
        },
      ],
      type: "Normal",
    }

  ],
};
