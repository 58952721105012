import React from "react";
import SyntaxParas from "../SyntaxParas/SyntaxParas";
import "./BulletParas.css";

const BulletParas = ({ points }) => {
  return (
    <ul className="normal-main-points-container">
      {points.map((itemAns, index) => {
        if (itemAns.htmlElement === "Li") {
          return (
            <li
              className={"bullet__each-point"}
              dangerouslySetInnerHTML={{ __html: itemAns.value }}
            />
          );
        } else if (itemAns.htmlElement === "Syntax") {
          return (
            <SyntaxParas
              className={"bullet__each-point"}
              content={itemAns.value}
            />
          );
        }
      })}
    </ul>
  );
};

export default BulletParas;
