import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import "./SyntaxParas.css";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";


const SyntaxParas = ({ content,containerClassName }) => {

  return (
    <SyntaxHighlighter className={`${containerClassName ? containerClassName : "syntax__paras-container"}`} language="jsx"
    style={vscDarkPlus}
    wrapLongLines
    customStyle={{
        // backgroundColor: "transparent",
        opacity: "1",
        marginTop: "-2rem",
    }}
    codeTagProps={{
        style: {
            // color: "white",
        },
    }}>
      {content}
    </SyntaxHighlighter>
  );
};

export default SyntaxParas;
