import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./ReusableComponents/Layout/Layout";
import ReactGA from "react-ga4";
import "./App.css";

ReactGA.initialize('G-RVCTTSJ6G2');

function App() {
  return (
    <Router>
      <Layout></Layout>
    </Router>
  );
}

export default App;
