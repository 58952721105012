// eslint-disable-next-line import/no-anonymous-default-export
export default {
    data: [
      {
        id: 1,
        ques: "Load States based on countries | Reactjs",
        description: [
          {
            id: "a",
            point: "Create 3 dropdowns load them with countries, states and capitals",
          },
          {
            id: "b",
            point:
              "Autopopulate the states based on country selected and capitals based on state selected",
          },
          {
            id: "b",
            point:
              "Autoselect country based on state selected and capitals dropdown should be disabled until state is selected",
          },
        ],
        difficulty: "Medium",
        visibleFiles: ["/App.js","/App.css"],
        files: { 
              "/App.js": `import React, { useState } from 'react';
import './App.css'
// Sample data
const countries = [
  { countryId: 1, name: 'India' },
  { countryId: 2, name: 'USA' },
  { countryId: 3, name: 'Canada' },
];

const states = [
  { stateId: 1, name: 'Telangana', countryId: 1 },
  { stateId: 2, name: 'Maharashtra', countryId: 1 },
  { stateId: 3, name: 'California', countryId: 2 },
  { stateId: 4, name: 'Texas', countryId: 2 },
  { stateId: 5, name: 'Ontario', countryId: 3 },
  { stateId: 6, name: 'Quebec', countryId: 3 },
];

const capitals = [
  { capitalId: 1, name: 'Hyderabad', stateId: 1 },
  { capitalId: 2, name: 'Mumbai', stateId: 2 },
  { capitalId: 3, name: 'Sacramento', stateId: 3 },
  { capitalId: 4, name: 'Austin', stateId: 4 },
  { capitalId: 5, name: 'Toronto', stateId: 5 },
  { capitalId: 6, name: 'Quebec City', stateId: 6 },
];

const App = () => {
  const [filteredStates, setFilteredStates] = useState(states);
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [filteredCapitals, setFilteredCapitals] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');

  // Handle country change
  const handleCountryChange = (event) => {
    const countryId = parseInt(event.target.value);
    setSelectedCountry(countryId);

    if (countryId) {
      setFilteredStates(states.filter(state => state.countryId === countryId));
    } else {
      setFilteredStates(states);
    }

    setSelectedState('');
    setFilteredCapitals([]);
  };

  // Handle state change
  const handleStateChange = (event) => {
    const stateId = parseInt(event.target.value);
    setSelectedState(stateId);

    if (stateId) {
      const selectedState = states.find(state => state.stateId === stateId);
      setSelectedCountry(selectedState.countryId);
      setFilteredCapitals(capitals.filter(capital => capital.stateId === stateId));
    } else {
      setFilteredCapitals([]);
    }
  };

  return (
     <div className="dropdown-container">
      {/* Country Dropdown */}
       <select className="dropdown" value={selectedCountry} onChange={handleCountryChange}>
        <option value="">Select Country</option>
        {filteredCountries.map((country) => (
          <option key={country.countryId} value={country.countryId}>
            {country.name}
          </option>
        ))}
      </select>

      {/* State Dropdown */}
       <select className="dropdown" value={selectedState} onChange={handleStateChange}>
        <option value="">Select State</option>
        {filteredStates.map((state) => (
          <option key={state.stateId} value={state.stateId}>
            {state.name}
          </option>
        ))}
      </select>

      {/* Capital Dropdown */}
      <select className="dropdown" disabled={!selectedState}>
        <option value="">Select Capital</option>
        {filteredCapitals.map((capital) => (
          <option key={capital.capitalId} value={capital.capitalId}>
            {capital.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default App;


`,
"/App.css":`
/* Center the dropdowns container */
.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height to center vertically */
  gap: 20px; /* Space between dropdowns */
}

/* Style the dropdowns */
.dropdown {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  appearance: none; /* Remove default dropdown arrow */
}

/* Add a custom arrow */
.dropdown::after {
  content: '▼';
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Adjust dropdowns on hover */
.dropdown:hover {
  border-color: #888;
  background-color: #fff;
}

`
             },
        solution: "https://stackblitz.com/edit/so-58128062-upload-progress-vqywnx?embed=1&file=index.js",
      },
      {
        id: 2,
        ques: "Implement a Dynamic checkbox counter",
        description: [
          {
            id: "a",
            point:
              "Display 4 checkboxes with different names and a button named selectall",
          },
          {
            id: "b",
            point: "User can select each checkbox ",
          },
          {
            id: "c",
            point: "Select all button click will check all checkboxes",
          },
          {
            id: "d",
            point: "Button should be disabled until all checkboxes are selected.",
          },
          {
            id: "e",
            point: "Display selected checkboxes count and names in ui.",
          },
        ],
        difficulty: "Easy",
        visibleFiles: ["/App.js","/App.css"],
        files: { 
          "/App.js": `import React, { useState } from 'react';
import './App.css'

 const Checkbox = ({ label, checked, onChange }) => {
  return (
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} />
        {label}
      </label>
  );
};
 
const App = () => {
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: 'Checkbox 1', checked: false },
    { id: 2, label: 'Checkbox 2', checked: false },
    { id: 3, label: 'Checkbox 3', checked: false },
    { id: 4, label: 'Checkbox 4', checked: false },
  ]);

  const [selectAllDisabled, setSelectAllDisabled] = useState(false);

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
    const allChecked = updatedCheckboxes.every((checkbox) => checkbox.checked);
    setSelectAllDisabled(allChecked);
  };

  const handleSelectAll = () => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: !selectAllDisabled,
    }));
    setCheckboxes(updatedCheckboxes);
    setSelectAllDisabled(!selectAllDisabled);
  };

  const selectedCheckboxes = checkboxes.filter((checkbox) => checkbox.checked);
  const selectedCount = selectedCheckboxes.length;

  return (
    <>
      {checkboxes.map((checkbox) => (
        <Checkbox
          key={checkbox.id}
          label={checkbox.label}
          checked={checkbox.checked}
          onChange={() => handleCheckboxChange(checkbox.id)}
        />
      ))}
      <button onClick={handleSelectAll} disabled={selectAllDisabled}>
        {selectAllDisabled ? 'Deselect All' : 'Select All'}
      </button>
      <p>Selected: {selectedCount}</p>
      <ul>
        {selectedCheckboxes.map((checkbox) => (
          <li key={checkbox.id}>{checkbox.label}</li>
        ))}
      </ul>
    </>
  );
};


export default App;


`,
          "/App.css":`/* App.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

div {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
}

label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

input[type="checkbox"] {
  margin-right: 10px;
  accent-color: #007bff;
}

button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
}

button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: #d6d6d6;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

p {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  font-size: 14px;
  color: #555;
  padding: 5px 0;
}
`

             },
        solution: "https://stackblitz.com/edit/so-58128062-upload-progress-kjiaqs?file=index.js",
      },
      {
        id: 3,
        ques: "Implement Auto-Save form details with Debouncing",
        description: [
          {
            id: "a",
            point: "Create a react form with username and password fileds",
          },
          {
            id: "b",
            point: "Create a custom debounce function to delay the operations",
          },
          {
            id: "c",
            point: "When user stops typing use the debounce function to autosave the username and password "
          }
        ],
        difficulty: "Medium",
        visibleFiles: ["/App.js","/App.css"],
        files: { 
          "/App.js": `import React, { useState, useEffect } from 'react';
import './App.css'

const App = () => {
  const [formData, setFormData] = useState({ username: '', email: '' });

  // Simulate an auto-save function
  const saveData = (data) => {
    console.log("Auto-saving data: ", data);
    // Replace with your save logic (e.g., API call)
  };

  useEffect(() => {
      const handler = setTimeout(() => {
        saveData(formData);
      }, 1000);

      return () => {
        clearTimeout(handler);
      };
    
  }, [formData]);

  // Handle input changes and trigger isTyping
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
   <div className="container">
      <form className="form">
        <div className="inputGroup">
          <label className="label">Username:</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="input"
          />
        </div>
        <div className="inputGroup">
          <label className="label">Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="input"
          />
        </div>
      </form>
    </div>
  );
};

export default App;


`,
          "/App.css":`.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.inputGroup {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

`

             },
        solution: "https://stackblitz.com/edit/so-58128062-upload-progress-ueeqqe?file=index.js",
      },
      {
        id: 4,
        ques: "Implement Search filter for a List with debouncing",
        description: [
          {
            id: "a",
            point: "Create an array of objects, each containing an id and a countryName",
          },
          {
            id: "b",
            point: "Display all the country names from the array in the UI.",
          },
          {
            id: "c",
            point: "Display a search input box that allows users to filter the displayed country names based on their input."
          },
          {
            id:"d",
            point: "Implement debouncing on the search input to optimize performance and handle rapid user input efficiently."
          }
        ],
        difficulty: "Medium",
        visibleFiles: ["/App.js","/App.css"],
        files: { 
          "/App.js": `import React, { useState, useEffect } from 'react';
import './App.css'

const App = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const items = [
    { id: 1, countryname: 'India' },
    { id: 2, countryname: 'USA' },
    { id: 3, countryname: 'Canada' },
    { id: 4, countryname: 'Australia' }
  ];

  useEffect(() => {
    let timeOut = setTimeout(()=>{
      const results = items.filter(item =>
        item.countryname.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredResults(results);
    },300)

    return () => {
      clearTimeout(timeOut)
    };
  }, [searchQuery]);

  return (
  <div className="container">
    <input
      type="text"
      placeholder="Search countries..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
    <ul>
      {filteredResults.map(item => (
        <li key={item.id}>{item.countryname}</li>
      ))}
    </ul>
  </div>
  );
};


export default App;





`,
          "/App.css":`


          /* App.css */
          body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
          }
          
          .container {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            padding: 20px;
            max-width: 400px;
            width: 100%;
            text-align: center;
          }
          
          input[type="text"] {
            width: 100%;
            padding: 10px;
            margin-bottom: 20px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
            box-sizing: border-box;
          }
          
          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
          
          li {
            padding: 10px;
            border-bottom: 1px solid #ddd;
          }
          
          li:last-child {
            border-bottom: none;
          }
          
          li:hover {
            background-color: #f0f0f0;
          }
          
`

             },
        solution: "https://stackblitz.com/edit/so-58128062-upload-progress-ueeqqe?file=index.js",
      },
    ],
  };
  

  // visibleFiles: ["/App.js", "/index.js"],
  //       files: { "/Child.js": `export default Child =() => {
  //         return <h1>Iam 3333333333333</h1>
  //     }`,
  //             "/App.js": `import Child from './Child';
      
  //     export default App = () => {
  //       return <Child></Child>
  //     }`
  //            },