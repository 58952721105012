// eslint-disable-next-line import/no-anonymous-default-export
export default {
  data: [
    {
      question: "What is Angular Framework?",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular is a TypeScript-based open-source front-end platform that makes it easy to build web, mobile and desktop applications. The major features of this framework include declarative templates, dependency injection, end to end tooling which ease application development.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "What are the key components of Angular?",
      answer: [
        {
          htmlElement: "div",
          value: "<span>Angular has the key components below,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Component: These are the basic building blocks of an Angular application to control HTML views.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Modules: An Angular module is a set of angular basic building blocks like components, directives, services etc. An application is divided into logical pieces and each piece of code is called as 'module' which perform a single task.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Templates: These represent the views of an Angular application.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Services: Are used to create components which can be shared across the entire application. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Metadata: This can be used to add more data to an Angular class. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are directives?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span> Directives add behaviour to an existing DOM element or an existing component instance. </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Directive, ElementRef, Input } from '@angular/core';

  @Directive({ selector: '[myHighlight]' })
  export class HighlightDirective {
  constructor(el: ElementRef) {
    el.nativeElement.style.backgroundColor = 'yellow';
    }
  }         
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Now this directive extends HTML element behavior with a yellow background as below </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <p myHighlight>Highlight me!</p>        
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "What are components?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span> Components are the most basic UI building block of an Angular app, which form a tree of Angular components. These components are a subset of directives. Unlike directives, components always have a template, and only one component can be instantiated per element in a template. Let's see a simple example of Angular component </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Component } from '@angular/core';

  @Component ({
    selector: 'my-app',
    template: ' <div>
      <h1>{{title}}</h1>
      <div>Learn Angular6 with examples</div>
      </div> ',
    })
          
    export class AppComponent {
    title: string = 'Welcome to Angular world';
  }        
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the differences between Component and Directive?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In a short note, A component(@component) is a directive-with-a-template.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Some of the major differences are mentioned below  </span>",
        },
        {
          htmlElement: "SubHeader",
          value: "<span>Component</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>To register a component we use @Component meta-data annotation  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Components are typically used to create UI widgets </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Component is used to break down the application into smaller components  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Only one component can be present per DOM element	 </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>@View decorator or templateurl/template are mandatory	  </span>",
        },
        {
          htmlElement: "SubHeader",
          value: "<span>Directive</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>To register a directive we use @Directive meta-data annotation  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Directives are used to add behavior to an existing DOM element </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Directive is used to design re-usable components  </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> Many directives can be used per DOM element </span>",
        },
        {
          htmlElement: "Li",
          value: "<span>Directive doesn't use View  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "What is a template?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A template is a HTML view where you can display data by binding controls to properties of an Angular component. You can store your component's template in one of two places. You can define it inline using the template property, or you can define the template in a separate HTML file and link to it in the component metadata using the @Component decorator's templateUrl property.</span>",
        },
        {
          htmlElement: "SubHeader",
          value: "<span> Using inline template with template syntax, </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Component } from '@angular/core';

  @Component ({
  selector: 'my-app',
  template: '
    <div>
      <h1>{{title}}</h1>
      <div>Learn Angular</div>
    </div>
    '
  })
          
  export class AppComponent {
    title: string = 'Hello World';
  }          
  `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>Using separate template file such as app.component.html  </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Component } from '@angular/core';

  @Component ({
    selector: 'my-app',
    templateUrl: 'app/app.component.html'
  })
          
  export class AppComponent {
    title: string = 'Hello World';
  }        
  `,
        },
      ],
      type: "Normal",
    },
    {
      question: "What is a module?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Modules are logical boundaries in your application and the application is divided into separate modules to separate the functionality of your application. Lets take an example of app.module.ts root module declared with @NgModule decorator as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { NgModule }      from '@angular/core';
  import { BrowserModule } from '@angular/platform-browser';
  import { AppComponent }  from './app.component';
  
  @NgModule ({
      imports:      [ BrowserModule ],
      declarations: [ AppComponent ],
      bootstrap:    [ AppComponent ],
      providers: []
  })
  export class AppModule { }        
  `,
        },
        {
          htmlElement: "div",
          value:
            "<span> The NgModule decorator has five important (among all) options: </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The imports option is used to import other dependent modules. The BrowserModule is required by default for any web based angular application.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> The declarations option is used to define components in the respective module. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The bootstrap option tells Angular which Component to bootstrap in the application.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> The providers option is used to configure a set of injectable objects that are available in the injector of this module. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> The entryComponents option is a set of components dynamically loaded into the view. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are lifecycle hooks available?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular application goes through an entire set of processes or has a lifecycle right from its initiation to the end of the application. The representation of lifecycle in pictorial representation as follows,</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>The description of each lifecycle method is as below,  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>ngOnChanges: When the value of a data bound property changes, then this method is called.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> ngOnInit: This is called whenever the initialization of the directive/component after Angular first displays the data-bound properties happens. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>ngDoCheck: This is for the detection and to act on changes that Angular can't or won't detect on its own.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>ngAfterContentInit: This is called in response after Angular projects external content into the component's view  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>ngAfterContentChecked: This is called in response after Angular checks the content projected into the component.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>ngAfterViewInit: This is called in response after Angular initializes the component's views and child views.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>ngAfterViewChecked: This is called in response after Angular checks the component's views and child views.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>ngOnDestroy: This is the cleanup phase just before Angular destroys the directive/component.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is a data binding?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Data binding is a core concept in Angular and allows to define communication between a component and the DOM, making it very easy to define interactive applications without worrying about pushing and pulling data. There are four forms of data binding(divided as 3 categories) which differ in the way the data is flowing.</span>",
        },
        {
          htmlElement: "SubHeader",
          value: "<span>From the Component to the DOM:  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Interpolation: {{ value }}: Adds the value of a property from the component</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <li>Name: {{ user.name }}</li>
  <li>Address: {{ user.address }}</li>
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Property binding: [property]=”value”: The value is passed from the component to the specified property or simple HTML attribute</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <input type="email" [value]="user.email">        
          `,
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span>From the DOM to the Component: Event binding: (event)=”function”:</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>When a specific DOM event happens (eg.: click, change, keyup), call the specified method in the component</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <button (click)="logout()"></button>        
          `,
        },
        {
          htmlElement: "SubHeader",
          value: "<span>Two-way binding: Two-way data binding:</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> [(ngModel)]=”value”: Two-way data binding allows to have the data flow both ways. For example, in the below code snippet, both the email DOM input and component email property are in sync</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <input type="email" [(ngModel)]="user.email">       
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "What is metadata?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Metadata is used to decorate a class so that it can configure the expected behavior of the class. The metadata is represented by decorators</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Class decorators, e.g. @Component and @NgModule </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { NgModule, Component } from '@angular/core';

  @Component({
    selector: 'my-component',
    template: '<div>Class decorator</div>',
  })
  export class MyComponent {
    constructor() {
      console.log('Hey I am a component!');
    }
  }

  @NgModule({
    imports: [],
    declarations: [],
  })
  export class MyModule {
    constructor() {
      console.log('Hey I am a module!');
    }
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Property decorators Used for properties inside classes, e.g. @Input and @Output  </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Component, Input } from '@angular/core';

  @Component({
      selector: 'my-component',
      template: '<div>Property decorator</div>'
  })
  
  export class MyComponent {
      @Input()
      title: string;
  }        
  `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Method decorators Used for methods inside classes, e.g. @HostListener  </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Component, HostListener } from '@angular/core';

  @Component({
      selector: 'my-component',
      template: '<div>Method decorator</div>'
  })
  export class MyComponent {
      @HostListener('click', ['$event'])
      onHostClick(event: Event) {
          // clicked, 'event' available
      }
  }        
  `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Parameter decorators Used for parameters inside class constructors, e.g. @Inject, @Optional  </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Component, Inject } from '@angular/core';
  import { MyService } from './my-service';
  
  @Component({
      selector: 'my-component',
      template: '<div>Parameter decorator</div>'
  })
  export class MyComponent {
      constructor(@Inject(MyService) myService) {
          console.log(myService); // MyService
      }
  }        
  `,
        },
      ],
      type: "Normal",
    },
    {
      question: " What is angular CLI?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular CLI(Command Line Interface) is a command line interface to scaffold and build angular apps using nodejs style (commonJs) modules. You need to install using below npm command,</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  npm install @angular/cli@latest        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of few commands, which will come handy while creating angular projects            </span>",
        },
        {
          htmlElement: "Li",
          value: "<span>Creating New Project: ng new  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Generating Components, Directives & Services: ng generate/g The different types of commands would be,  </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  ng generate class my-new-class: add a class to your application
  ng generate component my-new-component: add a component to your application
  ng generate directive my-new-directive: add a directive to your application
  ng generate enum my-new-enum: add an enum to your application
  ng generate module my-new-module: add a module to your application
  ng generate pipe my-new-pipe: add a pipe to your application
  ng generate service my-new-service: add a service to your application        
          `,
        },
        {
          htmlElement: "Li",
          value: "<span>Running the Project: ng serve  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the difference between constructor and ngOnInit?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Constructor is a default method of the class that is executed when the class is instantiated and ensures proper initialisation of fields in the class and its subclasses. Angular, or better Dependency Injector (DI), analyses the constructor parameters and when it creates a new instance by calling new MyClass() it tries to find providers that match the types of the constructor parameters, resolves them and passes them to the constructor.ngOnInit is a life cycle hook called by Angular to indicate that Angular is done creating the component.Mostly we use ngOnInit for all the initialization/declaration and avoid stuff to work in the constructor. The constructor should only be used to initialize class members but shouldn't do actual 'work'. So you should use constructor() to setup Dependency Injection and not much else. ngOnInit() is better place to 'start' - it's where/when components' bindings are resolved.</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  export class App implements OnInit{
    constructor(private myService: MyService){
        //called first time before the ngOnInit()
    }
  
    ngOnInit(){
        //called after the constructor and called  after the first ngOnChanges()
        //e.g. http call...
    }
  }        
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "What is a service?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A service is used when a common functionality needs to be provided to various modules. Services allow for greater separation of concerns for your application and better modularity by allowing you to extract common functionality out of components.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's create a repoService which can be used across components,  </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Injectable } from '@angular/core';
  import { Http } from '@angular/http';
  
  @Injectable({ // The Injectable decorator is required for dependency injection to work
    // providedIn option registers the service with a specific NgModule
    providedIn: 'root',  // This declares the service with the root app (AppModule)
  })
  export class RepoService{
    constructor(private http: Http){
    }
  
    fetchAll(){
      return this.http.get('https://api.github.com/repositories');
    }
  }        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> The above service uses Http service as a dependency. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is dependency injection in Angular?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Dependency injection (DI), is an important application design pattern in which a class asks for dependencies from external sources rather than creating them itself. Angular comes with its own dependency injection framework for resolving dependencies( services or objects that a class needs to perform its function).So you can have your services depend on other services throughout your application.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How is Dependency Hierarchy formed?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Injectors in Angular have rules that can be leveraged to achieve the desired visibility of injectables in your applications. By understanding these rules, you can determine in which NgModule, Component, or Directive you should declare a provider.</span>",
        },
        {
          htmlElement: "SubHeader",
          value: "<span> Module injector </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>When angular starts, it creates a root injector where the services will be registered, these are provided via injectable annotation. All services provided in the ng-model property are called providers (if those modules are not lazy-loaded).  </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Angular recursively goes through all models which are being used in the application and creates instances for provided services in the root injector. If you provide some service in an eagerly-loaded model, the service will be added to the root injector, which makes it available across the whole application. </span>",
        },
        {
          htmlElement: "SubHeader",
          value: "<span>Platform Module  </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>During application bootstrapping angular creates a few more injectors, above the root injector goes the platform injector, this one is created by the platform browser dynamic function inside the main.ts file, and it provides some platform-specific features like DomSanitizer.  </span>",
        },
        {
          htmlElement: "SubHeader",
          value: "<span> NullInjector() </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> At the very top, the next parent injector in the hierarchy is the NullInjector().The responsibility of this injector is to throw the error if something tries to find dependencies there, unless you've used @Optional() because ultimately, everything ends at the NullInjector() and it returns an error or, in the case of @Optional(), null. </span>",
        },
        {
          htmlElement: "SubHeader",
          value: "<span> ElementInjector </span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Angular creates ElementInjector hierarchies implicitly for each DOM element. ElementInjector injector is being created for any tag that matches the angular component, or any tag on which directive is applied, and you can configure it in component and directive annotations inside the provider's property, thus, it creates its own hierarchy likewise the upper one. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of async pipe?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The AsyncPipe subscribes to an observable or promise and returns the latest value it has emitted. When a new value is emitted, the pipe marks the component to be checked for changes.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take a time observable which continuously updates the view for every 2 seconds with the current time. </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
    @Component({
      selector: 'async-observable-pipe',
      template: '<div><code>observable|async</code>:
            Time: {{ time | async }}</div>'
    })
    export class AsyncObservablePipeComponent {
      time: Observable<string>;
      constructor() {
        this.time = new Observable((observer) => {
          setInterval(() => {
            observer.next(new Date().toString());
          }, 2000);
        });
      }
    }    
          `,
        },
      ],
      type: "Normal",
    },
    {
      question:
        " What is the option to choose between inline and external template file?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can store your component's template in one of two places. You can define it inline using the template property, or you can define the template in a separate HTML file and link to it in the component metadata using the @Component decorator's templateUrl property.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> The choice between inline and separate HTML is a matter of taste, circumstances, and organization policy. But normally we use inline template for small portion of code and external template file for bigger views. By default, the Angular CLI generates components with a template file. But you can override that with the below command, </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  ng generate component hero -it        
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of *ngFor directive?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>We use Angular *ngFor directive in the template to display each item in the list. For example, here we can iterate over a list of users:</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <li *ngFor="let user of users">
  {{ user }}
  </li>        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> The user variable in the *ngFor double-quoted instruction is a template input variable. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of *ngIf directive?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Sometimes an app needs to display a view or a portion of a view only under specific circumstances. The Angular *ngIf directive inserts or removes an element based on a truthy/falsy condition. Let's take an example to display a message if the user age is more than 18:</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <p *ngIf="user.age > 18">You are not eligible for student pass!</p>        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Note: Angular isn't showing and hiding the message. It is adding and removing the paragraph element from the DOM. That improves performance, especially in the larger projects with many data bindings. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What happens if you use script tag inside template?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular recognizes the value as unsafe and automatically sanitizes it, which removes the script tag but keeps safe content such as the text content of the script tag. This way it eliminates the risk of script injection attacks. If you still use it then it will be ignored and a warning appears in the browser console.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take an example of innerHtml property binding which causes XSS vulnerability,  </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  export class InnerHtmlBindingComponent {
    // For example, a user/attacker-controlled value from a URL.
    htmlSnippet = 'Template <script>alert("0wned")</script> <b>Syntax</b>';
  }        
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is interpolation?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Interpolation is a special syntax that Angular converts into property binding. It’s a convenient alternative to property binding. It is represented by double curly braces({{}}). The text between the braces is often the name of a component property. Angular replaces that name with the string value of the corresponding component property.</span>",
        },
        {
          htmlElement: "div",
          value: "<span>Let's take an example,  </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <h3>
    {{title}}
    <img src="{{url}}" style="height:30px">
  </h3>        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> In the example above, Angular evaluates the title and url properties and fills in the blanks, first displaying a bold application title and then a URL. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are template expressions?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A template expression produces a value similar to any Javascript expression. Angular executes the expression and assigns it to a property of a binding target; the target might be an HTML element, a component, or a directive. In the property binding, a template expression appears in quotes to the right of the = symbol as in [property]='expression'. In interpolation syntax, the template expression is surrounded by double curly braces. For example, in the below interpolation, the template expression is {{username}},</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <h3>{{username}}, welcome to Angular</h3>        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>The below javascript expressions are prohibited in template expression  </span>",
        },
        {
          htmlElement: "Li",
          value: "<span>assignments (=, +=, -=, ...)  </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> new </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> chaining expressions with ; or             </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> increment and decrement operators (++ and --) </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are template statements?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A template statement responds to an event raised by a binding target such as an element, component, or directive. The template statements appear in quotes to the right of the = symbol like (event)='statement'.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's take an example of button click event's statement </span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  <button (click)="editProfile()">Edit Profile</button>        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> In the above expression, editProfile is a template statement. The below JavaScript syntax expressions are not allowed. </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> new </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> increment and decrement operators, ++ and -- </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> operator assignment, such as += and -= </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> the bitwise operators | and & </span>",
        },
        {
          htmlElement: "Li",
          value: "<span>the template expression operators </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How do you categorize data binding types?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Binding types can be grouped into three categories distinguished by the direction of data flow. They are listed as below,</span>",
        },
        {
          htmlElement: "Li",
          value: "<span>From the source-to-view  </span>",
        },
        {
          htmlElement: "Li",
          value: "<span> From view-to-source </span>",
        },
        {
          htmlElement: "Li",
          value: "<span>View-to-source-to-view  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are pipes?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Pipes are simple functions that use template expressions to accept data as input and transform it into a desired output. For example, let us take a pipe to transform a component's birthday property into a human-friendly date using date pipe.</span>",
        },
        {
          htmlElement: "Syntax",
          value: `
  import { Component } from '@angular/core';

  @Component({
    selector: 'app-birthday',
    template: '<p>Birthday is {{ birthday | date }}</p>'
  })
  export class BirthdayComponent {
    birthday = new Date(1987, 6, 18); // June 18, 1987
  }        
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is a parameterized pipe?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A pipe can accept any number of optional parameters to fine-tune its output. The parameterized pipe can be created by declaring the pipe name with a colon ( : ) and then the parameter value. If the pipe accepts multiple parameters, separate the values with colons. Let's take a birthday example with a particular format(dd/MM/yyyy):</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Component } from '@angular/core';

  @Component({
    selector: 'app-birthday',
    template: '<p>Birthday is {{ birthday | date:'dd/MM/yyyy'}}</p>' // 18/06/1987
  })
  export class BirthdayComponent {
    birthday = new Date(1987, 6, 18);
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Note: The parameter value can be any valid template expression, such as a string literal or a component property. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "How do you chain pipes?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can chain pipes together in potentially useful combinations as per the needs. Let's take a birthday property which uses date pipe(along with parameter) and uppercase pipes as below</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Component } from '@angular/core';

  @Component({
    selector: 'app-birthday',
    template: '<p>Birthday is {{  birthday | date:'fullDate' | uppercase}} </p>' // THURSDAY, JUNE 18, 1987
  })
  export class BirthdayComponent {
    birthday = new Date(1987, 6, 18);
  }

          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is a custom pipe?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Apart from built-in pipes, you can write your own custom pipe with the below key characteristics:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> A pipe is a class decorated with pipe metadata @Pipe decorator, which you import from the core Angular library For example, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Pipe({name: 'myCustomPipe'})        
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>The pipe class implements the PipeTransform interface's transform method that accepts an input value followed by optional parameters and returns the transformed value. The structure of PipeTransform would be as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  interface PipeTransform {
    transform(value: any, ...args: any[]): any
  }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> The @Pipe decorator allows you to define the pipe name that you'll use within template expressions. It must be a valid JavaScript identifier. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  template: '{{someInputValue | myCustomPipe: someOtherValue}}'       
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "Give an example of custom pipe?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can create custom reusable pipes for the transformation of existing value. For example, let us create a custom pipe for finding file size based on an extension,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Pipe, PipeTransform } from '@angular/core';

  @Pipe({name: 'customFileSizePipe'})
  export class FileSizePipe implements PipeTransform {
    transform(size: number, extension: string = 'MB'): string {
      return (size / (1024 * 1024)).toFixed(2) + extension;
    }
  }  
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Now you can use the above pipe in template expression as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  template: '
    <h2>Find the size of a file</h2>
    <p>Size: {{288966 | customFileSizePipe: 'GB'}}</p>
    '        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the difference between pure and impure pipe?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A pure pipe is only called when Angular detects a change in the value or the parameters passed to a pipe. For example, any changes to a primitive input value (String, Number, Boolean, Symbol) or a changed object reference (Date, Array, Function, Object). An impure pipe is called for every change detection cycle no matter whether the value or parameters changes. i.e, An impure pipe is called often, as often as every keystroke or mouse-move.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What is a bootstrapping module?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span> Every application has at least one Angular module, the root module that you bootstrap to launch the application is called as bootstrapping module. It is commonly known as AppModule. The default structure of AppModule generated by AngularCLI would be as follows: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { BrowserModule } from '@angular/platform-browser';
  import { NgModule } from '@angular/core';
  import { FormsModule } from '@angular/forms';
  import { HttpClientModule } from '@angular/common/http';

  import { AppComponent } from './app.component';

  /* the AppModule class with the @NgModule decorator */
  @NgModule({
    declarations: [
      AppComponent
    ],
    imports: [
      BrowserModule,
      FormsModule,
      HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
  })
  export class AppModule { }        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are observables?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Observables are declarative which provide support for passing messages between publishers and subscribers in your application. They are mainly used for event handling, asynchronous programming, and handling multiple values. In this case, you define a function for publishing values, but it is not executed until a consumer subscribes to it. The subscribed consumer then receives notifications until the function completes, or until they unsubscribe.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What is HttpClient and its benefits?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Most of the Front-end applications communicate with backend services over HTTP protocol using either XMLHttpRequest interface or the fetch() API. Angular provides a simplified client HTTP API known as HttpClient which is based on top of XMLHttpRequest interface. This client is avaialble from @angular/common/http package. You can import in your root module as below:  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { HttpClientModule } from '@angular/common/http';
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>The major advantages of HttpClient can be listed as below,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Contains testability features</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Provides typed request and response objects</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Intercept request and response</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Supports Observalbe APIs</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Supports streamlined error handling</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Explain on how to use HttpClient with an example?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the steps need to be followed for the usage of HttpClient.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Import HttpClient into root module: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { HttpClientModule } from '@angular/common/http';
  @NgModule({
    imports: [
      BrowserModule,
      // import HttpClientModule after BrowserModule.
      HttpClientModule,
    ],
    ......
    })
    export class AppModule {}        
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Inject the HttpClient into the application: Let's create a userProfileService(userprofile.service.ts) as an example. It also defines get method of HttpClient: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Injectable } from '@angular/core';
  import { HttpClient } from '@angular/common/http';
  
  const userProfileUrl: string = 'assets/data/profile.json';
  
  @Injectable()
  export class UserProfileService {
    constructor(private http: HttpClient) { }
  
    getUserProfile() {
      return this.http.get(this.userProfileUrl);
    }
  }        
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Create a component for subscribing service: Let's create a component called UserProfileComponent(userprofile.component.ts), which injects UserProfileService and invokes the service method: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  fetchUserProfile() {
    this.userProfileService.getUserProfile()
      .subscribe((data: User) => this.user = {
          id: data['userId'],
          name: data['firstName'],
          city:  data['city']
      });
  }        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Since the above service method returns an Observable which needs to be subscribed in the component.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "How can you read full response?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The response body doesn't or may not return full response data because sometimes servers also return special headers or status code, which are important for the application workflow. In order to get the full response, you should use observe option from HttpClient:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  getUserResponse(): Observable<HttpResponse<User>> {
    return this.http.get<User>(
      this.userUrl, { observe: 'response' });
  }       
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Now HttpClient.get() method returns an Observable of typed HttpResponse rather than just the JSON data. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How do you perform Error handling?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If the request fails on the server or fails to reach the server due to network issues, then HttpClient will return an error object instead of a successful reponse. In this case, you need to handle in the component by passing error object as a second callback to subscribe() method.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's see how it can be handled in the component with an example,            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  fetchUser() {
    this.userService.getProfile()
      .subscribe(
        (data: User) => this.userProfile = { ...data }, // success path
        error => this.error = error // error path
      );
  }        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> It is always a good idea to give the user some meaningful feedback instead of displaying the raw error object returned from HttpClient. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is RxJS?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>RxJS is a library for composing asynchronous and callback-based code in a functional, reactive style using Observables. Many APIs such as HttpClient produce and consume RxJS Observables and also uses operators for processing observables.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, you can import observables and operators for using HttpClient as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Observable, throwError } from 'rxjs';
  import { catchError, retry } from 'rxjs/operators';        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is subscribing?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>An Observable instance begins publishing values only when someone subscribes to it. So you need to subscribe by calling the subscribe() method of the instance, passing an observer object to receive the notifications.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take an example of creating and subscribing to a simple observable, with an observer that logs the received message to the console.  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Creates an observable sequence of 5 integers, starting from 1
  const source = range(1, 5);

  // Create observer object
  const myObserver = {
    next: x => console.log('Observer got a next value: ' + x),
    error: err => console.error('Observer got an error: ' + err),
    complete: () => console.log('Observer got a complete notification'),
  };

  // Execute with the observer object and Prints out each item
  source.subscribe(myObserver);
  // => Observer got a next value: 1
  // => Observer got a next value: 2
  // => Observer got a next value: 3
  // => Observer got a next value: 4
  // => Observer got a next value: 5
  // => Observer got a complete notification
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is an observable?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>An Observable is a unique Object similar to a Promise that can help manage async code. Observables are not part of the JavaScript language so we need to rely on a popular Observable library called RxJS. The observables are created using new keyword.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let see the simple example of observable, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Observable } from 'rxjs';

  const observable = new Observable(observer => {
    setTimeout(() => {
      observer.next('Hello from a Observable!');
    }, 2000);
  });        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is an observer?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Observer is an interface for a consumer of push-based notifications delivered by an Observable. It has below structure,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  interface Observer<T> {
    closed?: boolean;
    next: (value: T) => void;
    error: (err: any) => void;
    complete: () => void;
  }        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>A handler that implements the Observer interface for receiving observable notifications will be passed as a parameter for observable as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  myObservable.subscribe(myObserver);        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Note: If you don't supply a handler for a notification type, the observer ignores notifications of that type. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the difference between promise and observable?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of differences between promise and observable:</span>",
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span> Observable </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Declarative: Computation does not start until subscription, so they can run whenever you need the result</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Provides multiple values over time</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Subscribe method is used for error handling that facilitates centralized and predictable error handling</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Provides chaining and subscription to handle complex applications</span>",
        },
        {
          htmlElement: "SubHeader",
          value:
            "<span> Promise </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Executes immediately on creation</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Provides only one</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Push errors to the child promises</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Uses only .then() clause</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is multicasting?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Multi-casting is the practice of broadcasting to a list of multiple subscribers in a single execution.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> Let's demonstrate the multi-casting feature: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  var source = Rx.Observable.from([1, 2, 3]);
  var subject = new Rx.Subject();
  var multicasted = source.multicast(subject);
  
  // These are, under the hood, 'subject.subscribe({...})':
  multicasted.subscribe({
    next: (v) => console.log('observerA: ' + v)
  });
  multicasted.subscribe({
    next: (v) => console.log('observerB: ' + v)
  });
  
  // This is, under the hood, s  
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   How do you perform error handling in observables? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can handle errors by specifying an error callback on the observer instead of relying on try/catch, which are ineffective in asynchronous environment.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> For example, you can define error callback as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  myObservable.subscribe({
    next(num) { console.log('Next num: ' + num)},
    error(err) { console.log('Received an errror: ' + err)}
  }); 
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the shorthand notation for subscribe method?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The subscribe() method can accept callback function definitions in line, for next, error, and complete handlers. It is known as shorthand notation or Subscribe method with positional arguments.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can define subscribe method as below,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  myObservable.subscribe(
    x => console.log('Observer got a next value: ' + x),
    err => console.error('Observer got an error: ' + err),
    () => console.log('Observer got a complete notification')
  );
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the utility functions provided by RxJS?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The RxJS library also provides below utility functions for creating and working with observables.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Converting existing code for async operations into observables </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Iterating through the values in a stream </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Mapping values to different types </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Filtering streams  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Composing multiple streams </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What are observable creation functions?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>RxJS provides creation functions for the process of creating observables from promises, events, timers and Ajax requests. Let us explain each of them with an example:        </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Create an observable from a promise  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { from } from 'rxjs'; // from function

  const data = from(fetch('/api/endpoint')); //Created from Promise
  data.subscribe({
  next(response) { console.log(response); },
  error(err) { console.error('Error: ' + err); },
  complete() { console.log('Completed'); }
  });
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Create an observable that creates an AJAX request </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { ajax } from 'rxjs/ajax'; // ajax function
  const apiData = ajax('/api/data'); // Created from AJAX request
  // Subscribe to create the request
  apiData.subscribe(res => console.log(res.status, res.response));         
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Create an observable from a counter  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { interval } from 'rxjs'; // interval function
  const secondsCounter = interval(1000); // Created from Counter value
  secondsCounter.subscribe(n =>
    console.log('Counter value: $ {n}'));        
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Create an observable from an event </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { fromEvent } from 'rxjs';
  const el = document.getElementById('custom-element');
  const mouseMoves = fromEvent(el, 'mousemove');
  const subscription = mouseMoves.subscribe((e: MouseEvent) => {
    console.log('Coordnitaes of mouse pointer: $ {e.clientX} * $ {e.clientY}');
    });        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What will happen if you do not supply handler for the observer?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Usually, an observer object can define any combination of next, error, and complete notification type handlers. If you don't supply a handler for a notification type, the observer just ignores notifications of that type.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are Angular elements?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular elements are Angular components packaged as custom elements (a web standard for defining new HTML elements in a framework-agnostic way). Angular Elements host an Angular component, providing a bridge between the data and the logic defined in the component and the standard DOM APIs, thus, providing a way to use Angular components in non-Angular environments.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are custom elements?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Custom elements (or Web Components) are a Web Platform feature which extends HTML by allowing you to define a tag whose content is created and controlled by JavaScript code. The browser maintains a CustomElementRegistry of defined custom elements, which maps an instantiable JavaScript class to an HTML tag. Currently this feature is supported by Chrome, Firefox, Opera, and Safari, and available in other browsers through polyfills.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  Do I need to bootstrap custom elements?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, custom elements bootstrap (or start) automatically when they are added to the DOM, and are automatically destroyed when removed from the DOM. Once a custom element is added to the DOM for any page, it looks and behaves like any other HTML element, and does not require any special knowledge of Angular.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Explain how custom elements works internally?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the steps in an order about custom elements functionality,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>App registers custom element with browser: Use the createCustomElement() function to convert a component into a class that can be registered with the browser as a custom element.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>App adds custom element to DOM: Add custom element just like a built-in HTML element directly into the DOM.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Browser instantiate component based class: Browser creates an instance of the registered class and adds it to the DOM. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Instance provides content with data binding and change detection: The content with in template is rendered using the component and DOM data. The flow chart of the custom elements functionality would be as follows, </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How to transfer components to custom elements?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Transforming components to custom elements involves two major steps,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Build custom element class: Angular provides the createCustomElement() function for converting an Angular component (along with its dependencies) to a custom element. The conversion process implements NgElementConstructor interface, and creates a constructor class which is used to produce a self-bootstrapping instance of Angular component.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Register element class with browser: It uses customElements.define() JS function, to register the configured constructor and its associated custom-element tag with the browser's CustomElementRegistry. When the browser encounters the tag for the registered element, it uses the constructor to create a custom-element instance. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the mapping rules between Angular component and custom element?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Component properties and logic maps directly into HTML attributes and the browser's event system. Let us describe them in two steps,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The createCustomElement() API parses the component input properties with corresponding attributes for the custom element. For example, component @Input('myInputProp') converted as custom element attribute my-input-prop.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> The Component outputs are dispatched as HTML Custom Events, with the name of the custom event matching the output name. For example, component @Output() valueChanged = new EventEmitter() converted as custom element with dispatch event as 'valueChanged'. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How do you define typings for custom elements?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use the NgElement and WithProperties types exported from @angular/elements.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's see how it can be applied by comparing with Angular component</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> The simple container with input property would be as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component(...)
  class MyContainer {
    @Input() message: string;
  }        
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> After applying types typescript validates input value and their types, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const container = document.createElement('my-container') as NgElement & WithProperties<{message: string}>;
  container.message = 'Welcome to Angular elements!';
  container.message = true;  // <-- ERROR: TypeScript knows this should be a string.
  container.greet = 'News';  // <-- ERROR: TypeScript knows there is no 'greet' property on 'container'.
            `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are dynamic components?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Dynamic components are the components in which the component's location in the application is not defined at build time i.e. they are not used in any angular template. Instead, the component is instantiated and placed in the application at runtime.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What are the various kinds of directives?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>There are mainly three kinds of directives:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Components — These are directives with a template. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Structural directives — These directives change the DOM layout by adding and removing DOM elements.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Attribute directives — These directives change the appearance or behavior of an element, component, or another directive. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you create directives using CLI?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use CLI command ng generate directive to create the directive class file. It creates the source file(src/app/components/directivename.directive.ts), the respective test file .spec.ts and declare the directive class file in root module.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Give an example for attribute directives?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Let's take simple highlighter behavior as a example directive for DOM element. You can create and apply the attribute directive using below step:</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Create HighlightDirective class with the file name src/app/highlight.directive.ts. In this file, we need to import Directive from core library to apply the metadata and ElementRef in the directive's constructor to inject a reference to the host DOM element , </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Directive, ElementRef } from '@angular/core';

  @Directive({
    selector: '[appHighlight]'
  })
  export class HighlightDirective {
      constructor(el: ElementRef) {
          el.nativeElement.style.backgroundColor = 'red';
      }
  }        
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Apply the attribute directive as an attribute to the host element(for example,)</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <p appHighlight>Highlight me!</p>       
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Run the application to see the highlight behavior on paragraph element </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng serve        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is Angular Router?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular Router is a mechanism in which navigation happens from one view to the next as users perform application tasks. It borrows the concepts or model of browser's application navigation. It enables developers to build Single Page Applications with multiple views and allow navigation between these views.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is the purpose of base href tag? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The routing application should add element to the index.html as the first child in the tag in order to indicate how to compose navigation URLs. If app folder is the application root then you can set the href value as below</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <base href="/">       
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the router imports?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Angular Router which represents a particular component view for a given URL is not part of Angular Core. It is available in library named @angular/router to import required router components. For example, we import them in app module as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { RouterModule, Routes } from '@angular/router';       
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is router outlet?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The RouterOutlet is a directive from the router library and it acts as a placeholder that marks the spot in the template where the router should display the components for that outlet. Router outlet is used like a component,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <router-outlet></router-outlet>
  <!-- Routed components go here -->
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are router links?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The RouterLink is a directive on the anchor tags give the router control over those elements. Since the navigation paths are fixed, you can assign string values to router-link directive as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <h1>Angular Router</h1>
  <nav>
    <a routerLink="/todosList" >List of todos</a>
    <a routerLink="/completed" >Completed todos</a>
  </nav>
  <router-outlet></router-outlet>      
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are active router links?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>RouterLinkActive is a directive that toggles css classes for active RouterLink bindings based on the current RouterState. i.e, The Router will add CSS classes when this link is active and remove when the link is inactive. For example, you can add them to RouterLinks as below.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <h1>Angular Router</h1>
  <nav>
    <a routerLink="/todosList" routerLinkActive="active">List of todos</a>
    <a routerLink="/completed" routerLinkActive="active">Completed todos</a>
  </nav>
  <router-outlet></router-outlet>        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is router state?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>RouterState is a tree of activated routes. Every node in this tree knows about the 'consumed' URL segments, the extracted parameters, and the resolved data. You can access the current RouterState from anywhere in the application using the Router service and the routerState property.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({templateUrl:'template.html'})
  class MyComponent {
    constructor(router: Router) {
      const state: RouterState = router.routerState;
      const root: ActivatedRoute = state.root;
      const child = root.firstChild;
      const id: Observable<string> = child.params.map(p => p.id);
      //...
    }
  }       
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are router events?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>During each navigation, the Router emits navigation events through the Router.events property allowing you to track the lifecycle of the route.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>The sequence of router events is as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  NavigationStart,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  RoutesRecognized,
  GuardsCheckStart,
  ChildActivationStart,
  ActivationStart,
  GuardsCheckEnd,
  ResolveStart,
  ResolveEnd,
  ActivationEnd
  ChildActivationEnd
  NavigationEnd,
  NavigationCancel,
  NavigationError
  Scroll
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is activated route?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>ActivatedRoute contains the information about a route associated with a component loaded in an outlet. It can also be used to traverse the router state tree. The ActivatedRoute will be injected as a router service to access the information. In the below example, you can access route path and parameters,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({...})
  class MyComponent {
    constructor(route: ActivatedRoute) {
      const id: Observable<string> = route.params.pipe(map(p => p.id));
      const url: Observable<string> = route.url.pipe(map(segments => segments.join('')));
      // route.data includes both 'data' and 'resolve'
      const user = route.data.pipe(map(d => d.user));
    }
  }        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you define routes?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A router must be configured with a list of route definitions. You configures the router with routes via the RouterModule.forRoot() method, and adds the result to the AppModule's imports array.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const appRoutes: Routes = [
    { path: 'todo/:id',      component: TodoDetailComponent },
    {
      path: 'todos',
      component: TodosListComponent,
      data: { title: 'Todos List' }
    },
    { path: '',
      redirectTo: '/todos',
      pathMatch: 'full'
    },
    { path: '**', component: PageNotFoundComponent }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forRoot(
        appRoutes,
        { enableTracing: true } // <-- debugging purposes only
      )
      // other imports here
    ],
    ...
  })
  export class AppModule { }       
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of Wildcard route?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If the URL doesn't match any predefined routes then it causes the router to throw an error and crash the app. In this case, you can use wildcard route. A wildcard route has a path consisting of two asterisks to match every URL.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can define PageNotFoundComponent for wildcard route as below            </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  { path: '**', component: PageNotFoundComponent }        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Do I need a Routing Module always?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, the Routing Module is a design choice. You can skip routing Module (for example, AppRoutingModule) when the configuration is simple and merge the routing configuration directly into the companion module (for example, AppModule). But it is recommended when the configuration is complex and includes specialized guard and resolver services.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is Angular Universal?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular Universal is a server-side rendering module for Angular applications in various scenarios. This is a community driven project and available under @angular/platform-server package. Recently Angular Universal is integrated with Angular CLI.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are different types of compilation in Angular?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular offers two ways to compile your application,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Just-in-Time (JIT)</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Ahead-of-Time (AOT)</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is JIT?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Just-in-Time (JIT) is a type of compilation that compiles your app in the browser at runtime. JIT compilation was the default until Angular 8, now default is AOT. When you run the ng build (build only) or ng serve (build and serve locally) CLI commands, the type of compilation (JIT or AOT) depends on the value of the aot property in your build configuration specified in angular.json. By default, aot is set to true.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is AOT?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Ahead-of-Time (AOT) is a type of compilation that compiles your app at build time. This is the default starting in Angular 9. When you run the ng build (build only) or ng serve (build and serve locally) CLI commands, the type of compilation (JIT or AOT) depends on the value of the aot property in your build configuration specified in angular.json. By default, aot is set to true.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng build
  ng serve        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Why do we need compilation process?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Angular components and templates cannot be understood by the browser directly. Due to that Angular applications require a compilation process before they can run in a browser. For example, In AOT compilation, both Angular HTML and TypeScript code converted into efficient JavaScript code during the build phase before browser runs it.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the advantages with AOT?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of AOT benefits,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Faster rendering: The browser downloads a pre-compiled version of the application. So it can render the application immediately without compiling the app.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Fewer asynchronous requests: It inlines external HTML templates and CSS style sheets within the application javascript which eliminates separate ajax requests. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Smaller Angular framework download size: Doesn't require downloading the Angular compiler. Hence it dramatically reduces the application payload.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Detect template errors earlier: Detects and reports template binding errors during the build step itself </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Better security: It compiles HTML templates and components into JavaScript. So there won't be any injection attacks. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What are the ways to control AOT compilation?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can control your app compilation in two ways,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>By providing template compiler options in the tsconfig.json file  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> By configuring Angular metadata with decorators </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the restrictions of metadata?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Angular, You must write metadata with the following general constraints,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Write expression syntax with in the supported range of javascript features</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> The compiler can only reference symbols which are exported </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Only call the functions supported by the compiler </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Decorated and data-bound class members must be public. </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the three phases of AOT?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The AOT compiler works in three phases,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Code Analysis: The compiler records a representation of the source </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Code generation: It handles the interpretation as well as places restrictions on what it interprets. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Validation: In this phase, the Angular template compiler uses the TypeScript compiler to validate the binding expressions in templates.  </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  Can I use arrow functions in AOT?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, Arrow functions or lambda functions can’t be used to assign values to the decorator properties. For example, the following snippet is invalid:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({
    providers: [{
      provide: MyService, useFactory: () => getService()
    }]
  })      
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>To fix this, it has to be changed as following exported function:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  function getService(){
    return new MyService();
  }
  
  @Component({
    providers: [{
      provide: MyService, useFactory: getService
    }]
  })        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>If you still use arrow function, it generates an error node in place of the function. When the compiler later interprets this node, it reports an error to turn the arrow function into an exported function. Note: From Angular5 onwards, the compiler automatically performs this rewriting while emitting the .js file.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of metadata json files?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The metadata.json file can be treated as a diagram of the overall structure of a decorator's metadata, represented as an abstract syntax tree(AST). During the analysis phase, the AOT collector scan the metadata recorded in the Angular decorators and outputs metadata information in .metadata.json files, one per .d.ts file.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Can I use any javascript feature for expression syntax in AOT?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No, the AOT collector understands a subset of (or limited) JavaScript features. If an expression uses unsupported syntax, the collector writes an error node to the .metadata.json file. Later point of time, the compiler reports an error if it needs that piece of metadata to generate the application code.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is folding?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The compiler can only resolve references to exported symbols in the metadata. Where as some of the non-exported members are folded while generating the code. i.e Folding is a process in which the collector evaluate an expression during collection and record the result in the .metadata.json instead of the original expression. For example, the compiler couldn't refer selector reference because it is not exported</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  let selector = 'app-root';
  @Component({
    selector: selector
  })        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Will be folded into inline selector</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({
    selector: 'app-root'
  })
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Remember that the compiler can’t fold everything. For example, spread operator on arrays, objects created using new keywords and function calls.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "   What are macros? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The AOT compiler supports macros in the form of functions or static methods that return an expression in a single return expression. For example, let us take a below macro function,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  export function wrapInArray<T>(value: T): T[] {
    return [value];
  }        
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can use it inside metadata as an expression,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @NgModule({
    declarations: wrapInArray(TypicalComponent)
  })
  export class TypicalModule {}        
  `,
        },
        {
          htmlElement: "div",
          value:
            "<span>The compiler treats the macro expression as it written directly</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @NgModule({
    declarations: [TypicalComponent]
  })
  export class TypicalModule {}       
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Give an example of few metadata errors?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are some of the errors encountered in metadata,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Expression form not supported: Some of the language features outside of the compiler's restricted expression syntax used in angular metadata can produce this error. Let's see some of these examples,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  1. export class User { ... }
    const prop = typeof User; // typeof is not valid in metadata
  2. { provide: 'token', useValue: { [prop]: 'value' } }; // bracket notation is not valid in metadata        
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Reference to a local (non-exported) symbol: The compiler encountered a referenced to a locally defined symbol that either wasn't exported or wasn't initialized. Let's take example of this error, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // ERROR
  let username: string; // neither exported nor initialized
  
  @Component({
    selector: 'my-component',
    template: ... ,
    providers: [
      { provide: User, useValue: username }
    ]
  })
  export class MyComponent {} 
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can fix this by either exporting or initializing the value,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  export let username: string; // exported
  (or)
  let username = 'John'; // initialized 
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Function calls are not supported: The compiler does not currently support function expressions or lambda functions. For example, you cannot set a provider's useFactory to an anonymous function or arrow function as below. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  providers: [
    { provide: MyStrategy, useFactory: function() { ... } },
    { provide: OtherStrategy, useFactory: () => { ... } }
  ]
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can fix this with exported function</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  export function myStrategy() { ... }
  export function otherStrategy() { ... }
  ... // metadata
  providers: [
  { provide: MyStrategy, useFactory: myStrategy },
  { provide: OtherStrategy, useFactory: otherStrategy },
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Destructured variable or constant not supported: The compiler does not support references to variables assigned by destructuring. For example, you cannot write something like this: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { user } from './user';

  // destructured assignment to name and age
  const {name, age} = user;
  ... //metadata
  providers: [
    {provide: Name, useValue: name},
    {provide: Age, useValue: age},
  ]
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can fix this by non-destructured values</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { user } from './user';
  ... //metadata
  providers: [
      {provide: Name, useValue: user.name},
      {provide: Age, useValue: user.age},
    ] 
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is metadata rewriting?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Metadata rewriting is the process in which the compiler converts the expression initializing the fields such as useClass, useValue, useFactory, and data into an exported variable, which replaces the expression. Remember that the compiler does this rewriting during the emit of the .js file but not in definition files( .d.ts file).</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you provide configuration inheritance?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular Compiler supports configuration inheritance through extends in the tsconfig.json on angularCompilerOptions. i.e, The configuration from the base file(for example, tsconfig.base.json) are loaded first, then overridden by those in the inheriting config file.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "extends": "../tsconfig.base.json",
    "compilerOptions": {
      "experimentalDecorators": true,
      ...
    },
    "angularCompilerOptions": {
      "fullTemplateTypeCheck": true,
      "preserveWhitespaces": true,
      ...
    }
  }        
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you specify angular template compiler options?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The angular template compiler options are specified as members of the angularCompilerOptions object in the tsconfig.json file. These options will be specified adjecent to typescript compiler options.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "compilerOptions": {
      "experimentalDecorators": true,
                ...
    },
    "angularCompilerOptions": {
      "fullTemplateTypeCheck": true,
      "preserveWhitespaces": true,
                ...
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   How do you enable binding expression validation? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can enable binding expression validation explicitly by adding the compiler option fullTemplateTypeCheck in the 'angularCompilerOptions' of the project's tsconfig.json. It produces error messages when a type error is detected in a template binding expression.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, consider the following component:  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({
    selector: 'my-component',
    template: '{{user.contacts.email}}'
  })
  class MyComponent {
    user?: User;
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> This will produce the following error: </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  my.component.ts.MyComponent.html(1,1): : Property 'contacts' does not exist on type 'User'. Did you mean 'contact'?
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of any type cast function?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can disable binding expression type checking using $any() type cast function(by surrounding the expression). In the following example, the error Property contacts does not exist is suppressed by casting user to the any type.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  template:
  '{{ $any(user).contacts.email }}'
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>The $any() cast function also works with this to allow access to undeclared members of the component.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  template:
  '{{ $any(this).contacts.email }}'
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is Non null type assertion operator?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use the non-null type assertion operator to suppress the Object is possibly 'undefined' error. In the following example, the user and contact properties are always set together, implying that contact is always non-null if user is non-null. The error is suppressed in the example by using contact!.email.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({
    selector: 'my-component',
    template: '<span *ngIf="user"> {{user.name}} contacted through {{contact!.email}} </span>'
  })
  class MyComponent {
    user?: User;
    contact?: Contact;
  
    setData(user: User, contact: Contact) {
      this.user = user;
      this.contact = contact;
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is type narrowing? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The expression used in an ngIf directive is used to narrow type unions in the Angular template compiler similar to if expression in typescript. So *ngIf allows the typeScript compiler to infer that the data used in the binding expression will never be undefined.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({
    selector: 'my-component',
    template: '<span *ngIf="user"> {{user.contact.email}} </span>'
  })
  class MyComponent {
    user?: User;
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you describe various dependencies in angular application?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The dependencies section of package.json with in an angular application can be divided as follow,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Angular packages: Angular core and optional modules; their package names begin @angular/.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Support packages: Third-party libraries that must be present for Angular apps to run.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Polyfill packages: Polyfills plug gaps in a browser's JavaScript implementation. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "What is zone?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A Zone is an execution context that persists across async tasks. Angular relies on zone.js to run Angular's change detection processes when native JavaScript operations raise events</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of common module?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The commonly-needed services, pipes, and directives provided by @angular/common module. Apart from these HttpClientModule is available under @angular/common/http.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What is codelyzer?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Codelyzer provides set of tslint rules for static code analysis of Angular TypeScript projects. You can run the static code analyzer over web apps, NativeScript, Ionic etc. Angular CLI has support for this and it can be use as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng new codelyzer
  ng lint
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is angular animation? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular's animation system is built on CSS functionality in order to animate any property that the browser considers animatable. These properties includes positions, sizes, transforms, colors, borders etc. The Angular modules for animations are @angular/animations and @angular/platform-browser and these dependencies are automatically added to your project when you create a project using Angular CLI.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "What are the steps to use animation module?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You need to follow below steps to implement animation in your angular project,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Enabling the animations module: Import BrowserAnimationsModule to add animation capabilities into your Angular root application module(for example, src/app/app.module.ts). </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { NgModule } from '@angular/core';
  import { BrowserModule } from '@angular/platform-browser';
  import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

  @NgModule({
    imports: [
      BrowserModule,
      BrowserAnimationsModule
    ],
    declarations: [ ],
    bootstrap: [ ]
  })
  export class AppModule { }
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Importing animation functions into component files: Import required animation functions from @angular/animations in component files(for example, src/app/app.component.ts).  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import {
    trigger,
    state,
    style,
    animate,
    transition,
    // ...
  } from '@angular/animations';
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Adding the animation metadata property: add a metadata property called animations: within the @Component() decorator in component files(for example, src/app/app.component.ts) </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css'],
    animations: [
      // animation triggers go here
    ]
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is State function?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular's state() function is used to define different states to call at the end of each transition. This function takes two arguments: a unique name like open or closed and a style() function.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can write a open state function  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  state('open', style({
    height: '300px',
    opacity: 0.5,
    backgroundColor: 'blue'
  })),
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is Style function?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The style function is used to define a set of styles to associate with a given state name. You need to use it along with state() function to set CSS style attributes. For example, in the close state, the button has a height of 100 pixels, an opacity of 0.8, and a background color of green.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  state('close', style({
    height: '100px',
    opacity: 0.8,
    backgroundColor: 'green'
  })),
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Note: The style attributes must be in camelCase. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of animate function?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular Animations are a powerful way to implement sophisticated and compelling animations for your Angular single page web application.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Component, OnInit, Input } from '@angular/core';
  import { trigger, state, style, animate, transition } from '@angular/animations';

  @Component({
  selector: 'app-animate',
  templateUrl: '<div [@changeState]="currentState" class="myblock mx-auto"></div>',
  styleUrls: '.myblock {
      background-color: green;
      width: 300px;
      height: 250px;
      border-radius: 5px;
      margin: 5rem;
      }',
  animations: [
      trigger('changeState', [
      state('state1', style({
          backgroundColor: 'green',
          transform: 'scale(1)'
      })),
      state('state2', style({
          backgroundColor: 'red',
          transform: 'scale(1.5)'
      })),
      transition('*=>state1', animate('300ms')),
      transition('*=>state2', animate('2000ms'))
      ])
  ]
  })
  export class AnimateComponent implements OnInit {

      @Input() currentState;

      constructor() { }

      ngOnInit() {
      }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What is transition function?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The animation transition function is used to specify the changes that occur between one state and another over a period of time. It accepts two arguments: the first argument accepts an expression that defines the direction between two transition states, and the second argument accepts an animate() function.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take an example state transition from open to closed with an half second transition between states.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  transition('open => closed', [
    animate('500ms')
  ]),
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "How to inject the dynamic script in angular?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Using DomSanitizer we can inject the dynamic Html,Style,Script,Url.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Component, OnInit } from '@angular/core';
  import { DomSanitizer } from '@angular/platform-browser';
  @Component({
    selector: 'my-app',
    template: '
        <div [innerHtml]="htmlSnippet"></div>
    ',
  })
  export class App {
        constructor(protected sanitizer: DomSanitizer) {}
        htmlSnippet: string = this.sanitizer.bypassSecurityTrustScript("<script>safeCode()</script>");
    }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is a service worker and its role in Angular?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A service worker is a script that runs in the web browser and manages caching for an application. Starting from 5.0.0 version, Angular ships with a service worker implementation. Angular service worker is designed to optimize the end user experience of using an application over a slow or unreliable network connection, while also minimizing the risks of serving outdated content.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the design goals of service workers?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of design goals of Angular's service workers,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> It caches an application just like installing a native application </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> A running application continues to run with the same version of all files without any incompatible files </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> When you refresh the application, it loads the latest fully cached version </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> When changes are published then it immediately updates in the background </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Service workers saves the bandwidth by downloading the resources only when they changed. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is Angular Ivy?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular Ivy is a new rendering engine for Angular. You can choose to opt in a preview version of Ivy from Angular version 8.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> You can enable ivy in a new project by using the --enable-ivy flag with the ng new command </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng new ivy-demo-app --enable-ivy
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> You can add it to an existing project by adding enableIvy option in the angularCompilerOptions in your project's tsconfig.app.json. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "compilerOptions": { ... },
    "angularCompilerOptions": {
      "enableIvy": true
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the features included in ivy preview?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can expect below features with Ivy preview,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Generated code that is easier to read and debug at runtime </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Faster re-build time </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Improved payload size</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Improved template type checking  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Can I use AOT compilation with Ivy?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, it is a recommended configuration. Also, AOT compilation with Ivy is faster. So you need set the default build options(with in angular.json) for your project to always use AOT compilation.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {
    "projects": {
      "my-project": {
        "architect": {
          "build": {
            "options": {
              ...
              "aot": true,
            }
          }
        }
      }
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is Angular Language Service?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Angular Language Service is a way to get completions, errors, hints, and navigation inside your Angular templates whether they are external in an HTML file or embedded in annotations/decorators in a string. It has the ability to autodetect that you are opening an Angular file, reads your tsconfig.json file, finds all the templates you have in your application, and then provides all the language services.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you install angular language service in the project?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can install Angular Language Service in your project with the following npm command,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install --save-dev @angular/language-service
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>After that add the following to the 'compilerOptions' section of your project's tsconfig.json</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  "plugins": [
    {"name": "@angular/language-service"}
  ]
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: The completion and diagnostic services works for .ts files only. You need to use custom plugins for supporting HTML files.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Is there any editor support for Angular Language Service?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, Angular Language Service is currently available for Visual Studio Code and WebStorm IDEs. You need to install angular language service using an extension and devDependency respectively. In sublime editor, you need to install typescript which has has a language service plugin model.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you add web workers in your application?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can add web worker anywhere in your application. For example, If the file that contains your expensive computation is src/app/app.component.ts, you can add a Web Worker using ng generate web-worker app command which will create src/app/app.worker.ts web worker file. This command will perform below actions,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Configure your project to use Web Workers  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Adds app.worker.ts to receive messages</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  addEventListener('message', ({ data }) => {
    const response = 'worker response to $ {data}';
    postMessage(response);
  });
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> The component app.component.ts file updated with web worker file </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  if (typeof Worker !== 'undefined') {
    // Create a new
    const worker = new Worker('./app.worker', { type: 'module' });
    worker.onmessage = ({ data }) => {
      console.log('page got message: $\{data\}');
    };
    worker.postMessage('hello');
  } else {
    // Web Workers are not supported in this environment.
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> Note: You may need to refactor your initial scaffolding web worker code for sending messages to and from. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are the limitations with web workers?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You need to remember two important things when using Web Workers in Angular projects,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Some environments or platforms(like @angular/platform-server) used in Server-side Rendering, don't support Web Workers. In this case you need to provide a fallback mechanism to perform the computations to work in this environments.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Running Angular in web worker using @angular/platform-webworker is not yet supported in Angular CLI.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "What is Angular CLI Builder?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Angular8, the CLI Builder API is stable and available to developers who want to customize the Angular CLI by adding or modifying commands. For example, you could supply a builder to perform an entirely new task, or to change which third-party tool is used by an existing command.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is a builder?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A builder function is a function that uses the Architect API to perform a complex process such as 'build' or 'test'. The builder code is defined in an npm package. For example, BrowserBuilder runs a webpack build for a browser target and KarmaBuilder starts the Karma server and runs a webpack build for unit tests.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "How do you invoke a builder?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Angular CLI command ng run is used to invoke a builder with a specific target configuration. The workspace configuration file, angular.json, contains default configurations for built-in builders.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you create app shell in Angular?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>An App shell is a way to render a portion of your application via a route at build time. This is useful to first paint of your application that appears quickly because the browser can render static HTML and CSS without the need to initialize JavaScript. You can achieve this using Angular CLI which generates an app shell for running server-side of your app.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng generate appShell [options] (or)
  ng g appShell [options]
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "    What are the case types in Angular?",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular uses capitalization conventions to distinguish the names of various types. Angular follows the list of the below case types.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> camelCase : Symbols, properties, methods, pipe names, non-component directive selectors, constants uses lowercase on the first letter of the item. For example, 'selectedUser' </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>UpperCamelCase (or PascalCase): Class names, including classes that define components, interfaces, NgModules, directives, and pipes uses uppercase on the first letter of the item.  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  dash-case (or 'kebab-case'): The descriptive part of file names, component selectors uses dashes between the words. For example, 'app-user-list'.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  UPPER_UNDERSCORE_CASE: All constants uses capital letters connected with underscores. For example, 'NUMBER_OF_USERS'.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "What are the class decorators in Angular?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A class decorator is a decorator that appears immediately before a class definition, which declares the class to be of the given type, and provides metadata suitable to the type</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>The following list of decorators comes under class decorators,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>@Component()  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> @Directive() </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>@Pipe()  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> @Injectable() </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> @NgModule() </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What are class field decorators?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The class field decorators are the statements declared immediately before a field in a class definition that defines the type of that field. Some of the examples are: @input and @output,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Input() myProperty;
  @Output() myEvent = new EventEmitter();
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is declarable in Angular?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Declarable is a class type that you can add to the declarations list of an NgModule. The class types such as components, directives, and pipes comes can be declared in the module. The structure of declarations would be,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  declarations: [
    YourComponent,
    YourPipe,
    YourDirective
  ],
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the restrictions on declarable classes?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below classes shouldn't be declared,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>A class that's already declared in another NgModule  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Ngmodule classes            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Service classes </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Helper classes </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is a DI token?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A DI token is a lookup token associated with a dependency provider in dependency injection system. The injector maintains an internal token-provider map that it references when asked for a dependency and the DI token is the key to the map. Let's take example of DI Token usage,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const BASE_URL = new InjectionToken<string>('BaseUrl');
  const injector =  Injector.create({providers: [{provide: BASE_URL, useValue: 'http://some-domain.com'}]});
  const url = injector.get(BASE_URL);
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is Angular DSL? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A domain-specific language (DSL) is a computer language specialized to a particular application domain. Angular has its own Domain Specific Language (DSL) which allows us to write Angular specific html-like syntax on top of normal html. It has its own compiler that compiles this syntax to html that the browser can understand. This DSL is defined in NgModules such as animations, forms, and routing and navigation.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Basically you will see 3 main syntax in Angular DSL.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> (): Used for Output and DOM events. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> []: Used for Input and specific DOM element attributes. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  *: Structural directives(*ngFor or *ngIf) will affect/change the DOM structure.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "   what is an rxjs subject in Angular ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>An RxJS Subject is a special type of Observable that allows values to be multicasted to many Observers. While plain Observables are unicast (each subscribed Observer owns an independent execution of the Observable), Subjects are multicast.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>  A Subject is like an Observable, but can multicast to many Observers. Subjects are like EventEmitters: they maintain a registry of many listeners.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
          import { Subject } from 'rxjs';

          const subject = new Subject<number>();
       
          subject.subscribe({
            next: (v) => console.log('observerA: $ {v}')
          });
          subject.subscribe({
            next: (v) => console.log('observerB: $ {v}')
          });
       
          subject.next(1);
          subject.next(2);
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is Bazel tool?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Bazel is a powerful build tool developed and massively used by Google and it can keep track of the dependencies between different packages and build targets. In Angular8, you can build your CLI application with Bazel. Note: The Angular framework itself is built with Bazel.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the advantages of Bazel tool?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of key advantages of Bazel tool,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>It creates the possibility of building your back-ends and front-ends with the same tool</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The incremental build and tests</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>It creates the possibility to have remote builds and cache on a build farm.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you use Bazel with Angular CLI?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The @angular/bazel package provides a builder that allows Angular CLI to use Bazel as the build tool            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Use in an existing applciation: Add @angular/bazel using CLI </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng add @angular/bazel
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Use in a new application: Install the package and create the application with collection option  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install -g @angular/bazel
  ng new --collection=@angular/bazel
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>When you use ng build and ng serve commands, Bazel is used behind the scenes and outputs the results in dist/bin folder.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you run Bazel directly?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Sometimes you may want to bypass the Angular CLI builder and run Bazel directly using Bazel CLI. You can install it globally using @bazel/bazel npm package. i.e, Bazel CLI is available under @bazel/bazel package. After you can apply the below common commands,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  bazel build [targets] // Compile the default output artifacts of the given targets.
  bazel test [targets] // Run the tests with *_test targets found in the pattern.
  bazel run [target]: Compile the program represented by target and then run it.
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is platform in Angular? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A platform is the context in which an Angular application runs. The most common platform for Angular applications is a web browser, but it can also be an operating system for a mobile device, or a web server. The runtime-platform is provided by the @angular/platform-* packages and these packages allow applications that make use of @angular/core and @angular/common to execute in different environments. i.e, Angular can be used as platform-independent framework in different environments, For example,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> While running in the browser, it uses platform-browser package. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> When SSR(server-side rendering ) is used, it uses platform-server package for providing web server implementation. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What happens if I import the same module twice?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If multiple modules imports the same module then angular evaluates it only once (When it encounters the module first time). It follows this condition even the module appears at any level in a hierarchy of imported NgModules.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "How do you select an element with in a component template?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use @ViewChild directive to access elements in the view directly. Let's take input element with a reference,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <input #uname>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>and define view child directive and access it in ngAfterViewInit lifecycle hook</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @ViewChild('uname') input;

  ngAfterViewInit() {
    console.log(this.input.nativeElement.value);
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you detect route change in Angular?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Angular7, you can subscribe to router to detect the changes. The subscription for router events would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  this.router.events.subscribe((event: Event) => {})
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take a simple component to detect router changes</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { Component } from '@angular/core';
  import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

  @Component({
      selector: 'app-root',
      template: 'router-outlet></router-outlet>'
  })
  export class AppComponent {

    constructor(private router: Router) {

    this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
            // Show loading indicator and perform an action
        }

        if (event instanceof NavigationEnd) {
            // Hide loading indicator and perform an action
        }

        if (event instanceof NavigationError) {
            // Hide loading indicator and perform an action
            console.log(event.error); // It logs an error for debugging
        }
    });
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you pass headers for HTTP client?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can directly pass object map for http client or create HttpHeaders class to supply the headers.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  constructor(private _http: HttpClient) {}
  this._http.get('someUrl',{
    headers: {'header1':'value1','header2':'value2'}
  });

  (or)
  let headers = new HttpHeaders().set('header1', headerValue1); // create header object
  headers = headers.append('header2', headerValue2); // add a new header, creating a new object
  headers = headers.append('header3', headerValue3); // add another header

  let params = new HttpParams().set('param1', value1); // create params object
  params = params.append('param2', value2); // add a new param, creating a new object
  params = params.append('param3', value3); // add another param

  return this._http.get<any[]>('someUrl', { headers: headers, params: params })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of differential loading in CLI?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>From Angular8 release onwards, the applications are built using differential loading strategy from CLI to build two separate bundles as part of your deployed application.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  The first build contains ES2015 syntax which takes the advantage of built-in support in modern browsers, ships less polyfills, and results in a smaller bundle size.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> The second build contains old ES5 syntax to support older browsers with all necessary polyfills. But this results in a larger bundle size. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  Is Angular supports dynamic imports?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, Angular 8 supports dynamic imports in router configuration. i.e, You can use the import statement for lazy loading the module using loadChildren method and it will be understood by the IDEs(VSCode and WebStorm), webpack, etc. Previously, you have been written as below to lazily load the feature module. By mistake, if you have typo in the module name it still accepts the string and throws an error during build time.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {path: ‘user’, loadChildren: ‘./users/user.module#UserModulee’},
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>This problem is resolved by using dynamic imports and IDEs are able to find it during compile time itself.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {path: ‘user’, loadChildren: () => import(‘./users/user.module’).then(m => m.UserModule)};
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is lazy loading? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Lazy loading is one of the most useful concepts of Angular Routing. It helps us to download the web pages in chunks instead of downloading everything in a big bundle. It is used for lazy loading by asynchronously loading the feature module for routing whenever required using the property loadChildren. Let's load both Customer and Order feature modules lazily as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  const routes: Routes = [
    {
      path: 'customers',
      loadChildren: () => import('./customers/customers.module').then(module => module.CustomersModule)
    },
    {
      path: 'orders',
      loadChildren: () => import('./orders/orders.module').then(module => module.OrdersModule)
    },
    {
      path: '',
      redirectTo: '',
      pathMatch: 'full'
    }
  ];
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What are workspace APIs?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular 8.0 release introduces Workspace APIs to make it easier for developers to read and modify the angular.json file instead of manually modifying it. Currently, the only supported storage3 format is the JSON-based format used by the Angular CLI. You can enable or add optimization option for build target as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { NodeJsSyncHost } from '@angular-devkit/core/node';
  import { workspaces } from '@angular-devkit/core';

  async function addBuildTargetOption() {
      const host = workspaces.createWorkspaceHost(new NodeJsSyncHost());
      const workspace = await workspaces.readWorkspace('path/to/workspace/directory/', host);

      const project = workspace.projects.get('my-app');
      if (!project) {
        throw new Error('my-app does not exist');
      }

      const buildTarget = project.targets.get('build');
      if (!buildTarget) {
        throw new Error('build target does not exist');
      }

      buildTarget.options.optimization = true;

      await workspaces.writeWorkspace(workspace, host);
  }

  addBuildTargetOption();
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you upgrade angular version?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Angular upgrade is quite easier using Angular CLI ng update command as mentioned below. For example, if you upgrade from Angular 7 to 8 then your lazy loaded route imports will be migrated to the new import syntax automatically.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  $ ng update @angular/cli @angular/core
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is Angular Material? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular Material is a collection of Material Design components for Angular framework following the Material Design spec. You can apply Material Design very easily using Angular Material. The installation can be done through npm or yarn,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install --save @angular/material @angular/cdk @angular/animations
  (OR)
  yarn add @angular/material @angular/cdk @angular/animations
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   How do you upgrade location service of angularjs? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>If you are using $location service in your old AngularJS application, now you can use LocationUpgradeModule(unified location service) which puts the responsibilities of $location service to Location service in Angular. Let's add this module to AppModule as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  // Other imports ...
  import { LocationUpgradeModule } from '@angular/common/upgrade';

  @NgModule({
    imports: [
      // Other NgModule imports...
      LocationUpgradeModule.config()
    ]
  })
  export class AppModule {}
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is NgUpgrade?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>NgUpgrade is a library put together by the Angular team, which you can use in your applications to mix and match AngularJS and Angular components and bridge the AngularJS and Angular dependency injection systems.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " How do you test Angular application using CLI?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular CLI downloads and install everything needed with the Jasmine Test framework. You just need to run ng test to see the test results. By default this command builds the app in watch mode, and launches the Karma test runner. The output of test results would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  10% building modules 1/1 modules 0 active
  ...INFO [karma]: Karma v1.7.1 server started at http://0.0.0.0:9876/
  ...INFO [launcher]: Launching browser Chrome ...
  ...INFO [launcher]: Starting browser Chrome
  ...INFO [Chrome ...]: Connected on socket ...
  Chrome ...: Executed 3 of 3 SUCCESS (0.135 secs / 0.205 secs)
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  How to use polyfills in Angular application?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Angular CLI provides support for polyfills officially. When you create a new project with the ng new command, a src/polyfills.ts configuration file is created as part of your project folder. This file includes the mandatory and many of the optional polyfills as JavaScript import statements. Let's categorize the polyfills,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Mandatory polyfills: These are installed automatically when you create your project with ng new command and the respective import statements enabled in 'src/polyfills.ts' file.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Optional polyfills: You need to install its npm package and then create import statement in 'src/polyfills.ts' file. For example, first you need to install below npm package for adding web animations (optional) polyfill. bash npm install --save web-animations-js  and create import statement in polyfill file. javascript import 'web-animations-js';  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What are the ways to trigger change detection in Angular?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can inject either ApplicationRef or NgZone, or ChangeDetectorRef into your component and apply below specific methods to trigger change detection in Angular. i.e, There are 3 possible ways,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> ApplicationRef.tick(): Invoke this method to explicitly process change detection and its side-effects. It check the full component tree. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> NgZone.run(callback): It evaluate the callback function inside the Angular zone. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> ChangeDetectorRef.detectChanges(): It detects only the components and it's children. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "   What are the security principles in angular? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the list of security principles in angular,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> You should avoid direct use of the DOM APIs. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> You should enable Content Security Policy (CSP) and configure your web server to return appropriate CSP HTTP headers. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> You should Use the offline template compiler. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> You should Use Server Side XSS protection. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  You should Use DOM Sanitizer.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>You should Preventing CSRF or XSRF attacks.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is the reason to deprecate Web Tracing Framework?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular has supported the integration with the Web Tracing Framework (WTF) for the purpose of performance testing. Since it is not well maintained and failed in majority of the applications, the support is deprecated in latest releases.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the reason to deprecate web worker packages?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Both @angular/platform-webworker and @angular/platform-webworker-dynamic are officially deprecated, the Angular team realized it's not good practice to run the Angular application on Web worker</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you find angular CLI version?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular CLI provides it's installed version using below different ways using ng command,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng v
  ng version
  ng -v
  ng --version
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>and the output would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  Angular CLI: 1.6.3
  Node: 8.11.3
  OS: darwin x64
  Angular:
  ...
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is schematic?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>It's a scaffolding library that defines how to generate or transform a programming project by creating, modifying, refactoring, or moving files and code. It defines rules that operate on a virtual file system called a tree.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is rule in Schematics?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In schematics world, it's a function that operates on a file tree to create, delete, or modify files in a specific manner.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is Schematics CLI?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Schematics come with their own command-line tool known as Schematics CLI. It is used to install the schematics executable, which you can use to create a new schematics collection with an initial named schematic. The collection folder is a workspace for schematics. You can also use the schematics command to add a new schematic to an existing collection, or extend an existing schematic. You can install Schematic CLI globally as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install -g @angular-devkit/schematics-cli
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "What are the best practices for security in angular?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Below are the best practices of security in angular,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Use the latest Angular library releases </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Don't modify your copy of Angular </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Avoid Angular APIs marked in the documentation as “Security Risk.”  </span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is Angular security model for preventing XSS attacks?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular treats all values as untrusted by default. i.e, Angular sanitizes and escapes untrusted values When a value is inserted into the DOM from a template, via property, attribute, style, class binding, or interpolation.</span>",
        }
      ],
      type: "Normal",
    },   
    {
      question: "  What is the role of template compiler for prevention of XSS attacks?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The offline template compiler prevents vulnerabilities caused by template injection, and greatly improves application performance. So it is recommended to use offline template compiler in production deployments without dynamically generating any template.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the various security contexts in Angular?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular defines the following security contexts for sanitization,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> HTML: It is used when interpreting a value as HTML such as binding to innerHtml. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Style: It is used when binding CSS into the style property. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> URL: It is used for URL properties such as <a href>. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Resource URL: It is a URL that will be loaded and executed as code such as <script src>.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is Sanitization? Is angular supports it?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Sanitization is the inspection of an untrusted value, turning it into a value that's safe to insert into the DOM. Yes, Angular suppports sanitization. It sanitizes untrusted values for HTML, styles, and URLs but sanitizing resource URLs isn't possible because they contain arbitrary code.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of innerHTML?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The innerHtml is a property of HTML-Elements, which allows you to set it's html-content programmatically. Let's display the below html code snippet in a <div> tag as below using innerHTML binding,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div [innerHTML]="htmlSnippet"></div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>and define the htmlSnippet property from any component</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  export class myComponent {
    htmlSnippet: string = '<b>Hello World</b>, Angular';
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Unfortunately this property could cause Cross Site Scripting (XSS) security bugs when improperly handled.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is the difference between interpolated content and innerHTML? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The main difference between interpolated and innerHTML code is the behavior of code interpreted. Interpolated content is always escaped i.e, HTML isn't interpreted and the browser displays angle brackets in the element's text content. Where as in innerHTML binding, the content is interpreted i.e, the browser will convert < and > characters as HTMLEntities. For example, the usage in template would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <p>Interpolated value:</p>
  <div >{{htmlSnippet}}</div>
  <p>Binding of innerHTML:</p>
  <div [innerHTML]="htmlSnippet"></div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>and the property defined in a component.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  export class InnerHtmlBindingComponent {
    htmlSnippet = 'Template <script>alert("XSS Attack")</script> <b>Code attached</b>';
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Even though innerHTML binding create a chance of XSS attack, Angular recognizes the value as unsafe and automatically sanitizes it.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " Is safe to use direct DOM API methods in terms of security?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>No,the built-in browser DOM APIs or methods don't automatically protect you from security vulnerabilities. In this case it is recommended to use Angular templates instead of directly interacting with DOM. If it is unavoidable then use the built-in Angular sanitization functions.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is DOM sanitizer?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>DomSanitizer is used to help preventing Cross Site Scripting Security bugs (XSS) by sanitizing values to be safe to use in the different DOM contexts.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you support server side XSS protection in Angular application?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The server-side XSS protection is supported in an angular application by using a templating language that automatically escapes values to prevent XSS vulnerabilities on the server. But don't use a templating language to generate Angular templates on the server side which creates a high risk of introducing template-injection vulnerabilities.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  Is angular prevents http level vulnerabilities?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular has built-in support for preventing http level vulnerabilities such as as cross-site request forgery (CSRF or XSRF) and cross-site script inclusion (XSSI). Even though these vulnerabilities need to be mitigated on server-side, Angular provides helpers to make the integration easier on the client side.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> HttpClient supports a token mechanism used to prevent XSRF attacks </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>HttpClient library recognizes the convention of prefixed JSON responses(which non-executable js code with ')]}',\n' characters) and automatically strips the string ')]}',\n' from all responses before further parsing  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What are Http Interceptors?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Http Interceptors are part of @angular/common/http, which inspect and transform HTTP requests from your application to the server and vice-versa on HTTP responses. These interceptors can perform a variety of implicit tasks, from authentication to logging.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span> The syntax of HttpInterceptor interface looks like as below, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  interface HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> You can use interceptors by declaring a service class that implements the intercept() method of the HttpInterceptor interface. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Injectable()
  export class MyInterceptor implements HttpInterceptor {
      constructor() {}
      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
          ...
      }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> After that you can use it in your module, </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
    @NgModule({
      ...
      providers: [
          {
              provide: HTTP_INTERCEPTORS,
              useClass: MyInterceptor,
              multi: true
          }
      ]
      ...
  })
  export class AppModule {}
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What are the applications of HTTP interceptors?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The HTTP Interceptors can be used for different variety of tasks,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Authentication  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Logging </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Caching</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Fake backend            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  URL transformation            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Modifying headers            </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " Is multiple interceptors supported in Angular?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, Angular supports multiple interceptors at a time. You could define multiple interceptors in providers property:</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyFirstInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MySecondInterceptor, multi: true }
  ],
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>The interceptors will be called in the order in which they were provided. i.e, MyFirstInterceptor will be called first in the above interceptors configuration.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How can I use interceptor for an entire application?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use same instance of HttpInterceptors for the entire app by importing the HttpClientModule only in your AppModule, and add the interceptors to the root application injector. For example, let's define a class that is injectable in root application.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Injectable()
  export class MyInterceptor implements HttpInterceptor {
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {

      return next.handle(req).do(event => {
        if (event instanceof HttpResponse) {
            // Code goes here
        }
      });

    }
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>After that import HttpClientModule in AppModule</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, HttpClientModule],
    providers: [
      { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
  })
  export class AppModule {}
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: "  How does Angular simplifies Internationalization?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular simplifies the below areas of internationalization,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Displaying dates, number, percentages, and currencies in a local format. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Preparing text in component templates for translation. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Handling plural forms of words. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Handling alternative text. </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How do you manually register locale data?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>By default, Angular only contains locale data for en-US which is English as spoken in the United States of America . But if you want to set to another locale, you must import locale data for that new locale. After that you can register using registerLocaleData method and the syntax of this method looks like below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  registerLocaleData(data: any, localeId?: any, extraData?: any): void
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, let us import German locale and register it in the application</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { registerLocaleData } from '@angular/common';
  import localeDe from '@angular/common/locales/de';

  registerLocaleData(localeDe, 'de');
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of i18n attribute?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Angular i18n attribute marks translatable content. It is a custom attribute, recognized by Angular tools and compilers. The compiler removes it after translation.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Note: Remember that i18n is not an Angular directive.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of custom id?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>When you change the translatable text, the Angular extractor tool generates a new id for that translation unit. Because of this behavior, you must then update the translation file with the new id every time.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, the translation file messages.de.xlf.html has generated trans-unit for some text message as below  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <trans-unit id="827wwe104d3d69bf669f823jjde888" datatype="html">
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span> You can avoid this manual update of id attribute by specifying a custom id in the i18n attribute by using the prefix @@. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <h1 i18n="@@welcomeHeader">Hello i18n!</h1>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What happens if the custom id is not unique?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You need to define custom ids as unique. If you use the same id for two different text messages then only the first one is extracted. But its translation is used in place of both original text messages.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, let's define same custom id myCustomId for two messages,  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <h2 i18n="@@myCustomId">Good morning</h3>
  <!-- ... -->
  <h2 i18n="@@myCustomId">Good night</p>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>  and the translation unit generated for first text in for German language as</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <trans-unit id="myId" datatype="html">
  <source>Good morning</source>
  <target state="new">Guten Morgen</target>
  </trans-unit>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Since custom id is the same, both of the elements in the translation contain the same text as below  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <h2>Guten Morgen</h2>
  <h2>Guten Morgen</h2>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "Can I translate text without creating an element?    ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, you can achieve using <ng-container> attribute. Normally you need to wrap a text content with i18n attribute for the translation. But if you don't want to create a new DOM element just for the sake of translation, you can wrap the text in an element.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <ng-container i18n>I'm not using any DOM element for translation</ng-container>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Remember that <ng-container> is transformed into an html comment</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How can I translate attribute?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can translate attributes by attaching i18n-x attribute where x is the name of the attribute to translate. For example, you can translate image title attribute as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <img [src]="example" i18n-title title="Internationlization" />
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>By the way, you can also assign meaning, description and id with the i18n-x='|@@' syntax.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " List down the pluralization categories?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Pluralization has below categories depending on the language.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  =0 (or any other number)
  zero
  one
  two
  few
  many
  other
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is select ICU expression?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>ICU expression is is similar to the plural expressions except that you choose among alternative translations based on a string value instead of a number. Here you define those string values.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Let's take component binding with residenceStatus property which has 'citizen', 'permanent resident' and 'foreigner' possible values and the message maps those values to the appropriate translations.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <span i18n>The person is {residenceStatus, select, citizen {citizen} permanent resident {permanentResident} foreigner {foreigner}}</span>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you provide build configuration for multiple locales?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can provide build configuration such as translation file path, name, format and application url in configuration settings of Angular.json file. For example, the German version of your application configured the build as follows,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  "configurations": {
    "de": {
      "aot": true,
      "outputPath": "dist/my-project-de/",
      "baseHref": "/fr/",
      "i18nFile": "src/locale/messages.de.xlf",
      "i18nFormat": "xlf",
      "i18nLocale": "de",
      "i18nMissingTranslation": "error",
    }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is an angular library?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>An Angular library is an Angular project that differs from an app in that it cannot run on its own. It must be imported and used in an app. For example, you can import or add service worker library to an Angular application which turns an application into a Progressive Web App (PWA).</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is AOT compiler?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The AOT compiler is part of a build process that produces a small, fast, ready-to-run application package, typically for production. It converts your Angular HTML and TypeScript code into efficient JavaScript code during the build phase before the browser downloads and runs that code.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  How do you select an element in component template?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can control any DOM element via ElementRef by injecting it into your component's constructor. i.e, The component should have constructor with ElementRef parameter,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  constructor(myElement: ElementRef) {
    el.nativeElement.style.backgroundColor = 'yellow';
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is TestBed?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>TestBed is an api for writing unit tests for Angular applications and it's libraries. Even though We still write our tests in Jasmine and run using Karma, this API provides an easier way to create components, handle injection, test asynchronous behaviour and interact with our application.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is protractor?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Protractor is an end-to-end test framework for Angular and AngularJS applications. It runs tests against your application running in a real browser, interacting with it as a user would.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install -g protractor
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is collection?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Collection is a set of related schematics collected in an npm package. For example, @schematics/angular collection is used in Angular CLI to apply transforms to a web-app project. You can create your own schematic collection for customizing angular projects.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you create schematics for libraries?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can create your own schematic collections to integrate your library with the Angular CLI. These collections are classified as 3 main schematics,</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Add schematics: These schematics are used to install library in an Angular workspace using ng add command. For example, @angular/material schematic tells the add command to install and set up Angular Material and theming. </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Generate schematics: These schematics are used to modify projects, add configurations and scripts, and scaffold artifacts in library using ng generate command. For example, @angular/material generation schematic supplies generation schematics for the UI components. Let's say the table component is generated using ng generate @angular/material:table . </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Update schematics: These schematics are used to update library's dependencies and adjust for breaking changes in a new library release using ng update command. For example, @angular/material update schematic updates material and cdk dependencies using ng update @angular/material command.  </span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "   How do you use jquery in Angular? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use jquery in Angular using 3 simple steps,            </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span> Install the dependency: At first, install the jquery dependency using npm </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  npm install --save jquery
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Add the jquery script: In Angular-CLI project, add the relative path to jquery in the angular.json file  </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  "scripts": [
    "node_modules/jquery/dist/jquery.min.js"
  ]
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span> Start using jquery: Define the element in template. Whereas declare the jquery variable and apply CSS classes on the element. </span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div id="elementId">
  <h1>JQuery integration</h1>
  </div>
          `,
        },
        {
          htmlElement: "Syntax",
          value:`
  import {Component, OnInit} from '@angular/core';

  declare var $: any; // (or) import * as $ from 'jquery';

  @Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
  })
  export class AppComponent implements OnInit {
    ngOnInit(): void {
      $(document).ready(() => {
        $('#elementId').css({'text-color': 'blue', 'font-size': '150%'});
      });
    }
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the reason for No provider for HTTP exception?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>This exception is due to missing HttpClientModule in your module. You just need to import in module as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  import { HttpClientModule } from '@angular/common/http';

  @NgModule({
    imports: [
      BrowserModule,
      HttpClientModule,
    ],
    declarations: [ AppComponent ],
    bootstrap:    [ AppComponent ]
  })
  export class AppModule { }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is router state?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The RouteState is an interface which represents the state of the router as a tree of activated routes.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  interface RouterState extends Tree {
    snapshot: RouterStateSnapshot
    toString(): string
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>You can access the current RouterState from anywhere in the Angular app using the Router service and the routerState property.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "  How can I use SASS in angular project?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>When you are creating your project with angular cli, you can use ng newcommand. It generates all your components with predefined sass files.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng new My_New_Project --style=sass
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>But if you are changing your existing style in your project then use ng set command,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  ng set defaults.styleExt scss
          `,
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the purpose of hidden property?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The hidden property is used to show or hide the associated DOM element, based on an expression. It can be compared close to ng-show directive in AngularJS. Let's say you want to show user name based on the availability of user using hidden property.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div [hidden]="!user.name">
  My name is: {{user.name}}
  </div>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is the difference between ngIf and hidden property?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The main difference is that *ngIf will remove the element from the DOM, while [hidden] actually plays with the CSS style by setting display:none. Generally it is expensive to add and remove stuff from the DOM for frequent actions.</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is slice pipe?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The slice pipe is used to create a new Array or String containing a subset (slice) of the elements. The syntax looks like as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  {{ value_expression | slice : start [ : end ] }}
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can provide 'hello' list based on a greeting array,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Component({
    selector: 'list-pipe',
    template: '<ul>
      <li *ngFor='let i of greeting | slice:0:5'>{{i}}</li>
    </ul>'
  })
  export class PipeListComponent {
    greeting: string[] = ['h', 'e', 'l', 'l', 'o', 'm','o', 'r', 'n', 'i', 'n', 'g'];
  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is index property in ngFor directive?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The index property of the NgFor directive is used to return the zero-based index of the item in each iteration. You can capture the index in a template input variable and use it in the template.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can capture the index in a variable named indexVar and displays it with the todo's name using ngFor directive as below.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div *ngFor="let todo of todos; let i=index">{{i + 1}} - {{todo.name}}</div>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What is the purpose of ngFor trackBy?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The main purpose of using *ngFor with trackBy option is performance optimization. Normally if you use NgFor with large data sets, a small change to one item by removing or adding an item, can trigger a cascade of DOM manipulations. In this case, Angular sees only a fresh list of new object references and to replace the old DOM elements with all new DOM elements. You can help Angular to track which items added or removed by providing a trackBy function which takes the index and the current item as arguments and needs to return the unique identifier for this item.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, lets set trackBy to the trackByTodos() method</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div *ngFor="let todo of todos; trackBy: trackByTodos">
  ({{todo.id}}) {{todo.name}}
  </div>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>and define the trackByTodos method,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  trackByTodos(index: number, item: Todo): number { return todo.id; }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "   What is the purpose of ngSwitch directive? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>NgSwitch directive is similar to JavaScript switch statement which displays one element from among several possible elements, based on a switch condition. In this case only the selected element placed into the DOM. It has been used along with NgSwitch, NgSwitchCase and NgSwitchDefault directives.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, let's display the browser details based on selected browser using ngSwitch directive.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <div [ngSwitch]="currentBrowser.name">
    <chrome-browser    *ngSwitchCase="'chrome'"    [item]="currentBrowser"></chrome-browser>
    <firefox-browser   *ngSwitchCase="'firefox'"     [item]="currentBrowser"></firefox-browser>
    <opera-browser     *ngSwitchCase="'opera'"  [item]="currentBrowser"></opera-browser>
    <safari-browser     *ngSwitchCase="'safari'"   [item]="currentBrowser"></safari-browser>
    <ie-browser  *ngSwitchDefault           [item]="currentItem"></ie-browser>
  </div>
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " Is it possible to do aliasing for inputs and outputs?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Yes, it is possible to do aliasing for inputs and outputs in two ways.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Aliasing in metadata: The inputs and outputs in the metadata aliased using a colon-delimited (:) string with the directive property name on the left and the public alias on the right. i.e. It will be in the format of propertyName:alias.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  inputs: ['input1: buyItem'],
  outputs: ['outputEvent1: completedEvent']
          `,
        },
        {
          htmlElement: "Li",
          value:
            "<span>Aliasing with @Input()/@Output() decorator: The alias can be specified for the property name by passing the alias name to the @Input()/@Output() decorator.i.e. It will be in the form of @Input(alias) or @Output(alias).</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @Input('buyItem') input1: string;
  @Output('completedEvent') outputEvent1 = new EventEmitter<string>();
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " What is safe navigation operator?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The safe navigation operator(?)(or known as Elvis Operator) is used to guard against null and undefined values in property paths when you are not aware whether a path exists or not. i.e. It returns value of the object path if it exists, else it returns the null value.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>For example, you can access nested properties of a user profile easily without null reference errors as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  <p>The user firstName is: {{user?.fullName.firstName}}</p>
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>Using this safe navigation operator, Angular framework stops evaluating the expression when it hits the first null value and renders the view without any errors.</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: "   Is any special configuration required for Angular9? ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You don't need any special configuration. In Angular9, the Ivy renderer is the default Angular compiler. Even though Ivy is available Angular8 itself, you had to configure it in tsconfig.json file as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  "angularCompilerOptions": {    "enableIvy": true  }
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are type safe TestBed API changes in Angular9?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>Angular 9 provides type safe changes in TestBed API changes by replacing the old get function with the new inject method. Because TestBed.get method is not type-safe. The usage would be as below,</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  TestBed.get(ChangeDetectorRef) // returns any. It is deprecated now.

  TestBed.inject(ChangeDetectorRef) // returns ChangeDetectorRef
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  Is mandatory to pass static flag for ViewChild?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>In Angular 8, the static flag is required for ViewChild. Whereas in Angular9, you no longer need to pass this property. Once you updated to Angular9 using ng update, the migration will remove { static: false } script everywhere.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @ViewChild(ChildDirective) child: ChildDirective; // Angular9 usage
  @ViewChild(ChildDirective, { static: false }) child: ChildDirective; //Angular8 usage
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: "  What are the list of template expression operators?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The Angular template expression language supports three special template expression operators.</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>Pipe operator  </span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Safe navigation operator</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>  Non-null assertion operator</span>",
        },
      ],
      type: "Normal",
    },
    {
      question: " What is the precedence between pipe and ternary operators?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>The pipe operator has a higher precedence than the ternary operator (?:). For example, the expression first ? second : third | fourth is parsed as first ? second : (third | fourth).</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is an entry component?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>An entry component is any component that Angular loads imperatively(i.e, not referencing it in the template) by type. Due to this behavior, they can’t be found by the Angular compiler during compilation. These components created dynamically with ComponentFactoryResolver.</span>",
        },
        {
          htmlElement: "div",
          value:
            "<span>Basically, there are two main kinds of entry components which are following -</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>The bootstrapped root component</span>",
        },
        {
          htmlElement: "Li",
          value:
            "<span>A component you specify in a route</span>",
        }
      ],
      type: "Normal",
    },
    {
      question: " What is a bootstrapped component?  ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>A bootstrapped component is an entry component that Angular loads into the DOM during the bootstrap process or application launch time. Generally, this bootstrapped or root component is named as AppComponent in your root module using bootstrap property as below.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  @NgModule({
    declarations: [
      AppComponent
    ],
    imports: [
      BrowserModule,
      FormsModule,
      HttpClientModule,
      AppRoutingModule
    ],
    providers: [],
    bootstrap: [AppComponent] // bootstrapped entry component need to be declared here
  })
          `,
        }
      ],
      type: "Normal",
    },
    {
      question: " How do you manually bootstrap an application?   ",
      answer: [
        {
          htmlElement: "div",
          value:
            "<span>You can use ngDoBootstrap hook for a manual bootstrapping of the application instead of using bootstrap array in @NgModule annotation. This hook is part of DoBootstap interface.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  interface DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef): void
  }
          `,
        },
        {
          htmlElement: "div",
          value:
            "<span>The module needs to be implement the above interface to use the hook for bootstrapping.</span>",
        },
        {
          htmlElement: "Syntax",
          value:`
  class AppModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
      appRef.bootstrap(AppComponent); // bootstrapped entry component need to be passed
    }
  }
          `,
        }
      ],
      type: "Normal",
    }


































  ],
};
