import React, { useState } from "react";
import { LayoutWeb } from "../LayoutWeb/LayoutWeb";
import { NavbarHome } from "../../Components/NavbarHome/NavbarHome";
import Interviewqa from "../../Components/Interviewqa/Interviewqa";
import Home from "../../Components/Home/Home";
import { Route, Routes } from "react-router-dom";
import Challenges from "../../Components/Challenges/Challenges";
import { ChallengesAnswers } from "../../Components/Challenges/ChallengesAnswers/ChallengesAnswers";
import ReactChallenges from "../../Components/ReactChallenges/ReactChallenges";
import { ReactChallengeAnswer } from "../../Components/ReactChallenges/Answers/ReactChallengeAnswer";
import "./Layout.css";
import ReactScenarioChallenges from "../../Components/ReactChallenges/ReactScenarioChallenges/ReactScenarioChallenges";
import { ReactScenariosAnswerEditor } from "../../Components/ReactChallenges/ReactScenarioChallenges/ReactScenariosAnswerEditor/ReactScenariosAnswerEditor";

const Layout = () => {
  const [selCourse, setSelCourse] = useState("none");

  const handleSelCourse = (selVal) => {
    setSelCourse(selVal);
  };

  return (
    <div className="layout-container">
      <div className="layout-mobile-container">
        <NavbarHome
          pageWrapId={"page-wrap"}
          outerContainerId={"App"}
          handleSelCourse={handleSelCourse}
        ></NavbarHome>
        <div id="page-wrap">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/InterviewQa/:intType" element={<Interviewqa />} />
            <Route path="/Challenges" element={<Challenges />} />
            <Route
              path="/ChallengeAnswer/:chalNumb"
              element={<ChallengesAnswers />}
            ></Route>
            <Route path="/ReactChallenges" element={<ReactChallenges />} />
            <Route
              path="/ReactScenarioChallenges"
              element={<ReactScenarioChallenges />}
            />
            <Route
              path="/ReactChallengeAnswer/:reactChalNumb"
              element={<ReactChallengeAnswer />}
            />
            <Route
              path="/ReactScenariosAnswer/:reactChalNumb"
              element={<ReactScenariosAnswerEditor />}
            />
          </Routes>
        </div>
      </div>
      <LayoutWeb selCourse={selCourse}></LayoutWeb>
    </div>
  );
};

export default Layout;
