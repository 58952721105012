import React, { useState, useEffect } from "react";
import "./ReactChallengeAnswer.css"; // Import a CSS file for styling
import { useParams } from "react-router-dom";
import ReactChallenges from "../../Interviewqa/QnaJson/ReactChallenges";

export const ReactChallengeAnswer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { reactChalNumb } = useParams();
  const [stackBlitzUrl,setstackBlitzUrl] = useState("");

  useEffect(() => {
    const challengesInfo = ReactChallenges.data;
    let url = challengesInfo[reactChalNumb-1].solution;
    setstackBlitzUrl(url);
  }, []);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      {isLoading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <iframe
        src={stackBlitzUrl}
        style={{ width: "100%", height: "100%", border: "none" }}
        title="Nested circles - Reactjs"
        onLoad={handleIframeLoad}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};
